// @flow
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import * as _ from "lodash";
import {toastError, toastSuccess} from "../..";
import {Button} from "antd";
import {useMutation} from "@apollo/client";

type Props = {
    onClick: Function,
    mutation: {
        mutation: any,
        mutationOptions?: any,
        translateMutationOptions: any,
        successToast?: string
    },
    selectedRowIds?: any,
    onSuccess?: Function,
    content: any,
    key?: any
}

const MutationToolbarButton = (props: Props) => {

    const { onClick, mutation, selectedRowIds, onSuccess, content, key } = props;
    const {t} = useTranslation();

    const [mut, { data }] = useMutation(mutation.mutation, mutation.mutationOptions);

    return <Button
        onClick={() => {
            if(!_.isNil(onClick)){
                onClick(selectedRowIds);
            }
            else {
                mut(mutation.translateMutationOptions(selectedRowIds))
                    .then(() => {
                        toastSuccess(_.get(mutation, 'successToast', t('Successfully removed')));
                        if (onSuccess) onSuccess();
                    })
                    .catch(() => {
                        toastError(t('An error occurred'));
                    });
            }
        }}
        key={key}
    >
        {content}
    </Button>
};

export default MutationToolbarButton
