import * as React from 'react';
import {Component} from 'react';
import _ from 'lodash';
import AttributesForm from '../AttributesForm';
import {Modal} from "antd";
import {OnFormItemClick} from "../../types";

type Props = {
  editValue: any,
  onSave: Function,
  columns: any,
  onCancel: Function,
  visible: boolean,
  t: any,
  onFormItemClick?: OnFormItemClick
}

type State = {
  values: any
};

class EditRowModal extends Component<Props, State> {
  state = {
    values: _.get(this, 'props.editValue', null)
  };

  render() {
    const {columns, onSave, visible, onCancel, t, onFormItemClick} = this.props;
    const {values} = this.state;

    const attributes = columns.map((el: object) => {
      return {
        index: el['dataIndex'],
        targetType: 'string',
        name: el['title'],
        ...el,
      }
    });

    return <Modal
      title={t("Edit Row")}
      data-testid={"edit-row-modal"}
      visible={visible}
      footer={null}
      onCancel={() => onCancel()}
    >
      <AttributesForm
        onFormItemClick={onFormItemClick}
        layout={'vertical'}
        clearOnSubmit={true}
        attributes={attributes}
        values={values}
        submitTitle={t('Edit Row')}
        onChange={(newValues: any) => {
          this.setState({
            values: {
              ...values,
              ...newValues
            }
          })
        }}
        submit={(values: any) => new Promise((resolve: any) => {
          onSave(values);
          resolve(values);
        })}
      />
    </Modal>
  }
}

export default EditRowModal;
