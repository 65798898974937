// @flow
import React, {useEffect, useRef, useState} from 'react';
import * as _ from 'lodash'

import {Table} from "antd";
import Loader from "../Loader";
import {useTranslation} from "react-i18next";
import {Row} from "../Flex";
import QueryErrorIndicator from '../QueryErrorIndicator';
import {NetworkStatus, useApolloClient, useQuery} from "@apollo/client";
import {useComponentSize} from "../../index";

export type QueryData = {
    query: any,
    pagingSize?: number,
    queryOptions?: any,
    itemsKeyPath: string,
    totalKeyPath: string,
    limitKeyPath?: string,
    startKeyPath?: string,
    queryVariables?: object,
}

type Props = {
    queryData: QueryData,
    useFullHeight?: boolean,
    style?: any,
    bordered?: boolean,
    columns?: any,
    uniqueIdentifier?: any,
    useWindow?: boolean,
    sortBy?: string,
    removeKeyById?: string,
    pagingScrollHeight?: any,
    className?: any,
    sortKeyPath?: any,
    debounceSubscriptionTimeout?: number,
    rowSelection?: any,
    size?: any,
    rowKey?: any,
    scroll?: any,
    usePagination?: boolean,
    pagination?: {
        current: number,
        [key: string]: any
    },
    filter?: any,
    sorter?: any,
    onChange?: (pagination: any, filter: any, sort: any) => void,
    onFilterChange?: any,
    onSorterChange?: any,
    onPaginationChange?: (pagination: any) => void
    onRow?: any,
    useSubscriptionReload?: boolean,
    subscriptionQuery?: any,
    renderExtraFooter?: (query: any) => any
};

const QueryTable = (props: Props) => {

    const {t} = useTranslation();
    let {
        useFullHeight,
        style,
        className,
        sortBy,
        removeKeyById,
        pagingScrollHeight,
        rowKey,
        usePagination,
        queryData,
        filter,
        sorter,
        onChange,
        onFilterChange,
        onSorterChange,
        onPaginationChange,
        debounceSubscriptionTimeout,
        useSubscriptionReload,
        pagination,
        subscriptionQuery,
        renderExtraFooter
    } = props;

    const itemsKeyPath: any = queryData.itemsKeyPath;
    const totalKeyPath: any = queryData.totalKeyPath;
    const limitKeyPath: any = _.get(queryData, 'limitKeyPath');
    const startKeyPath: any = _.get(queryData, 'startKeyPath');
    const pagingSize : any= _.get(queryData, 'pagingSize');

    const [loadingMore, setLoadingMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagingData, setPagingData] = useState<any>([]);
    const [cachePagination, setCachePagination] = useState<any>({
        current: 1,
        pageSize: pagingSize
    });


    let usedPagination: any = {
        ...cachePagination,
        ...pagination,
        pageSize: pagingSize
    }

    const tableRef: any = useRef<any>();
    const size = useComponentSize(tableRef)

    const scroll = props.scroll || useFullHeight ? { y: size.height - 100 } : undefined;



    let variables = {...queryData.queryVariables};
    if(!_.isNil(filter) && !_.isEmpty(filter)){
        variables = {
            ...variables,
            ...{filter: filter}
        }
    }
    if(!_.isNil(sorter) && !_.isEmpty(sorter)){
        variables = {
            ...variables,
            ...{sort: sorter},
        }
    }

    if(limitKeyPath && startKeyPath && pagingSize){
        _.set(variables, limitKeyPath, pagingSize);
        _.set(variables, startKeyPath, usedPagination && usePagination ? (_.get(usedPagination,'current',1) -1 ) * pagingSize : 0);
    }

    let query = useQuery(queryData.query, {
        notifyOnNetworkStatusChange: true,
        ...queryData.queryOptions,
        variables,
    });

    const debounceReload = _.debounce(() => {
        query.refetch()
    },debounceSubscriptionTimeout, {
        leading: true
    })

    useEffect(() => {
        // @ts-ignore
        setPagingData([])
    },[JSON.stringify(variables)])

    let items: any = [];

    if (usePagination){
        items = _.get(_.get(query, 'data', query), itemsKeyPath, []);
    }else {
        items = [
            ..._.get(_.get(query, 'data', query), itemsKeyPath, []),
            ...pagingData
        ];
    }


    const _handleWaypointEnter = () => {
        const total =  _.get(query, `data.${totalKeyPath}`,0)
        const itemCount =  items.length

        if (itemCount < total && !loadingMore) {
            let variables: any = _.cloneDeep(query.variables);

            _.set(variables, startKeyPath, itemCount);
            _.set(variables, limitKeyPath, pagingSize);

            setLoadingMore(true);
            query.fetchMore({
                variables,
            })
                .then((res) => {
                    setPagingData([
                        ..._.cloneDeep(pagingData),
                        ..._.get(res,`data.${itemsKeyPath}`,[])
                        ]
                    )
                    setLoadingMore(false)
                });
        }
    };

    useEffect(() => {
        // @ts-ignore
        const scrollContainer = tableRef.current ? tableRef.current.querySelector(".ant-table-body") : undefined;
        if (scrollContainer && !usePagination && pagingSize && totalKeyPath && limitKeyPath){
            scrollContainer.onscroll = ({target}: any) => {
                if (target.scrollHeight - target.offsetHeight - target.scrollTop < pagingScrollHeight){
                    _handleWaypointEnter();
                }
            }
        }
    },[tableRef, items]);

    if (sortBy) {
        items = _.sortBy(items, [sortBy]);
    }
    if (removeKeyById) {
        items = _.filter(items, (item) => item.id !== removeKeyById)
    }

    const client = useApolloClient();

    useEffect(() => {
        if (useSubscriptionReload && subscriptionQuery){
            client.subscribe({
                query: subscriptionQuery
            }).subscribe((result: any) => {
                debounceReload();
            });
        }
    },[useSubscriptionReload])

    const total =  _.get(query, `data.${totalKeyPath}`,0)

    const paginationConfig = usePagination ? {
        showSizeChanger: false,
        ...usedPagination,
        total
    } : false;

    return <div ref={tableRef}
                className={`bg-white ${useFullHeight ? "full-height" : ""} ${className ? className : ''}`}
                style={style}
    >
        <Table
            scroll={scroll}
            rowKey={rowKey ? rowKey : 'id'}
            // TODO: VSM lists keep freezing because query.loading is true (VSM-11505)
            // loading={query.loading && !loadingMore}
            footer={ usePagination ? undefined : () => {
                let errorComponent = null;
                if (query.error) {
                    // @ts-ignore
                    errorComponent = <div data-cy={'query-error-component'}><QueryErrorIndicator query={query}/></div>
                }
                return <Row style={{minHeight: 30, contentAlign: 'middle'}}>
                    <span className={"flex-grow-1 flex-self-center"}>
                        {total>-1 ? `${total} ${t("items")}` : t("No entries loaded")}
                    </span>

                    {
                        loadingMore ?
                            <span className={"mr-5"}>
                                <Loader size={"small"}/>
                            </span>
                        : null
                    }

                    {!loadingMore && errorComponent ? errorComponent : null}

                    {!loadingMore && renderExtraFooter ? renderExtraFooter(query) : null}
                </Row>

            } }
            onChange={(pagination, filters, sorter: any) => {

                if (onChange){
                    onChange(pagination, filters, sorter);
                }

                if (pagination){
                    if(onPaginationChange){
                        onPaginationChange(pagination)
                    }
                    setCachePagination(pagination)
                }

                if (filters){
                    let changes: any = {};

                    Object.keys(filters).map((key: string) => {
                        if(!_.isNil(filters[key]) && !_.isEmpty(filters[key])){
                            changes[key] = filters[key];
                        }
                    });

                    if (onFilterChange){
                        onFilterChange(changes)
                    }
                }

                if (sorter) {
                    if(onSorterChange){
                        onSorterChange(sorter)
                    }
                }

            }}
            {...props}
            pagination={paginationConfig}
            dataSource={items}
        />
    </div>;
};

QueryTable.defaultProps = {
    pagingScrollHeight: 100,
    debounceSubscriptionTimeout: 10000,
    usePagination: false
};

export default QueryTable;
