import React from "react";
import FilterValueLabel from "../FilterValueLabel";
import AttributeValueLabel from "../AttributeValueLabel";
import {AttributeInputType} from "@dreebit/form-attributes-core";
import ValueDataLoaderWrapper, {ValueDataLoaderWrapperRenderArguments} from "../Wrapper/ValueDataLoaderWrapper";
import * as moment_ from "moment";
import _ from "lodash";
import {Spin} from "antd";

const moment = moment_;

interface Props {
  attribute: any,
  value: any,
  onChange?: (val: any) => void
}

const AttributeFilterValueLabel = ({attribute, value, onChange}: Props) => {

  const inputType = attribute.inputType.toUpperCase();
  let labelName: any = attribute.name;
  if ([AttributeInputType.CHECKBOX, AttributeInputType.BUTTON, AttributeInputType.SWITCH].indexOf(inputType) > -1) {
    labelName = null;
  }
  const dataLoaderConfig = _.get(attribute, 'dataLoaderConfig');
  const options = _.get(attribute, 'options') || _.get(attribute, 'optionValues');

  return <ValueDataLoaderWrapper options={options} dataLoaderConfig={dataLoaderConfig}
                                 value={value}>{(args: ValueDataLoaderWrapperRenderArguments) => {

    let tmpValue = value;
    if (!Array.isArray(tmpValue)) {
      if (!_.isNil(tmpValue)) {
        tmpValue = [tmpValue]
      } else {
        tmpValue = [];
      }
    }

    if (attribute.inputType.toUpperCase().includes('DATE') && tmpValue && tmpValue.length === 2) {
      const dates = tmpValue.map((date: any) => {
        return moment(date).format(_.get(attribute, "format", "LLL"))
      });
      tmpValue = [dates.join(' - ')];
    }

    const closeItems: any[] = args.loading ? [<span><Spin
      size={'small'}/></span>] : tmpValue.map((item: any, index: any) => {

      const option = _.find(args.options, {value: item});

      return <AttributeValueLabel key={index} option={option} attribute={attribute} value={item}/>
    })

    return <FilterValueLabel onChange={onChange} value={tmpValue} labelName={labelName} closeItem={closeItems}/>
  }}</ValueDataLoaderWrapper>

}

export default AttributeFilterValueLabel;
