import * as React from "react";
import {Attribute, AttributeInputType} from "@dreebit/form-attributes-core";
import _ from "lodash";
import DataLoaderValueLabel from "../DataLoaderValueLabel";
import * as moment_ from "moment";

const moment = moment_;


interface Props {
  attribute: Attribute<any>,
  value?: any,
  option?: any
}

const AttributeValueLabel = ({attribute, value, option}: Props): any => {
  const inputType = attribute.inputType && attribute.inputType.toUpperCase();

  if (option) {
    return option.title || option.name || option.value
  }

  const dataLoaderConfig = _.get(attribute, 'dataLoaderConfig');
  const tmpOptions = _.get(attribute, 'options') || _.get(attribute, 'optionValues');
  if ((dataLoaderConfig && !_.isEmpty(dataLoaderConfig))) {
    return <DataLoaderValueLabel options={tmpOptions} dataLoaderConfig={dataLoaderConfig} value={value}/>
  }

  // @ts-ignore
  if ([AttributeInputType.DATEPICKER, AttributeInputType.DATERANGEPICKER].indexOf(inputType.toUpperCase()) > -1 && moment(value).isValid()) {
    return moment(value).format(_.get(attribute, "format", "LLL"))
  }

  // @ts-ignore
  if ([AttributeInputType.CHECKBOX, AttributeInputType.BUTTON, AttributeInputType.SWITCH].indexOf(inputType.toUpperCase()) > -1) {
    return attribute.name || attribute.index
  }

  if (typeof value === 'string') {
    return value;
  }

  if (typeof value === 'boolean') {
    return "";
  }

  return `${value}`;
}

export default AttributeValueLabel;
