// @ts-nocheck
import * as React from 'react';
import {CardOptionValue, CardPickerAttribute} from '@dreebit/form-attributes-core';
import '../index.css';
import {Icon} from "../../../Icon";
import _ from 'lodash';

type Props = {
  attribute: CardPickerAttribute<any>,
  icon?: {
    name: string,
    size?: number,
    color?: string
  } | any,
  item: CardOptionValue<any>,
}

const CardPickerIcon = ({icon, attribute, item}: Props) => {
  if (!icon) return null;

  const iconSize = _.get(attribute, 'iconSize', _.get(item, 'icon.size', 14));
  const iconColor = _.get(attribute, 'iconColor', _.get(item, 'icon.color', null));

  return <div
    className={'CardIconWrapper'}
    style={{
      fontSize: iconSize,
      color: iconColor
    }}
  >
    {
      React.isValidElement(icon)
        ? icon
        : icon.name
          ? <Icon type={icon.name}/>
          : null
    }
  </div>
};

export default CardPickerIcon
