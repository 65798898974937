// @ts-nocheck
import * as React from 'react';
import * as _ from 'lodash';
import {CardOptionValue, CardPickerAttribute} from '@dreebit/form-attributes-core';
import CardPickerIcon from "./icon";
import './index.css';

type Props = {
  attribute: CardPickerAttribute<any>,
  item: CardOptionValue<any>,
  active?: boolean,
  onClick?: any
}

const CardPickerItem = ({attribute, item, active, onClick}: Props) => {

  if (_.isNil(item)) return null;
  const {value, title, titleStyle, subTitle, subTitleStyle, description, descriptionStyle, icon, component} = item;

  if (component && React.isValidElement(component)) return component;

  return <div
    key={value}
    className={`full-width full-height flex-row CardWrapper ${active ? 'ant-btn-primary' : null}`}
    onClick={() => !_.isNil(onClick) ? onClick(value) : null}
  >
    {
      icon
        ? <CardPickerIcon
          attribute={attribute}
          icon={icon}
          item={item}
        />
        : null
    }
    <div
      className={'flex-col full-width CardContentWrapper'}
    >
      {
        title
          ? <span
            className={'CardTitle'}
            style={titleStyle}
          >
            {title}
          </span>
          : null
      }
      {
        subTitle
          ? <span
            className={'CardSubTitle blockText'}
            style={subTitleStyle}
          >
            {subTitle}
          </span>
          : null
      }
      {
        description
          ? <span
            className={'CardDescription blockText'}
            style={descriptionStyle}
          >
            {description}
          </span>
          : null
      }
    </div>
  </div>
};

export default CardPickerItem
