// @flow
import React from 'react';
import classNames from 'classnames';

type Props = {
    children?: any,
    className?: any,
    style?: any
};


export const Row = (props: Props) => {
    const {children, style} = props;

    return <div style={style} className={classNames('flex-row', props.className)}>{children}</div>;
};

export const Col = (props: Props) => {
    const {children, style} = props;

    return <div style={style} className={classNames('flex-col', props.className)}>{children}</div>;
};


export const Grow = (props: Props) => {
    const {children, style} = props;

    return <div style={style} className={classNames('flex-grow-1', props.className)}>{children}</div>;
};


export const FlexRow = (props: Props) => {
    const {children, style} = props;

    return <div style={style} className={`flex-row ${props.className ? props.className : '' }`}>{children}</div>;
};

export const FlexCol = (props: Props) => {
    const {children, style} = props;

    return <div style={style} className={`flex-col ${props.className ? props.className : '' }`}>{children}</div>;
};


export const FlexGrow = (props: Props) => {
    const {children, style} = props;

    return <div style={style} className={`flex-grow-1 ${props.className ? props.className : '' }`}>{children}</div>;
};
