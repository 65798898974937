export * from "./styles.css";


export * from "@dreebit/form-attributes-core";

export {OnFormItemClick} from "./types";
export {SubmitArguments} from "./components/AttributesForm"

export {Icon} from "./components/Icon";

export {default as AttributesForm} from "./components/AttributesForm";
export {default as ModalAttributeForm} from "./components/ModalAttributeForm";

export {default as EditableWrapper} from "./components/Wrapper/EditableWrapper";
export {default as DataLoaderWrapper} from "./components/Wrapper/DataLoaderWrapper";
export {default as ValueDataLoaderWrapper} from "./components/Wrapper/ValueDataLoaderWrapper";

export * from "./factories/InputTypeFactory";
export {InputTypeFactory} from "./factories/InputTypeFactory";
export * from "./factories/WebRenderFactory";
export {WebRenderFactory} from "./factories/WebRenderFactory";

export {default as AttributeTextComponent} from "./components/FormItems/AttributeTextComponent";
export {default as AttributeSelectComponent} from "./components/FormItems/AttributeSelectComponent/index";
export {default as AttributeNumberSelectComponent} from "./components/FormItems/AttributeNumberSelectComponent/index";
export {getNumberOptionValueItems} from "./components/FormItems/AttributeNumberSelectComponent/utils";
export {default as SelectComponent} from "./components/FormItems/AttributeSelectComponent/SelectComponent";

export default {};
