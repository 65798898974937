// @flow
import React  from 'react';
import {AttributesForm} from "@dreebit/form-attributes-antd";
import Toolbar from "../Toolbar";

type Props = {
    onChange: any,
    params: any,
    attributes: any
};

const ListFilter = (props: Props) => {
    const { params, attributes, onChange } = props;

    const _handleChanges = (changes: any) => {
        if (onChange) {
            onChange(changes);
        }
    };

    return <Toolbar
        renderLeft={() => <AttributesForm
            cacheChanges={false}
            layout={'inline'}
            onChange={(changes: any) => {
                const values = {
                    ...params,
                    ...changes
                };

                _handleChanges(values);
            }}
            values={params}
            attributes={attributes}
            useSubmit={false}
        />}
    />;
};

export default ListFilter;
