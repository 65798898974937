import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, CustomAttribute, RcForm, Translations} from '@dreebit/form-attributes-core';
import EditableWrapper from "../Wrapper/EditableWrapper";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: CustomAttribute,
  translations: Translations,
  form: RcForm,
  style: AttributeFormStyles,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

type State = {}

class AttributeHiddenComponent extends Component<Props, State> {

  render() {
    const {attribute, style, form, allValues, translations, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-radio form-attribute-radio-' + attribute.index}
      style={style}
      formProps={formProps}
      allValues={allValues}
      translations={translations}
      form={form}
      attribute={attribute}
    >
      {({value}) => {
        return <input type={"hidden"} value={value}/>
      }}
    </EditableWrapper>
  }
}

export default AttributeHiddenComponent;
