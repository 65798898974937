import * as React from 'react';
import {Component} from 'react';
import {Input, Tooltip} from "antd";
import _ from "lodash";
import {AttributeFormStyles, DurationAttribute, RenderFactory, Translations} from '@dreebit/form-attributes-core';
import EditableWrapper from "../Wrapper/EditableWrapper";
import {DisplayValueRenderArgs} from "../EditableAttributeContainer";
import * as _moment from "moment";
import createAddon from "../../utils/AttributeAddonHelper";
import {OnFormItemClick} from "../../types";

const moment = _moment;


type Props = {
  attribute: DurationAttribute<any>,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

const UnitMap = {
  "s": "seconds",
  "sec": "seconds",
  "sek": "seconds",
  "m": "minutes",
  "min": "minutes",
}

class AttributeDurationComponent extends Component<Props> {

  getDurationText(value: number) {
    const {attribute, translations} = this.props
    let unit = _.get(attribute, 'unit', 'minutes');
    if (UnitMap[unit]) {
      unit = UnitMap[unit];
    }
    const duration:any = moment.duration(value, unit);
    if (!(typeof duration.format === "function")) {
      console.warn("You tried to use 'moment-duration-format' but the dep is not installed or setup")
      return value;
    }
    return duration.format(_.get(attribute, 'format', 'y [y], w [w], d [d], h [h], m [min], s [sec]'), {
      largest: 3,
      userLocale: translations["language"],
      trim: "all"
    })
  }

  render() {
    const {attribute, style, form, translations, allValues, formProps, onFormItemClick} = this.props;

    const tmpAttribute = _.cloneDeep(attribute);
    tmpAttribute.addOn = null

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      allValues={allValues}
      formProps={formProps}
      translations={translations}
      form={form}
      displayValueRender={({value}: DisplayValueRenderArgs) => {
        return `${this.getDurationText(value)}`
      }}
      attribute={tmpAttribute}>

      {({value, onChange, onKeyPress, inputRef}) => {

        let addOnAfter: any = createAddon(attribute, form, this.context.renderFactory, style, translations);
        if (attribute.addOnAttribute) {
          const renderFactory: RenderFactory = this.context.renderFactory;

          addOnAfter = renderFactory.renderAttributes([attribute.addOnAttribute], form, {
            ...style,
            withoutFormItem: true,
          }, translations)
        }

        let durationText = this.getDurationText(value)

        const input = <Input
          ref={inputRef}
          id={attribute.index}
          value={value}
          className={`form-item-input-${attribute.index}`}
          onChange={(event) => {
            const value = event.target.value;
            onChange(value);
          }}
          onKeyPress={onKeyPress}
          addonAfter={addOnAfter}
          disabled={attribute.disabled}
          placeholder={attribute.placeholder}/>;


        if (typeof durationText === "string") {
          return <Tooltip title={`${durationText}`}>
            {input}
          </Tooltip>
        }

        return input;
      }}

    </EditableWrapper>
  }
}

export default AttributeDurationComponent;
