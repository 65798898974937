// @flow
import * as React from "react"
import {ReactNode} from "react"
import * as moment_ from "moment";
import _ from 'lodash';

const moment = moment_;

type RenderArguments = {
  value?: any,
  onChange?: any,
  format?: any
}

type Props = {
  value?: any,
  onChange?: Function,
  format?: string,
  children: (args: RenderArguments) => ReactNode
};

type State = {}

class DateWrapper extends React.Component<Props, State> {
  render() {
    const {children, value, onChange, format} = this.props;

    let result = value;
    if (_.isString(result)) {
      if (result !== '') {
        let tmp = moment(value);
        if (tmp.isValid()) {
          result = moment(value);
        }
      } else {
        result = undefined;
      }
    }

    return children({
      value: result,
      onChange,
      format
    });
  }
}

export default DateWrapper
