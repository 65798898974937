// @ts-nocheck
import * as React from "react";
import {ReactNode} from "react";
import {Attribute, DataLoaderConfig, OptionValue, QueryConfig} from '@dreebit/form-attributes-core';
import _ from 'lodash';
import {Query, QueryResult} from "@apollo/client/react/components";
import {gql} from "@apollo/client";

export interface ValueDataLoaderWrapperRenderArguments {
  loading?: boolean
  error?: any
  data?: any
  options?: OptionValue<any>[]
}

type Props = {
  attribute?: Attribute<any>,
  options?: OptionValue<any>[],
  dataLoaderConfig?: DataLoaderConfig,
  value?: any,
  customData?: any,
  children: (args: ValueDataLoaderWrapperRenderArguments) => ReactNode
};

type State = {}

const ValueDataLoaderWrapper = (props) => {
  const {options, children, dataLoaderConfig, value} = props;

  const getOptions = (result, config) => {
    let options = []

    let tmpValue = value;
    if (!Array.isArray(tmpValue)) {
      tmpValue = [value];
    }

    if (!Array.isArray(result)) {
      if (_.get(result, config.valueKeyPath)) {
        options = [{
          key: _.get(result, config.valueKeyPath),
          title: _.get(result, config.titleKeyPath),
          value: _.get(result, config.valueKeyPath),
          raw: result
        }]
      }
    } else {
      options = tmpValue.map((val) => {
        let item = _.find(result, {
          [config.valueKeyPath]: val
        });
        if (item) {
          return {
            key: _.get(item, config.valueKeyPath),
            title: _.get(item, config.titleKeyPath),
            value: _.get(item, config.valueKeyPath),
            raw: item
          }
        }
        return null
      })
    }
    return options.filter((item) => !!item);
  }

  const getVariables = (queryConfig: QueryConfig) => {

    const {value, customData} = props;
    let variables = _.get(queryConfig, 'variables');

    if (queryConfig.getQueryOptions) {
      variables = {
        ...variables,
        ..._.get(queryConfig.getQueryOptions({
          value,
          customData,
        }), 'variables', {})
      }
    }
    if (!variables) {
      variables = {
        value: props.value
      }
    }

    return variables;
  }

  if (value === undefined || value === null) return children({});

  if (options && options.length > 0) {
    let tmpOptions = [];

    if (_.isArray(value)) {
      tmpOptions = value.map((val) => {
        return _.find(options, {value: val})
      })
    } else {
      const tmpVal = _.find(options, {value: value})

      if (tmpVal) {
        tmpOptions = [tmpVal]
      }
    }

    return children({
      options: tmpOptions
    });
  }
  if (dataLoaderConfig) {


    if (dataLoaderConfig.valueQueryConfig) {

      const config = _.get(dataLoaderConfig, 'valueQueryConfig');
      const queryString = _.get(config, 'query');
      if (!queryString) return <div>{`Error! No valueQuery text available!`}</div>;
      const query = typeof queryString === 'string' ? gql(queryString) : queryString;
      const variables = getVariables(config);

      return <Query query={query} variables={variables} fetchPolicy={'cache-first'}>
        {
          ({loading, error, data}: QueryResult) => {

            let result = _.get(data, config.dataKeyPath);


            return children({
              loading,
              error,
              data: result,
              options: getOptions(result, config)
            });

          }}
      </Query>

    }

    if (dataLoaderConfig.dataQueryConfig) {

      const queryString = _.get(dataLoaderConfig, 'dataQueryConfig.query');
      if (!queryString) return `Error! No valueQuery text available!`;
      const query = typeof queryString === 'string' ? gql(queryString) : queryString;
      const variables = getVariables(dataLoaderConfig.dataQueryConfig);

      return <Query query={query} variables={variables}>
        {({loading, error, data}: QueryResult) => {

          const result = _.get(data, dataLoaderConfig.dataQueryConfig.dataKeyPath, []);

          return children({
            loading,
            error,
            data: result,
            options: getOptions(result, dataLoaderConfig.dataQueryConfig)
          })
        }}
      </Query>
    }
  }
  return children({})
}

export default ValueDataLoaderWrapper;
