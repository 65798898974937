import React from 'react';
import './index.css';
import Icon from '@mdi/react';
import _ from "lodash";

type Props = {
  type: string
};

export const MaterialCommunityIcon = ({type, ...rest}: Props) => {
  const icons = require(`@mdi/js`);

  let path = icons[type];
  if (!path) return null;

  let size = _.get(rest, 'style.fontSize') || _.get(rest, 'size') || '1.2em';
  if (!_.isString(size)) {
    size = `${size}px`
  }

  return <Icon path={path} size={size} {...rest}/>;
}
