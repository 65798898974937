import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, MultilanguageAttribute, Translations} from '@dreebit/form-attributes-core';
import MultilangInput from "../MultilangInput";
import EditableWrapper from "../Wrapper/EditableWrapper";
import {DisplayValueRenderArgs} from "../EditableAttributeContainer";
import _ from "lodash";
import './index.css';
import MultiLangDropdownIcon from "../MultilangInput/MultiLangDropdownIcon";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: MultilanguageAttribute,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

let lang = navigator.language || _.get(navigator, 'userLanguage', 'en');
lang = _.first(lang.replace("-", "_").split("_"));

class AttributeMultilangComponent extends Component<Props> {


  state = {
    // @ts-ignore
    selectedLanguage: this.props.attribute.language ? this.props.attribute.language
      : this.props.attribute.languages && this.props.attribute.languages.length
        ? _.get(this.props.translations, 'language', lang)
        : _.get(this.props.attribute.languages, "[0].value")
  }

  _handleLanguageChange = (lang: string) => {
    console.log("_handleLanguageChange", lang)
    this.setState({
      selectedLanguage: lang
    })
  }

  render() {
    const {attribute, style, form, translations, allValues, formProps, onFormItemClick} = this.props;

    const placeholder = _.get(attribute, 'placeholder');

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      allValues={allValues}
      formProps={formProps}
      translations={translations}
      form={form}
      shouldBeInsideClick={(e: any) => {
        const classname = _.get(e, 'target.className');
        if (classname && classname.split) {
          return !_.intersection(['ant-dropdown-menu-title-content', 'ant-dropdown-menu-item'], classname.split(" ")).length
        }
        return true
      }}
      displayValueRender={(args: DisplayValueRenderArgs | any) => {

        const value = _.get(args, 'value');
        let language = this.state.selectedLanguage;
        if ((language && value && _.get(value, language)) || (placeholder && typeof placeholder !== 'string')) {
          return <div className={"flex-row flex-content-center"}>
            <span className={`flex-grow ${placeholder ? 'placeholder' : ''}`}>
              {
                placeholder
                  ? _.get(placeholder, language)
                  : _.get(args.value, language)
              }
            </span>
            <span className={"flex-self-center"}>
              <MultiLangDropdownIcon
                languages={attribute.languages}
                selectedLanguage={language}
                onLanguageChange={this._handleLanguageChange}/>
            </span>
          </div>
        }

        if (value && typeof value === 'string') {
          return value;
        }

        if (value && typeof value === 'object') {
          return _.get(value, _.first(Object.keys(value)))
        }

        if (typeof args === 'string') {
          return args;
        }

        if (placeholder) {
          return <span className={`flex-grow placeholder`}>
            {placeholder}
          </span>
        }

        if (!args.value) {
          return "";
        }

        return Object.keys(args.value).map((key) => args.value[key]).join(", ");
      }}
      attribute={attribute}>

      {({value, onChange, onClick, inputRef, onKeyPress}) => {

        return <MultilangInput languages={attribute.languages}
                               selectedLanguage={this.state.selectedLanguage}
                               onSelectedLanguageChange={this._handleLanguageChange}
                               onKeyPress={onKeyPress}
                               value={value}
                               inputRef={inputRef}
                               onChange={onChange}
                               onClick={(event: any) => onClick && onClick(event, attribute.index, {language: this.state.selectedLanguage})}
                               id={attribute.index}
                               disabled={attribute.disabled}
                               className={`form-item-markdown-${attribute.index}`}
                               placeholder={placeholder}
        />
      }}

    </EditableWrapper>
  }
}

export default AttributeMultilangComponent;
