import * as React from 'react';
import {Component} from 'react';
import _ from "lodash";
import {format} from "d3-format";
import {Input} from "antd";
import {
  AttributeFormStyles,
  FormAttributeRenderContext,
  NumberAttribute,
  translateValues,
  Translations
} from '@dreebit/form-attributes-core';
import EditableWrapper from "../../Wrapper/EditableWrapper";
import {DisplayValueRenderArgs} from "../../EditableAttributeContainer";
import createAddon from "../../../utils/AttributeAddonHelper";
import NumberExtractor from "./NumberFormatter";
import {OnFormItemClick} from "../../../types";

type Props = {
  attribute: NumberAttribute<any>,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  float?: boolean,
  onFormItemClick?: OnFormItemClick
}

class AttributeNumberComponent extends Component<Props> {

  state = {
    changes: undefined
  };

  getFormat = () => {
    // @ts-ignore
    return this.props.attribute.format || this.props.attribute.inputTypeOptions?.numberFormat;
  }

  isNumber(input: any) {
    const string = `${input}`;
    if (this.props.float || _.get(this.props, 'attribute.targetType') === 'float') return /^[-+]?[0-9]*(\.|\,)?[0-9]+([eE][-+]?[0-9]+)?$/g.test(string);
    return /^[-+]?[0-9]*[0-9]+([eE][-+]?[0-9]+)?$/g.test(string);
  }

  formatValue(input: any, overwriteAttributeFormat: boolean, t?: any) {
    let tmp = input ? `${input}` : input;
    if (!tmp && tmp !== 0) {
      return ''
    }

    if (
      tmp
      // @ts-ignore
      && (this.props.attribute.format === 'scientific' || this.props.attribute.inputTypeOptions?.scientific)
      && this.isNumber(tmp)
    ) {

      const parts = NumberExtractor.getNumberParts(Number(tmp));

      if (parts) {
        return <span className={'scientific-label'}>
                {parts.mantissa.toFixed(1)} ⋅ {parts.base} <sup>{parts.exponent}</sup>
            </span>
      }

    }

    if (this.props.attribute.format && overwriteAttributeFormat) {
      const formatFunction = format(this.props.attribute.format);
      if (typeof formatFunction === 'function') {
        tmp = format(this.props.attribute.format)(Number(tmp))
      }
    }
    const decimalSeparator = t ? t('decimalSeparator') : this.props.translations.decimalSeparator;

    // @ts-ignore
    if (tmp && tmp.replace && decimalSeparator !== "decimalSeparator") {
      tmp = tmp.replace('.', decimalSeparator);
    }

    return tmp;
  }

  parseValue(input: string, t?: any): string | null {
    const decimalSeparator = t ? t('decimalSeparator') : this.props.translations.decimalSeparator;
    return input ? input.replace(decimalSeparator, '.') : null;
  }

  isFloat(value?: any) {
    if (value) {
      if (typeof value === 'string') value = Number(value);

      return value % 1 !== 0;
    }
    return true;
  }

  render() {
    const {attribute, style, form, translations, allValues, formProps, onFormItemClick} = this.props;
    const floatValue = this.props.float || _.get(attribute, 'targetType') === 'float' || _.get(attribute, 'format') === 'float';

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      form={form}
      translations={translations}
      allValues={allValues}
      formProps={formProps}
      loading={attribute.loading}
      displayValueRender={(args: DisplayValueRenderArgs) => {
        let t = _.get(translations, 't');
        if (!t) t = (text: string) => _.get(translations, text, text);

        if (!args.value && attribute.placeholder) {
          return <div className={'placeholder'}>{attribute.placeholder}</div>
        }
        return this.formatValue(args.value, true, t)
      }}
      attribute={attribute}>

      {({value, onChange, onKeyPress, inputRef, t, onClick, disabled}) => {

        let addOnAfter: any = createAddon(attribute, form, this.context.renderFactory, style, translations);

        return <Input
          type={'number'}
          ref={inputRef}
          step={this.props.float
            ? '0.00001'
            : '1'
          }
          value={this.state.changes ? this.state.changes : value}
          onChange={(event) => {
            let newValue: any = this.parseValue(event.target.value, t);

            if (value && /^\d$/.test(value) && _.isNil(newValue)) {
              this.setState({
                changes: newValue
              });

              return onChange(newValue)
            }

            if (!floatValue && this.isFloat(newValue)) {
              return this.setState({
                changes: value
              });
            }

            this.setState({
              changes: newValue
            });

            const translatedValue = _.get(translateValues([attribute], {[attribute.index]: newValue}), attribute.index, newValue);

            onChange(translatedValue)
          }}
          onKeyPress={onKeyPress}
          addonAfter={addOnAfter}
          disabled={disabled || attribute.disabled}
          placeholder={attribute.placeholder}
          onClick={(event) => onClick && onClick(event, attribute.index)}
        />
      }}

    </EditableWrapper>
  }
}

AttributeNumberComponent.contextType = FormAttributeRenderContext;

export default AttributeNumberComponent;
