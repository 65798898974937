import * as React from 'react';
import {Component} from 'react';
import _ from "lodash";
import {message, Modal, Upload} from 'antd';

import Loader from "../Loader";
import {Icon} from "../Icon";

const Dragger = Upload.Dragger;

type Size = 'small' | 'large';

type Props = {
  multiple?: boolean,
  customContent?: any,
  accept?: string,
  action?: string,
  onChange: (fileList: any[]) => void,
  headers?: any,
  uploadKey?: string,
  onUpload?: Function,
  hideSuccessMessages?: boolean,
  autoRemove?: boolean,
  size?: Size,
  t?: any
  disabled?: any,
  fileList?: any,
  onRemove?: (file: any) => boolean | Promise<any>,
  onPreview?: (file: any) => void,
  onDownload?: (file: any) => void,
  withCredentials?: boolean,
  showLoading?: boolean
  onStatusChange?: any
}

type State = {
  visible: boolean,
  loading: boolean,
  fileList: [],
}

class FileUploader extends Component<Props, State> {

  uploadedFilesMap: any;

  constructor(props: Props) {
    super(props);

    this.uploadedFilesMap = {};

    this.state = {
      visible: false,
      loading: false,
      fileList: this.props.fileList || [],
    }

  }


  triggerChange(fileList: any) {
    if (this.props.onChange) {
      this.props.onChange(fileList);
    }
  }

  render() {

    const {
      multiple, accept, hideSuccessMessages, autoRemove, size, onRemove,
      onPreview, onDownload, withCredentials, customContent, headers, action,
      uploadKey, t, disabled, onStatusChange, showLoading
    } = this.props;

    const {fileList} = this.state;

    const draggerProps = {
      fileList,
      accept,
      name: uploadKey,
      multiple,
      disabled,
      showUploadList: true,
      action,
      headers,
      onRemove,
      onPreview,
      onDownload,
      withCredentials,
      onChange: (info: any) => {
        const status = info.file.status;
        const response = info.file.response;

        if (status === 'uploading' && showLoading) {
          this.setState({
            visible: true,
            loading: true
          })
        }

        if (onStatusChange) onStatusChange(status);

        let fileList = info.fileList;
        if (!this.props.multiple && fileList.length > 1) {
          fileList = [fileList[fileList.length - 1]];
        }

        if (autoRemove) {
          fileList = fileList.filter((item: any) => {
            return item.status !== 'done';
          })
        }

        this.setState({
          fileList
        });

        if (this.props.multiple && showLoading && info.fileList) {
          const uploadingFiles = info.fileList.filter((item: any) => {
            return item.status === 'uploading';
          });

          if (_.isEmpty(uploadingFiles)) {
            this.setState({
              visible: false,
              loading: false
            });
          }
        }

        if (status === 'done') {
          if (!this.props.multiple) {
            this.setState({
              visible: false,
              loading: false
            });
          }

          if (!hideSuccessMessages) {
            message.success(`${info.file.name} ${t('file uploaded successfully')}.`);
          }
          this.uploadedFilesMap[response.id] = info.file;
          if (this.props.onUpload) {
            this.props.onUpload(info.file);
          }
        } else if (status === 'error') {
          if (!this.props.multiple) {
            this.setState({
              visible: false,
              loading: false
            })
          }

          message.error(`${info.file.name} ${t('file upload failed.')}`);
        }

        this.triggerChange(fileList);

      },
    };

    return <div>
      <Dragger {...draggerProps}
               listType={"picture"}
      >
        {customContent ?
          customContent
          :
          size === 'small' ? <div>
            <p>
              <Icon type={"InboxOutlined"} style={{marginRight: 16, fontSize: 18}}/>

              {t('Click or drag file to this area to upload')}
            </p>
          </div> : <div>
            <p className="ant-upload-drag-icon">
              <Icon type={"InboxOutlined"}/>
            </p>
            <p className="ant-upload-text">{t('Click or drag file to this area to upload')}</p>
            <p className="ant-upload-hint">
              {t('Support for a single or bulk upload')}
            </p>
          </div>
        }


      </Dragger>
      {
        showLoading
          ? <Modal
            title={t('Uploading') + '...'}
            visible={this.state.visible}
            footer={null}
            onCancel={() => null}
            onOk={() => null}
            closable={false}
          >
            <Loader/>
          </Modal>
          : null
      }
    </div>;
  }

}

export default FileUploader
