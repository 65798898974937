import * as React from 'react';
import {Component} from 'react';
import _ from "lodash";
import File from "./../File"
import {AttributeFormStyles, Translations} from '@dreebit/form-attributes-core';
import EditableWrapper from "../Wrapper/EditableWrapper";
import {Avatar, List} from "antd";
import {OnFormItemClick} from "../../types";

function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}


type Props = {
  attribute: any,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

class AttributeFileComponent extends Component<Props> {
  render() {
    const {attribute, style, form, translations, allValues, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      form={form}
      translations={translations}
      formProps={formProps}
      allValues={allValues}
      attribute={attribute}>

      {({value, onChange, t}) => {

        const files = _.get(attribute, "files");

        return <div>
          {
            files && files.length && value ?
              <List
                itemLayout="horizontal"
                dataSource={files}
                renderItem={item => {

                  const size = _.get(item, 'size');
                  const contentType = _.get(item, 'contentType');
                  return <List.Item
                    actions={[<a onClick={() => {
                      onChange(null);
                    }}>{t('Delete')}</a>]}
                  >
                    <List.Item.Meta
                      avatar={<Avatar shape={"square"} size={170} src={_.get(item, 'thumbnailLink')}/>}
                      title={<a target={"_BLANK"} href={_.get(item, 'link')}>{_.get(item, 'filename')}</a>}
                      description={`${size ? `${bytesToSize(size)} ` : ""}${contentType ? contentType : ""}`}
                    />
                  </List.Item>
                }}
              />
              : null
          }

          <File
            uploadKey={attribute.uploadKey}
            headers={attribute.headers}
            disabled={attribute.disabled}
            id={attribute.index}
            onUpload={attribute.onUpload}
            action={attribute.action}
            imageOnly={attribute.imageOnly}
            valueKey={attribute.valueKey}
            previewKey={attribute.previewKey}
            className={`form-item-select-${attribute.index}`}
            filenameKey={attribute.filenameKey}
            multiple={attribute.multiple}
            value={value}
            onChange={onChange}
            t={t}
            fileList={attribute.fileList}
            onRemove={attribute.onRemove}
            onPreview={attribute.onPreview}
            onDownload={attribute.onDownload}
            withCredentials={attribute.withCredentials}
            onStatusChange={attribute.onStatusChange}
            showLoading={attribute.showLoading}
            {...attribute.inputProps}
          />
        </div>
      }}

    </EditableWrapper>
  }
}

export default AttributeFileComponent;
