import * as React from "react";
import _ from "lodash";
import AttributeForm from "../AttributesForm";
import {Attribute} from "@dreebit/form-attributes-interfaces";

interface Props {
  collapse: string[],
  attributes: Attribute<any>[],
  values: any
}

const ModalForm = (props: Props): React.ReactElement => {
  const showKeys = Object.keys(props.values).filter((key) => {
    return props.values[key] !== undefined && props.values[key] !== null
  });

  let collapse = _.get(props, 'collapse', []);

  return <AttributeForm
    layout={'inline'}
    disableSubmit={true}
    valueToolbar={true}
    {...props}
    collapse={[...collapse, ...showKeys]}
  />
}

ModalForm.defaultProps = {
  collapse: []
}

export default ModalForm;
