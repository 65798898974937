import _ from "lodash";

const findKey = (object: any, key: string) => {

    if (!object) {
        return null;
    }

    if (object[key]) {
        return object[key]
    }

    let result = null;

    Object.keys(object).forEach((item) => {
        const tmp = object[item];

        if (_.isObject(tmp)) {
            result = findKey(tmp, key);
        }
    });

    return result;
};

export default findKey;
