import * as React from 'react';
import {Component} from 'react';
import * as _ from "lodash";
import {Input} from "antd";
import {AttributeFormStyles, TextAttribute, Translations} from '@dreebit/form-attributes-core';
import EditableWrapper from "../Wrapper/EditableWrapper";
import {DisplayValueRenderArgs} from "../EditableAttributeContainer";
import {OnFormItemClick} from "../../types";

const {TextArea} = Input;

type Props = {
  attribute: TextAttribute<any>,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

class AttributeTextAreaComponent extends Component<Props> {
  state = {
    value: null
  }

  _handleChanges = (changes: string, onChange: (value: string) => void) => {

    onChange(changes);
    this.setState({
      value: null
    })
  }

  debounceChanges = _.debounce(this._handleChanges, this.props.attribute.debounceTime || 0);

  render() {
    const {attribute, style, form, allValues, translations, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      allValues={allValues}
      translations={translations}
      formProps={formProps}
      displayValueRender={(args: DisplayValueRenderArgs) => {
        return args.value
      }}
      form={form}
      disableEnter={true}
      attribute={attribute}>

      {({value, onChange, onKeyPress, inputRef, onClick}) => {

        return <TextArea
          ref={inputRef}
          value={this.state.value !== null ? this.state.value : value}
          onChange={(event) => {
            const value = event.target.value;
            if (attribute.debounceTime) {
              this.setState({
                value,
              }, () => {
                this.debounceChanges(value, onChange);
              })

            } else {
              onChange(value)
            }
          }}
          onKeyPress={onKeyPress}
          disabled={attribute.disabled}
          id={attribute.index}
          placeholder={attribute.placeholder}
          className={`form-item-textarea-${attribute.index}`}
          rows={5}
          onClick={(event) => onClick && onClick(event, attribute.index)}
        />
      }}

    </EditableWrapper>
  }
}

export default AttributeTextAreaComponent;
