// @ts-nocheck
import * as React from "react";

import {
  AttributeFormStyles,
  AttributeInputType,
  InputTypeMap,
  MultilanguageAttribute,
  Translations
} from "@dreebit/form-attributes-core";
import AttributeTextComponent from "../components/FormItems/AttributeTextComponent";
import AttributeSwitchComponent from "../components/FormItems/AttributeSwitchComponent";
import AttributeSelectComponent from "../components/FormItems/AttributeSelectComponent";
import AttributeSectionComponent from "../components/FormItems/AttributeSectionComponent";
import AttributeNumberComponent from "../components/FormItems/AttributeNumberComponent/index";
import AttributeDatePickerComponent from "../components/FormItems/AttributeDatePickerComponent";
import AttributeTimePickerComponent from "../components/FormItems/AttributeTimePickerComponent";
import AttributeDateRangePickerComponent from "../components/FormItems/AttributeDateRangePickerComponent";
import AttributeCheckboxComponent from "../components/FormItems/AttributeCheckboxComponent";
import AttributeRadioboxComponent from "../components/FormItems/AttributeRadioboxComponent";
import AttributeTableComponent from "../components/FormItems/AttributeTableComponent";
import AttributeLabelComponent from "../components/FormItems/AttributeLabelComponent";
import AttributeTreeComponent from "../components/FormItems/AttributeTreeComponent";
import AttributeMultilangComponent from "../components/FormItems/AttributeMultilangComponent";
import AttributeFileComponent from '../components/FormItems/AttributeFileComponent';
import AttributeTextAreaComponent from "../components/FormItems/AttributeTextAreaComponent";
import AttributeMarkdownComponent from "../components/FormItems/AttributeMarkdownComponent";
import AttributePasswordComponent from "../components/FormItems/AttributePasswordComponent";
import AttributeHoursComponent from "../components/FormItems/AttributeHoursComponent";
import AttributeButtonComponent from "../components/FormItems/AttributeButtonComponent";
import AttributeSelectListComponent from "../components/FormItems/AttributeSelectListComponent";
import AttributeFormComponent from "../components/FormItems/AttributeFormComponent";
import AttributeCardPickerComponent from "../components/FormItems/AttributeCardPickerComponent";
import AttributeCustomComponent from "../components/FormItems/AttributeCustomComponent";
import AttributeHiddenComponent from "../components/FormItems/AttributeHiddenComponent";
import AttributeDurationComponent from "../components/FormItems/AttributeDurationComponent";
import AttributeCurrencyComponent from "../components/FormItems/AttributeCurrencyComponent";
import AttributeNumberSelectComponent from "../components/FormItems/AttributeNumberSelectComponent";

export class InputTypeFactory {

  static build(): InputTypeMap {

    return {
      [AttributeInputType.LABEL]: (attribute, form, style: AttributeFormStyles, _undefined: any, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeLabelComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.TEXT]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeTextComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.PASSWORD]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributePasswordComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.NUMBER]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeNumberComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.CURRENCY]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeCurrencyComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.INT]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeNumberComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.FLOAT]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeNumberComponent float={true} key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.TEXTAREA]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeTextAreaComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.MARKDOWN]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeMarkdownComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.SWITCH]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeSwitchComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.SELECT]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeSelectComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.SELECTLIST]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeSelectListComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.PICKER]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeSelectComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.TAG]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeSelectComponent mode={"tag"} key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.SECTION]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeSectionComponent key={attribute.index} attribute={attribute} translations={translations} form={form} style={style}/>
      },
      [AttributeInputType.DATEPICKER]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeDatePickerComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.TIMEPICKER]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeTimePickerComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.DATERANGEPICKER]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeDateRangePickerComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.CHECKBOX]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeCheckboxComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.RADIO]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeRadioboxComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.AUTOCOMPLETE]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeSelectComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.DATATABLE]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeTableComponent key={attribute.index} attribute={attribute} form={form}
                                        style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.MULTISELECT]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeSelectComponent key={attribute.index} attribute={attribute} form={form}
                      style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.TREE]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeTreeComponent key={attribute.index} attribute={attribute} form={form}
                                       style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.MULTILANG]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeMultilangComponent key={attribute.index} attribute={attribute as MultilanguageAttribute} form={form}
                                       style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      } ,
      [AttributeInputType.FILE]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeFileComponent key={attribute.index} attribute={attribute} form={form}
                                       style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.HOURS]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeHoursComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.DURATION]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeDurationComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.BUTTON]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeButtonComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.FORM]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeFormComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.CARDPICKER]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeCardPickerComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.CUSTOM]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeCustomComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.HIDDEN]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeHiddenComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      },
      [AttributeInputType.NUMBERSELECT]: (attribute, form, style: AttributeFormStyles, _undefined, translations: Translations, allValues, formProps, onFormItemClick) => {
        return <AttributeNumberSelectComponent key={attribute.index} attribute={attribute} form={form} style={style} translations={translations} allValues={allValues} formProps={formProps} onFormItemClick={onFormItemClick}/>
      }
    }
  }

}
