// @ts-nocheck
import _ from 'lodash';
import {
  AttributeConditionChecker,
  AttributeFormStyles,
  AttributeGroup,
  checkAttributesValidation,
  FormAttributeRenderContext,
  FormValueTranslator,
  TextAttribute,
  translateValues
} from "@dreebit/form-attributes-core";
import {Button, Form, message, Popconfirm, Row} from "antd";
import AttributesFormGroupWrapper from "./GroupWrapper";
import * as React from "react";
import {ReactNode, useContext, useRef, useState} from "react";
import './index.css';
import AttributeFilterValueLabel from "../AttibuteFilterValueLabel";
import {AttributeTargetType, Attribute} from "@dreebit/form-attributes-interfaces";
import {OnFormItemClick} from "../../types";
import translate from "../../utils/translate";


export interface SubmitReturn {
  message?: string,
  successIndicators?: string[],
  errorIndicators?: string[],
}

export interface SubmitArguments {
  values: any
  changes: any
}

export type AttributeFormLayout = 'vertical' | 'horizontal' | 'inline';

function hasErrors(fieldsErrors: any[]) {
  return fieldsErrors.filter((item) => item.errors.length > 0).length > 0;
}

type Props = {
  className?: any,
  onFieldsChange?: any
  editable?: boolean,
  clearOnSubmit?: boolean,
  useTabs?: boolean,
  validateChanges?: boolean,
  onValidation?: (err: any, values?: any) => void,
  attributes: Attribute<any>[],
  collapse?: string[],
  layout?: AttributeFormLayout,
  values?: any,
  onChange?: Function,
  onSubmitClick?: ({values: any, changes: any}) => any,
  onEnter?: Function,
  form?: any,
  disableSubmit?: boolean,
  hideSubmit?: boolean,
  disabled?: boolean,
  submit?: (submitArgs: SubmitArguments) => Promise<SubmitReturn | void> | void,
  submitButtonLoading?: boolean,
  submitTitle?: string,
  submitButtonProps?: any,
  footerStyleClass?: string,
  beforeSubmit?: ReactNode,
  afterSubmit?: ReactNode,
  groups?: AttributeGroup[],
  confirm?: boolean | string,
  styles?: AttributeFormStyles,
  attributeConditionChecker?: any,
  enableInitOnChange?: boolean,
  onInit?: (initValues: any, props?: any) => void,
  allValues?: any,
  valueToolbar?: boolean,
  valueToolbarOnly?: boolean,
  hideRequiredStar?: boolean,
  hideLabels?: boolean,
  hideSubmitFooter?: boolean
  preserve?: boolean,
  onFormItemClick?: OnFormItemClick,
  disableSubmitButton?: boolean,// i do not know where this is used but it is needed in lingua-react
  submitOnChange?: boolean,// i do not know where this is used but it is needed in lingua-react
  useSubmit?: boolean,// i do not know where this is used but it is needed in lingua-react
}

const _handleChanges = async (changed: any, all: any, props: any, form, state, setState) => {

  /*
    const all = allFields.reduce((acc: any, cur: any) => {
      const key = _.get(cur,'name',[]).join('.')
      acc[key] = cur.value
      return acc;
    },{});

    const changed = changedFields.reduce((acc: any, cur: any) => {
      const key = _.get(cur,'name',[]).join('.')
      if ((cur.dirty || cur.touched) && !cur.validating){
        acc[key] = cur.value
      }
      return acc;
    },{});
  */

  const values = translateValues(props.attributes, changed, props.groups);
  const allValues = translateValues(props.attributes, all, props.groups);

  const sendChanges = async () => {

    setState({
      ...state,
      cacheValues: allValues
    });

    if (props.onChange) {
      const result = await props.onChange(values, allValues, Object.keys(allValues).map((key) => form.getFieldInstance(key)).reduce((acc: any, cur: any) => {
        const key = _.get(cur, 'name', []).join('.');
        acc[key] = cur;
        return acc;
      }, {}));

      return 'test';
    }

    return;
  };

  if (Object.keys(changed).length) {
    if (props.validateChanges || props.onValidation) {
      setTimeout(() => {
        if (form) {
          form.validateFields(Object.keys(changed))
            .then((values: any) => {
              if (props.onValidation) {
                const attributes = props.attributeConditionChecker.getFulfilledAttributes(values);

                const valid = !!attributes.reduce((acc, attribute): boolean => {
                  if (!acc) return acc;
                  if (_.findIndex(attribute.rules, {required: true}) > -1) {
                    return !!_.get(values, attribute.index);
                  }
                  return acc;
                }, true);

                props.onValidation(valid ? null : false, translateValues(attributes, values));
              }

              return sendChanges();
            })
            .catch((err: any) => {
              if (props.onValidation) {
                props.onValidation(err, allValues);
              }

              return sendChanges();
            });
        } else {
          return sendChanges();
        }
      }, 0)
    } else {
      return sendChanges();
    }
  }

  return;
};

const _handleSubmit = (props, form, state, setState) => {
  const {submit, attributes, onSubmitClick, onEnter} = props;

  form.validateFields()
    .then((values: any) => {
      const allValues = translateValues(attributes, values);

      if (props.onValidation) {
        props.onValidation(null, allValues);
      }

      const changedKeys = Object.keys(form.getFieldsValue()).filter(x => {
        return form.isFieldTouched(x);
      });
      const changes = translateValues(attributes, form.getFieldsValue(changedKeys));

      if (onSubmitClick) {
        onSubmitClick({
          values: allValues,
          changes
        });
      }
      if (onEnter) {
        onEnter({
          values: allValues,
          changes
        });
      }

      if (submit) {
        _setLoading(true, state, setState);

        return Promise.resolve(submit({
          values: allValues,
          changes
        }))
          .then((submitResult: SubmitReturn | void) => {
            if (submitResult) {
              if (submitResult.message) {
                message.success(submitResult.message);
              }

              if (submitResult.successIndicators) {
                setState({
                  showSuccessIndicators: submitResult.successIndicators
                }, () => {
                  setTimeout(() => {
                    setState({
                      showSuccessIndicators: []
                    })
                  }, 2000)
                })
              }
            }
          })
          .then(() => {

            if (props.clearOnSubmit) {
              form.resetFields();
            }
            _setLoading(false, state, setState);

          })
          .catch((err) => {
            message.error(err && err.message ? err.message : err);
            _setLoading(false, state, setState);
          })
      }
    }).catch((err) => {
    if (props.onValidation) {
      props.onValidation(err);
    }
    console.error("Validation Error", err);
  });
};

const _setLoading = (loading: boolean, state: any, setState: any) => {
  setState({
    ...state,
    loading
  })
};


const renderValueToolbar = (props, form) => {

  const values = _.get(props, "values", {});
  const valueLabels = props.attributes
    .filter((attribute) => {
      return !_.isNil(values[attribute.index]);
    })
    .map((attribute) => {

      let value = values[attribute.index];

      if (value === undefined) {
        value = attribute.value || _.get(attribute, 'defaultValue')
      }

      if (value === null || value === undefined || value === false) {
        return null;
      }

      if (!Array.isArray(value)) {
        value = [value];
      }


      return <Form.Item
        name={attribute.index}
        initialValue={attribute.value}
        trigger={'onChange'}
        validateTrigger={'onChange'}
        style={{marginBottom: 0}}
      ><AttributeFilterValueLabel value={value} attribute={attribute}/></Form.Item>


    }).filter((item: any) => !!item)

  return <div style={{
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }}>
    {valueLabels.map((item, index) => {
      return <span key={`${index}`} style={{marginRight: 5}}>{item}</span>
    })}
  </div>
}

const renderGroups = (props, form, state, setState, renderFactory, translations) => {
  let {styles, layout, disabled, allValues, groups, onFormItemClick} = props;

  /**
   * Check Attribute Condition
   */
  const attributeConditionChecker = new AttributeConditionChecker(props.attributes, groups);
  let fullFilledAttributes = attributeConditionChecker.getFulfilledAttributes(state.cacheValues) || props.attributes;

  let attributes = fullFilledAttributes.map((attr) => _.find(props.attributes, {index: attr.index}))

  if (disabled) {
    attributes = attributes.map((attribute: any) => {
      return {
        ...attribute,
        disabled: true
      }
    })
  }

  if (!renderFactory) {
    console.error(new Error('No RenderFactory found in context. Please use FormAttributeRenderContext'));
    return null;
  }

  const formItemStyle = {
    ...getStyles(layout, styles, props.editable),
  };

  const showSuccessIndicators: string[] = state.showSuccessIndicators || [];

  if (groups && groups.length) {

    return <AttributesFormGroupWrapper
      useTabs={props.useTabs}
      groups={groups}
      renderGroup={(group?: any) => {

        const groupAttributes = attributes.filter((attr) => attr.groups && attr.groups.indexOf(group.index) !== -1);
        const groupItems: ReactNode[] = renderFactory.renderAttributes(groupAttributes.map((attribute) => ({
          hasFeedback: showSuccessIndicators.includes(attribute.index),
          ...attribute,
        })), form, {
          ...formItemStyle,
          ...group.style
        }, translations, allValues, props, onFormItemClick);

        return groupItems;

      }}
    />
  }

  let filteredAttributes: Attribute<any>[] = attributes.map((attribute) => ({
    hasFeedback: showSuccessIndicators.includes(attribute.index),
    ...attribute,
  }));

  const collapse = _.get(props, 'collapse', []);
  if (state.collapsed) {
    filteredAttributes = filteredAttributes.filter((item: Attribute<any>) => {
      return collapse.indexOf(item.index) > -1
    })
  }

  return <Row gutter={8} wrap={true}
  >
    {renderFactory.renderAttributes(filteredAttributes, form, formItemStyle, translations, allValues, props, onFormItemClick)}
    {collapse.length ? renderCollapse(props, state, setState, translations) : null}
    {layout === "inline" && props.submit ? renderSubmit(props, form, state, setState, translations) : null}
  </Row>;
};

const renderCollapse = (props, state, setState, translations) => {
  const {disableSubmit, hideSubmit, layout, styles} = props;
  let t = _.get(translations, 't');
  if (!t) t = (text: string) => _.get(translations, text, text);

  const formItemLayout = _.get(getStyles(layout, styles, props.editable), "tailFormItemLayout");

  const collapse = <span className={"show-more-button"} style={{
    paddingRight: 10, paddingTop: 10,
    display: "inline-block"
  }}>
      {state.collapsed ? <a onClick={() => setState({collapsed: false})}>
        {t("Show more")}
      </a> : <a onClick={() => setState({collapsed: true})}>
        {t("Show less")}
      </a>}
    </span>

  return !disableSubmit && layout !== "inline" ? <Form.Item
    {...formItemLayout}
  >
    {
      collapse
    }

  </Form.Item> : collapse
};

const renderSubmit = (props, form, state, setState, translations) => {
  const {
    hideSubmitFooter,
    disableSubmit,
    submitButtonLoading,
    disableSubmitButton,
    hideSubmit,
    submitButtonProps,
    confirm,
    submitTitle,
    submit,
    attributes,
    beforeSubmit,
    afterSubmit,
    footerStyleClass
  } = props;

  if (disableSubmit || hideSubmitFooter) return null;

  let hide = hideSubmit || !submit;
  let disabled = disableSubmitButton;
  let t = _.get(translations, 't');
  if (!t) t = (text: string) => _.get(translations, text, text);

  let SubmitButton = <Button disabled={disabled} loading={submitButtonLoading || state.loading} type="primary"
                             htmlType="submit" {...submitButtonProps}>{submitTitle || t('Submit')}</Button>;

  if (form) {
    const {getFieldsError} = form;

    const valid = checkAttributesValidation(props.attributes, props.values, props.groups);
    disabled = disabled || hasErrors(getFieldsError()) || !_.get(valid, 'valid', true);

    const changedKeys = Object.keys(form.getFieldsValue(true)).filter(x => {
      return form.isFieldTouched(x);
    });

    const confirmKeys = attributes.filter(item => item.confirm && changedKeys.indexOf(item.index) > -1).map(item => item.index);

    if (confirmKeys.length || confirm) {
      SubmitButton = <Popconfirm
        title={confirm === true ? t("Are you sure") : confirm}
        onConfirm={() => _handleSubmit(props, form, state, setState)}
        okText={t("yes")}
        cancelText={t("no")}
      >
        <Button disabled={disabled} loading={state.loading}
                type="primary" {...submitButtonProps}>{submitTitle || t('Submit')}</Button>
      </Popconfirm>;
    }
  }

  let justifyContent = 'space-between';
  if ((!beforeSubmit && !afterSubmit) || (!beforeSubmit && hide && afterSubmit)) {
    justifyContent = 'flex-end';
  }

  return <div className={`FormSubmitWrapper ${justifyContent} ${footerStyleClass}`}
  >
    {beforeSubmit}
    {hide ? null : SubmitButton}
    {afterSubmit}
  </div>;
};


const getStyles = (layout?: AttributeFormLayout = 'horizontal', styles?: AttributeFormStyles, editable?: boolean) => {


  if (layout === "inline") {
    return {
      editable,
    };
  }
  if (layout === 'horizontal') {
    const defaultStyle: AttributeFormStyles = {
      formItemLayout: {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 24,
            offset: 0,
          },
        },
      },
    }
    return {
      ...defaultStyle,
      ...styles,
      editable,
    }
  }


  if (layout === 'vertical') {

    const defaultStyle: AttributeFormStyles = {
      formItemLayout: {
        labelCol: {
          xs: {span: 24},
          sm: {span: 24},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 24},
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 24,
            offset: 0,
          },
        },
      },
    }
    return {
      ...defaultStyle,
      ...styles,
      editable,
    }

  }

  return null;
}

export default (orgProps: Props) => {
  if (!orgProps?.attributes || orgProps?.attributes?.length === 0) return null;

  return <AttributesForm {...orgProps}/>
}

const AttributesForm = (orgProps: Props) => {
  let ref = useRef();
  let [form] = Form.useForm();
  const context = useContext(FormAttributeRenderContext);
  const {t, language} = context.translations;

  if (orgProps.form) form = orgProps.form;
  if (orgProps.ref) ref = orgProps.ref;

  const disableSubmit = orgProps.disableSubmit !== undefined ? orgProps.disableSubmit : orgProps.editable;

  const attributeValues = {
    ...orgProps.attributes.reduce((acc, cur) => {
      let value = _.get(cur, 'value') || _.get(cur, 'defaultValue');
      if (!_.isNil(value)) {
        acc[cur.index] = value;
      } else if (_.get(cur, 'targetType', '').toUpperCase() === AttributeTargetType.BOOLEAN) {
        acc[cur.index] = false;
      }

      return acc;
    }, {})
  };

  const initValues = {
    ...orgProps.defaultValues,
    ...attributeValues,
    ...orgProps.values
  }

  const [state, setState] = useState({
    cacheValues: initValues,
    collapsed: orgProps.collapse && orgProps.collapse.length > 0
  });

  const attributeConditionChecker = new AttributeConditionChecker(orgProps.attributes, orgProps.groups);
  let attributes = attributeConditionChecker.getFulfilledAttributes(state.cacheValues || initValues)
    .map((attribute: Attribute<any>) => {
      let value = _.get(state, `cacheValues.${attribute.index}`, _.get(orgProps, `values.${attribute.index}`, _.get(attribute, 'value', _.get(attribute, 'defaultValue'))));
      if (_.isNil(value) && _.get(attribute, 'targetType', '').toUpperCase() === AttributeTargetType.BOOLEAN) {
        value = false;
      }

      const result = {
        ...attribute,
        value: _.get(FormValueTranslator.valueForField(attribute, value), 'value')
      };

      // keys of the attribute that may contain a translation object that needs to be resolved
      const translatableKeys = ['name', 'hint', 'confirmText', "addOn.title"];
      translatableKeys.forEach((key) => {
        if (_.get(result, key)) _.set(result, key, translate(_.get(result, key), t, language));
      });

      const textAttribute: TextAttribute<any> = result;
      if (textAttribute.addOnAttribute) {
        const val = FormValueTranslator.valueForField(textAttribute.addOnAttribute, _.get(state, `cacheValues.${attribute.index}`, _.get(orgProps, `values.${textAttribute.addOnAttribute.index}`, textAttribute.addOnAttribute.value)));
        textAttribute.addOnAttribute.value = _.get(val, 'value');
      }
      return result;
    });

  if (orgProps.hideLabels) attributes = attributes.map((attribute: Attribute<any>) => {
    return {
      hideLabel: true,
      ...attribute
    }
  });

  const props = {
    ...orgProps,
    attributes,
    attributeConditionChecker,
    values: state.cacheValues
  }

  let {
    styles,
    submit,
    layout,
    className,
    values,
    editable,
    onInit,
    enableInitOnChange,
    collapse,
    valueToolbarOnly,
    onFieldsChange,
    preserve
  } = props;

  React.useEffect(() => {
    if (onInit) onInit(state.cacheValues, props);
    if (enableInitOnChange) _handleChanges(state.cacheValues, state.cacheValues, props, form, state, setState);
  }, []);

  React.useEffect(() => {
    const newInitValues = {
      ...orgProps.defaultValues,
      ...attributeValues,
      ...orgProps.values
    }

    setState({
      ...state,
      cacheValues: newInitValues
    })
    if (!_.isEqual(newInitValues, state.cacheValues)) {
      form.setFieldsValue(newInitValues)
    }
  }, [orgProps.values]);

  let {renderFactory, translations} = context;
  if (!translations) {
    translations = {};
  }

  let t = _.get(translations, 't');
  if (!t) t = (text: string) => _.get(translations, text, text);

  const formItemLayout = _.get(getStyles(layout, styles, editable), "tailFormItemLayout");

  let validateMessages = {
    default: `${t("Validation error on field")} ${t("${name}")}`,
    required: `${t("${name}")} ${t("is required")}`,
    whitespace: `${t("${name}")} ${t("cannot be empty")}`,
    types: {
      email: `${t("${name}")} ${t("is not a valid email")}`,
      number: `${t("${name}")} ${t("is not a valid number")}`,
    },
    date: {
      format: `${t("${name}")} ${t("is invalid for format date")}`,
      parse: `${t("${name}")} ${t("could not be parsed as date")}`,
      invalid: `${t("${name}")} ${t("is invalid date")}`
    },
    string: {
      len: `${t("${name}")} ${t("must be exactly")} ${"${len}"} ${t("characters")}`,
      min: `${t("${name}")} ${t("must be at least")} ${"${min}"} ${t("characters")}`,
      max: `${t("${name}")} ${t("cannot be longer than")} ${"${max}"} ${t("characters")}`,
      range: `${t("${name}")} ${t("must be between")} ${"${min}"} ${t("and")} ${"${max}"} ${t("characters")}`
    },
    number: {
      len: `${t("${name}")} ${t("must equal")} ${"${len}"}`,
      min: `${t("${name}")} ${t("cannot be less than")} ${"${min}"}`,
      max: `${t("${name}")} ${t("cannot be greater than")} ${"${max}"}`,
      range: `${t("${name}")} ${t("must be between")} ${"${min}"} ${t("and")} ${"${max}"}`
    },
    array: {
      len: `${t("${name}")} ${t("must be exactly")} ${"${len}"} ${t("in length")}`,
      min: `${t("${name}")} ${t("cannot be less than")} ${"${min}"} ${t("in length")}`,
      max: `${t("${name}")} ${t("cannot be greater than")} ${"${max}"} ${t("in length")}`,
      range: `${t("${name}")} ${t("must be between")} ${"${min}"} ${t("and")} ${"${max}"} ${t("in length")}`
    },
    pattern: {
      mismatch: `${t("${name}")} ${t("does not match pattern")} ${"${pattern}"}`
    }
  };

  const handleKeyUp = (event) => {
    if (!submit) {
      // Enter
      if (event.keyCode === 13) {
        ref.current.submit();
      }
    }
  };

  return <Form
    ref={ref}
    preserve={preserve}
    onKeyUp={handleKeyUp}
    layout={layout}
    form={form}
    initialValues={state.cacheValues || values}
    validateMessages={validateMessages}
    onFieldsChange={onFieldsChange}
    onValuesChange={(changedValues: any, allValues: any) => _handleChanges(changedValues, allValues, props, form, state, setState)}
    className={className}
    onFinish={(vals) => _handleSubmit(props, form, state, setState)}
  >

    {!valueToolbarOnly ? <>
      {renderGroups(props, form, state, setState, renderFactory, translations)}
      {!disableSubmit && layout !== "inline" ? <Form.Item
        {...formItemLayout}
      >
        {
          renderSubmit(props, form, state, setState, translations)
        }

      </Form.Item> : null}
    </> : null}

    {props.valueToolbar ? renderValueToolbar(props, form) : null}

  </Form>
};
