import React, {useState} from 'react';
import {AttributesForm} from "@dreebit/form-attributes-antd";
import * as _ from "lodash";
import {Alert} from "antd";
import {toastSuccess, toastError} from "../../utils/toast";
import moment from "moment";
import {useTranslation} from "react-i18next";

interface MutationValues {
    changes?: any,
    values?: any,
}

type Props = {
    className?: any,
    editable?: boolean,
    clearOnSubmit?: boolean,
    submitOnChange?: boolean,
    attributes: any[],
    groups?: any[],
    mutation?: any,
    overrideMutation?: (args?: any) => Promise<any>,
    translateMutationOptions?: (mutationValues: MutationValues) => any,
    onChange?: (changes: any, { allValues }: any) => Promise<any> | void,
    onError?: (error: Error) => void,
    submit?: boolean,
    onSubmit?: Function,
    collapse?: string[],
    submitTitle?: string,
    values?: any,
    defaultValues?: any,
    successMessage?: string,
    errorMessage?: string,
    errorPresentationMode?: 'toast' | 'box',
    profile?: any,
    cacheChanges?: boolean,
    formItemLayout?: any,
    useSubmit?: any,
    readOnly?: any,
    useTabs?: any,
    layout?: any,
    disableSubmit?: any,
    disabled?: boolean,
    disableToast?: boolean,
    client?: any,
    allValues?: any,
    enableInitOnChange?: boolean,
    footerStyleClass?: string,
    beforeSubmit?: any,
    afterSubmit?: any,
    styles?: any,
    confirm?: boolean | string,
    debounceTime?: number
};

type State = {
    changes?: any,
    visible?: boolean,
    index?: string,
    name?: string,
    startDate?: any,
    endDate?: any,
    errorText?: string|null,
}


const MutationForm = (props: Props) => {

    const {t} = useTranslation();

    let {
        debounceTime,
        attributes,
        clearOnSubmit,
        collapse,
        formItemLayout,
        useSubmit,
        readOnly,
        submitTitle,
        groups,
        overrideMutation,
        values,
        defaultValues,
        useTabs,
        className,
        editable,
        submitOnChange,
        submit,
        disabled,
        disableSubmit,
        disableToast,
        layout,
        errorPresentationMode,
        allValues,
        enableInitOnChange,
        afterSubmit,
        beforeSubmit,
        footerStyleClass,
        styles,
        confirm
    } = props;

    submit = submit || useSubmit || !disableSubmit;

    submitOnChange = props.submitOnChange || props.editable;
    groups = props.groups ? props.groups.map((item: any) => (
        {
            ...item,
            index: item.index || item.categoryIndex,
        }
    )) : undefined;
    defaultValues = props.attributes.reduce((acc: any, cur: any) => {
        if(cur.value === ""){
            acc[cur.index] = null;
        }

        if (cur.value === null || cur.value === undefined){
            acc[cur.index] = cur.value || cur.defaultValue
        }

        return acc;
    }, {});

    let cacheChanges = props.cacheChanges;

    const [errorText, setErrorText] = useState(null);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'M'));
    const [endDate, setEndDate] = useState(moment());
    const [changes, setChanges] = useState({});

    const formValues = cacheChanges ? {
        ...defaultValues,
        ...values,
        ...changes
    } : {
        ...defaultValues,
        ...values,
    };

    if (readOnly){

        attributes = attributes.map((item) => {
            return {
                ...item,
                inputType: 'label'
            }
        })
    }

    const handleSubmit = (values: any, changes: any) => {

        const {mutation, onError, errorPresentationMode, translateMutationOptions, successMessage, errorMessage, onSubmit, clearOnSubmit, onChange} = props;

        let mutationValues: MutationValues = _.clone(values);

        if (!mutationValues.values){
            mutationValues = {values: mutationValues}
        }

        // Clean values
        mutationValues.values = Object.keys(mutationValues.values).reduce((acc, key) => {
            const value = mutationValues.values[key];
            if (value !== undefined ){
                // @ts-ignore
                acc[key] = value;
            }
            return acc;
        }, {});

        mutationValues.changes = changes;



        const args = translateMutationOptions ? translateMutationOptions(mutationValues) : {variables: mutationValues.values};

        let promise = null;
        if (overrideMutation) {
            promise = overrideMutation(args)
        }
        else if (_.isFunction(mutation)){
            promise = mutation(args);
        }

        if (!promise || !promise.then){
            return;
        }

        return promise
            .then((res: any) => {
                if(!disableToast) toastSuccess(successMessage ? successMessage : t('Successfully saved'));
                return res;
            })
            .then((res: any) =>{
                if (onSubmit){
                    return onSubmit(res)
                }

                return res;
            })
            .then(() => {
                if (clearOnSubmit){
                    setChanges({});
                    if (onChange){
                        onChange({}, {})
                    }
                }
            })
            .catch((err: any) => {

                setErrorText(_.get(err, 'message', err));

                if (onError){
                    onError(err);
                }else if (errorPresentationMode === 'toast' && !disableToast){
                    toastError(errorMessage || err || t('An error occurred'));
                }

            })

    };


    const handleOnChange = (changedValues: any, allValues: any) => {
        const {onChange} = props;

        setChanges({
            ...changes,
            ...changedValues
        });

        return Promise.resolve().then(() => {
            return onChange ? onChange(changedValues, {
                allValues
            }) : null
        }).then(() => {
            if (submitOnChange){
                return handleSubmit(changedValues, changedValues);
            }
        })

    };

    return <div
        onDoubleClick={() => {
            console.log('Form:attributes',attributes)
            console.log('Form:values',formValues)
        }}>
        <AttributesForm
            allValues={allValues}
            enableInitOnChange={enableInitOnChange}
            useTabs={useTabs}
            className={className}
            editable={editable}
            styles={styles ? styles : formItemLayout ? {
                formItemLayout
            } : undefined}
            collapse={collapse}
            submitOnChange={submitOnChange}
            clearOnSubmit={clearOnSubmit}
            layout={layout}
            values={formValues}
            onChange={debounceTime ? _.debounce((changedValues: any, allValues: any) => handleOnChange(changedValues, allValues) ,debounceTime) : handleOnChange}
            attributes={attributes}
            groups={groups}
            useSubmit={useSubmit}
            submitTitle={submitTitle || t('Save')}
            disableSubmit={disableSubmit || editable}
            disableSubmitButton={_.isEmpty(changes)}
            disabled={disabled}
            submit={submit ? handleSubmit : null}
            footerStyleClass={footerStyleClass}
            beforeSubmit={beforeSubmit}
            afterSubmit={afterSubmit}
            confirm={confirm}
        />
        {errorText && errorPresentationMode === 'box' ? <Alert message={errorText} type="error" showIcon /> : null}
    </div>
};

MutationForm.defaultProps = {
    layout: 'horizontal',
    visible: false,
    index: null,
    cacheChanges: null,
    name: null,
    errorPresentationMode: 'toast'
};

export default MutationForm;
