import React from "react";
import _ from "lodash";
import {Icon} from "../Icon";

export type CompareIconOperator = "EQ" | "GT" | "GTE" | "LT" | "LTE" | "NE" | "IN" | 'NIN' | 'EMPTY' | 'NEMPTY' | 'REGEX' | 'BITMASK'

export const CompareOperatorTextMap = {
    "EQ": 'Equal',
    "NE": 'Not Equal',
    "GT": 'Greater than',
    "GTE": 'Greater than or equal',
    "LT": 'Less than',
    "LTE": 'Less than or equal',
    "IN": "In list",
    "NIN": "Not in list",
    "EMPTY": "Empty",
    "NEMPTY": "Not empty",
    "REGEX": "RegExp",
    "BITMASK": "Bitmask"
};

type Props = {
    operator: "EQ" | "GT" | "GTE" | "LT" | "LTE" | "NE" | "IN" | 'NIN' | 'EMPTY' | 'NEMPTY' | 'REGEX' | 'BITMASK',
    circled?: boolean,
    size?: number | string,
    color?: string,
    style?: any,
    className?: string,
    onClick?: any
}

const CompareIcon = (props: Props) => {

    const iconMap = {
        "EQ": "equal",
        "GT": "greater-than",
        "GTE": "greater-than-or-equal",
        "LT": "less-than",
        "LTE": "less-than-or-equal",
        "NE": "not-equal-variant",
        "IN": "MenuUnfoldOutlined",
        "NIN": "MenuFoldOutlined",
        "EMPTY": "mdiCircleOffOutline",
        "NEMPTY": "mdiCircleOutline",
        "REGEX": "mdiRegex",
        "BITMASK": "mdiNumeric0BoxMultipleOutline"
    }

    return <Icon
        type={_.get(iconMap, props.operator)}
        {...props}
    />
};

export default CompareIcon;
