// @ts-nocheck
import * as React from 'react';
import {Component} from 'react';
import {ConfigProvider, Empty, List, Select} from 'antd';
import * as _ from 'lodash';
import {Translations} from "@dreebit/form-attributes-core";
import {Icon} from '../Icon';

type ListOption = {
  title: string,
  value: string,
};

type Props = {
  value: Array<string>,
  options: Array<ListOption>,
  onChange: Function | undefined,
  translations: Translations,
  onSearch: Function | undefined,
  actions?: any[],
  removeIcon?: string,
  hideRemove?: boolean
};

class SelectList extends Component<Props> {
  static defaultProps = {
    removeIcon: 'delete'
  };

  onAdd = (item: string) => {
    let {value = []} = this.props;
    const {onChange} = this.props;

    if (!value) {
      value = [];
    }

    if (value.indexOf(item) !== -1) {
      return;
    }

    if (onChange) {
      onChange([...value, item]);
    }
  };

  onRemove = (item: string) => {
    const {onChange, value = []} = this.props;

    if (value.indexOf(item) === -1) {
      return;
    }

    if (onChange) {
      onChange(value.filter(valueItem => valueItem !== item));
    }
  };

  render() {
    let {value, translations, onSearch, removeIcon, hideRemove, actions} = this.props;
    const {options} = this.props;

    if (!value) {
      value = [];
    }

    const data = value.map(val => ({
      ..._.find(options, {value: val}),
      key: val,
    }));

    return (
      <div>
        <Select
          placeholder={translations["Please select"] || 'Please select'}
          showSearch={!!onSearch}
          autoClearSearchValue
          allowClear
          value={null}
          onSearch={onSearch ? (q: string) => {
            if (onSearch) {
              onSearch(q)
            }
          } : undefined}
          filterOption={!onSearch ? (input: any, option: any) =>
              _.get(option, 'props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0
            : false
          }
          onSelect={this.onAdd}
        >
          {options
            .filter(item => value.indexOf(item.value) === -1)
            .map((item) => (
              <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
            ))}
        </Select>
        {data.length ? <ConfigProvider renderEmpty={() => {
          return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        }}>
          <List
            size="small"
            bordered
            style={{marginTop: 10}}
            dataSource={data}
            renderItem={item => (
              <List.Item
                actions={[
                  ...actions ? actions : [],
                  hideRemove ? [] : <Icon type={removeIcon} onClick={() => {
                    this.onRemove(item.value);
                  }}/>
                ]}
              >
                {item.title}
              </List.Item>
            )}
          />
        </ConfigProvider> : null}

      </div>
    );
  }
}

export default SelectList;
