import * as React from 'react';
import {Component} from 'react';
import _ from "lodash";
import {DataLoaderConfig} from "@dreebit/form-attributes-core";
import ValueDataLoaderWrapper, {ValueDataLoaderWrapperRenderArguments} from "../Wrapper/ValueDataLoaderWrapper";

type Props = {
  dataLoaderConfig?: DataLoaderConfig,
  options?: any,
  value?: any,
}

class DataLoaderValueLabel extends Component<Props> {

  render() {
    const {dataLoaderConfig, value, options} = this.props;
    if (value === null || value === undefined) {
      return null;
    }

    return <ValueDataLoaderWrapper
      options={options}
      dataLoaderConfig={dataLoaderConfig}
      value={value}>
      {(args: ValueDataLoaderWrapperRenderArguments) => {
        const valueOptions = args.options;
        if (!valueOptions || !valueOptions.length) return value || null;
        const valueOption = _.first(valueOptions);
        return _.get(valueOption, 'title', _.get(valueOption, 'name', <span>{value}</span>))

      }}

    </ValueDataLoaderWrapper>
  }
}

export default DataLoaderValueLabel;
