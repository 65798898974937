// @flow
import React from 'react';
import {Col, Grow, Row} from "../Flex";

type Props = {
    borderBottom: boolean,
    renderLeft?: any,
    renderRight?: any,
    renderMiddle?: any,
    style: any,
    className?: any
};

const Toolbar = (props: Props) => {
    const {style, renderLeft, renderRight, renderMiddle, borderBottom, className} = props;

    return <Row
        className={className}
        style={{
            padding: '8px 16px',
            borderBottom: borderBottom ? '1px solid #ccc' : null,
            ...style
        }}
    >
        {
            renderLeft
                ? <Grow style={{
                    order: '0 !important'
                }}
                >
                    {renderLeft()}
                </Grow>
                : null
        }
        {
            renderMiddle
                ? <Grow style={{
                    order: '1 !important',
                    flex: 1
                }}
                >
                    {renderMiddle()}
                </Grow>
                : null
        }
        {
            renderRight
                ? <Col style={{
                    order: '2 !important'
                }}
                >
                    {renderRight()}
                </Col>
                : null
        }
    </Row>
};

Toolbar.defaultProps = {
    borderBottom: false,
    renderLeft: null,
    renderRight: null,
    renderMiddle: null,
    style: {}
};

export default Toolbar
