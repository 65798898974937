import * as React from 'react';
import {Component} from 'react';
import {Radio} from "antd";
import * as _ from "lodash";
import * as moment_ from "moment";

const moment = moment_;

type Props = {
  buttons: any,
  onClick: Function
  disabled: any
}

class TimeIntervalButtons extends Component<Props> {
  render() {
    const {buttons, onClick, disabled} = this.props;

    return buttons.length > 0
      ? <Radio.Group onChange={(val: any) => {
        val = _.get(val, 'target.value', {});
        let newValue = moment();

        if (val.direction === 'sub') {
          newValue = moment().subtract(val.interval, val.type)
        }
        if (val.direction === 'add' || !val.direction) {
          newValue = moment().add(val.interval, val.type)
        }

        this.setState({
          value: newValue
        });
        return onClick(newValue)
      }}
                     disabled={disabled}
      >
        {
          buttons.map((button: any, index: number) =>
            <Radio.Button
              key={`${index}`}
              value={{
                interval: button.interval,
                type: button.type,
                direction: button.direction
              }}>
              {_.get(button, 'name', '')}
            </Radio.Button>
          )
        }
      </Radio.Group>
      : null
  }
}

export default TimeIntervalButtons;
