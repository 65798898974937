import {OptionValue} from "@dreebit/form-attributes-core";
import * as _ from "lodash";

interface Options {
  max: number,
  min?: number,
  searchText?: string | number,
  allowCustom?: boolean,
  blackList?: number[]
}

export const getNumberOptionValueItems = (options: Options): { label: string, value: number }[] => {
  if (!options) return [];
  let {max, min, searchText, blackList, allowCustom} = options;

  if (max === undefined || max === null) return [];
  if (!min) min = 0;

  let items = _.range(min, max + 1);

  if (allowCustom && searchText) items = [
    Number(searchText),
    ...items
  ]

  return items
    /**
     * create option Value width value and title
     */
    .map((item: number) => {
      let title: string = item < 10 ? ("0" + String(item)) : String(item);

      return {
        value: item,
        label: title
      }
    })

    /**
     * Filter items by onSearch
     */
    .filter((optionValue: { value: number, label: string }) => {
      if (searchText) {
        if (typeof searchText === 'number') return optionValue.value === searchText;
        return optionValue.label.includes(searchText)
      }

      return true
    })

    /**
     * Remove Blacklisted items
     */
    .filter((optionValue: OptionValue<any>) => {
      return !(blackList && _.indexOf(blackList, optionValue.value) > -1)
    })
}
