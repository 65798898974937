import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, Translations} from '@dreebit/form-attributes-core';
import ValueLabel from "../ValueLabel";
import EditableWrapper from "../Wrapper/EditableWrapper";
import _ from "lodash";
import DataLoaderWrapper from "../Wrapper/DataLoaderWrapper";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: any,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

class AttributeLabelComponent extends Component<Props> {


  render() {
    const {attribute, style, form, allValues, translations, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      loading={attribute.loading}
      onFormItemClick={onFormItemClick}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      translations={translations}
      form={form}
      allValues={allValues}
      formProps={formProps}
      attribute={attribute}
    >
      {({value}) => {
        const getContent = (contentValue: any) => <ValueLabel
          addOn={attribute.addOn}
          placeholder={attribute.placeholder}
        >
          {attribute.renderInput
            ? attribute.renderInput(contentValue, attribute)
            : contentValue
          }
        </ValueLabel>;

        if (attribute.options || attribute.dataLoaderConfig) {
          return <DataLoaderWrapper
            form={form}
            value={_.get(value, 'key', value)}
            options={attribute.options}
            dataLoaderConfig={attribute.dataLoaderConfig}>
            {({options}) => {
              return getContent(_.get(_.find(options, {value}), 'title', value))
            }}
          </DataLoaderWrapper>
        }

        return getContent(value);
      }}
    </EditableWrapper>
  }
}

export default AttributeLabelComponent;
