import {Attribute, RenderFactory} from "@dreebit/form-attributes-core";
import * as React from "react";
import _ from "lodash";
import {Icon} from "../components/Icon";

const createAddon = (attribute: Attribute<any>, form: any, renderFactory: RenderFactory, style: any, translations: any) => {
  let addOnAfter: any = null;

  if (attribute.addOnAttribute){
    let addonAttribute = {
      ...attribute.addOnAttribute,
      hideLabel: true,
      noStyle: true
    };

    addOnAfter = _.first(renderFactory.renderAttributes([addonAttribute], form, {
      ...style,
      withoutFormItem: false,
    }, translations, {}, {layout: 'inline'} ));

  }else if (attribute.addOn){
     addOnAfter = createAddonLabel(attribute);
  }
  return addOnAfter;
}

export const createAddonLabel = (attribute: Attribute<any>) => {
  if (!attribute.addOn) return null

  let addOn: any = attribute.addOn;

  if (typeof addOn === "string"){
    if (addOn.startsWith("mailto:")){
      addOn = {
        icon: <a href={addOn}><Icon type={"MailOutlined"}/></a>,
      }
    }else {
      return <span className={"editable-addOn addOn-text"}>{addOn}</span>
    }
  }

  if (_.isObject(addOn)){
    return <div className={"editable-addOn "} onClick={() => addOn.onClick ? addOn.onClick() : null}
    >
      {addOn.title ? addOn.title : null}
      {addOn.icon ? addOn.icon : null}
    </div>
  }

  return null;
}

export default createAddon
