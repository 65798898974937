import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, RcForm, Translations, TreeAttribute} from '@dreebit/form-attributes-core';
import TreeInput from '../../TreeInput';
import EditableWrapper from "../../Wrapper/EditableWrapper";
import * as _ from "lodash";
import DataLoaderWrapper from "../../Wrapper/DataLoaderWrapper";
import Loader from "../../Loader";
import {OnFormItemClick} from "../../../types";

type Props = {
  attribute: TreeAttribute,
  form: RcForm,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

type State = {}

class AttributeTreeComponent extends Component<Props, State> {

  render() {
    const {attribute, style, form, allValues, translations, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      allValues={allValues}
      formProps={formProps}
      translations={translations}
      form={form}
      attribute={attribute}>

      {({value, onChange}) => {

        return <DataLoaderWrapper
          form={form}
          value={_.get(value, 'key', value)}
          onChange={onChange}
          dataLoaderConfig={attribute.dataLoaderConfig}>

          {({options, loading, error, onChange}) => {

            if (loading) {
              return <Loader size={12}/>;
            }

            if (error) return `Error!: ${error}`;

            return <TreeInput dataReducer={attribute.dataReducer}
                              treeData={attribute.data || options}
                              searchPlaceholder={attribute.placeholder}
                              value={value}
                              onChange={onChange}
                              disabled={attribute.disabled}
            />

          }}
        </DataLoaderWrapper>


      }}

    </EditableWrapper>
  }
}

// @ts-ignore
export default AttributeTreeComponent;
