import * as React from "react";
import {Component, ReactNode} from "react";
import {Col, Row, Tabs} from "antd";
import {AttributeGroup} from "@dreebit/form-attributes-core";
import _ from "lodash";
import './index.css';

interface Props {
  useTabs?: boolean,
  renderGroup: (group: AttributeGroup) => ReactNode[],
  groups: AttributeGroup[],
}

class AttributesFormGroupWrapper extends Component<Props> {

  state = {
    activeKey: undefined
  };

  render() {
    const {useTabs, groups, renderGroup} = this.props;

    if (useTabs) {
      // @ts-ignore
      return <Tabs
        activeKey={this.state.activeKey || _.chain(groups).first().get('index.css').value()}
        destroyInactiveTabPane
        onChange={(activeKey: string) => this.setState({
          activeKey
        })}
      >
        {
          groups.map((group) => {
            return <Tabs.TabPane key={group.index} tab={group.name}>
              <Row className={'full-width full-height'} gutter={8} wrap={true}
              >
                {renderGroup(group)}
              </Row>
            </Tabs.TabPane>
          })
        }
      </Tabs>
    }

    return <Row gutter={16} className={'AttributesInlineForm-group-wrapper'}>
      {
        groups.map((group: any) => {
          const items = renderGroup(group);

          if (items && items.length) {
            return <Col key={group.index}
                        className={'full-width full-height ' + _.get(group, 'colProps.className', `form-group-${group.index}`)}
                        {...group.colProps}
            >
              {
                group.name
                  ? <div className={'GroupHeaderWrapper'}>
                    <div className={'AttributesInlineForm-group-title'}>
                      {group.name}
                    </div>
                    {group.extra}
                  </div>
                  : null
              }

              <Row className={'AttributesInlineForm-group-fields'} gutter={8} wrap={true}
              >
                {renderGroup(group)}
              </Row>

              {group.footer ? <div className={'AttributesInlineForm-group-footer'}>{group.footer}</div> : null}

            </Col>
          }

          return null;
        })
      }
    </Row>
  }
}

export default AttributesFormGroupWrapper;
