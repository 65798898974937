// @ts-nocheck
import * as React from 'react';
import {
  AttributeFormStyles,
  CardOptionValue,
  CardPickerAttribute,
  RcForm,
  Translations
} from '@dreebit/form-attributes-core';
import EditableWrapper from "../../Wrapper/EditableWrapper";
import CardPickerItem from "./CardPickerItem";
import './index.css';
import * as _ from "lodash";
import {SizeMe} from "react-sizeme";
import {OnFormItemClick} from "../../../types";

type Props = {
  attribute: CardPickerAttribute<any>,
  form: RcForm,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

const AttributeCardPickerComponent = ({
                                        style,
                                        attribute,
                                        translations,
                                        form,
                                        allValues,
                                        formProps,
                                        onFormItemClick
                                      }: Props) => {

  return <EditableWrapper
    onFormItemClick={onFormItemClick}
    loading={attribute.loading}
    style={style}
    form={form}
    formProps={formProps}
    allValues={allValues}
    translations={translations}
    attribute={attribute}
  >
    {({value, onChange}) => {

      let {options, layout, maxItemsInRow, multiple} = attribute;
      if (!maxItemsInRow) maxItemsInRow = options ? options.length : 4;
      let maxAllowedItemsInRow = 4;

      if (!options || !options.length > 0) return null;

      const onCardClick = (newValue?: any) => {
        if (newValue) {
          if (multiple) {
            const tmpValue = _.cloneDeep(value) || [];
            if (tmpValue.indexOf(newValue) > -1) return onChange(tmpValue.filter((item) => item !== newValue));

            tmpValue.push(newValue);
            return onChange(tmpValue);
          }

          onChange(newValue);
        }
      };

      return <SizeMe
        refreshMode={"debounce"}
        monitorHeight
      >
        {({size}: any) => {
          let width = _.get(size, "width");

          if (layout === 'horizontal') {
            if (width < 500) {
              maxAllowedItemsInRow = 1;
            } else if (width < 750) {
              maxAllowedItemsInRow = 2;
            } else if (width < 1000) {
              maxAllowedItemsInRow = 3;
            } else if (width < 1100) {
              maxAllowedItemsInRow = 4;
            } else {
              maxAllowedItemsInRow = maxItemsInRow;
            }
          }

          return <div className={`full-width`}>
            <div className={`full-width ${layout === 'horizontal' ? 'cards_horizontal' : 'cards_vertical'}`}>
              {
                options.map((item: CardOptionValue, index: number) => {
                  if (!item) return null;

                  let active = false;
                  if (value) {
                    if (multiple && value.length) active = value.indexOf(_.get(item, 'value')) > -1;
                    else active = _.get(item, 'value') === value;
                  }

                  const flexWidth = maxItemsInRow <= maxAllowedItemsInRow ? 100 / maxItemsInRow : 100 / maxAllowedItemsInRow;

                  return <div
                    key={index}
                    className={`card ${active ? 'active' : null}`}
                    style={{
                      ..._.get(attribute, 'cardStyle', {}),
                      flex: `0 1 calc(${flexWidth}% - 1em )`,
                      boxSizing: 'border-box',
                      margin: '5px .5em',
                      overflow: 'hidden'
                    }}
                  >
                    <CardPickerItem
                      attribute={attribute}
                      item={item}
                      active={active}
                      onClick={onCardClick}
                    />
                  </div>
                })
              }
            </div>
          </div>
        }}
      </SizeMe>
    }}
  </EditableWrapper>
};

export default AttributeCardPickerComponent;
