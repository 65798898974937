import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, DateAttribute, RcForm, Translations} from '@dreebit/form-attributes-core';
import {DatePicker} from "antd";
import DateRangeWrapper from "../Wrapper/DateRangeWrapper";
import EditableWrapper from "../Wrapper/EditableWrapper";
import {DisplayValueRenderArgs} from "../EditableAttributeContainer";
import * as moment_ from "moment";
import {OnFormItemClick} from "../../types";

const moment = moment_;

const {RangePicker} = DatePicker;

type Props = {
  attribute: DateAttribute<any>,
  form: RcForm,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

type State = {}

class AttributeDateRangePickerComponent extends Component<Props, State> {

  render() {
    const {attribute, style, form, allValues, translations, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      formProps={formProps}
      className={'form-attribute-radio form-attribute-radio-' + attribute.index}
      style={style}
      allValues={allValues}
      translations={translations}
      form={form}
      displayValueRender={({value}: DisplayValueRenderArgs) => {
        if (value && value.length === 2) {
          return value.map((dateValue: any) => {
            return moment(dateValue).format(attribute.format || (attribute.showTime ? 'LLL' : 'LL'))
          }).join(' - ');
        }
        return value ? moment(value).format(attribute.format || (attribute.showTime ? 'LLL' : 'LL')) : null
      }}
      attribute={attribute}
    >
      {(args) => {
        return <DateRangeWrapper
          value={args.value}
          onChange={args.onChange}
          format={attribute.format}>
          {({value, onChange}) => {
            return <RangePicker
              format={attribute.showTime ? 'L HH:mm' : 'L'}
              value={value}
              onChange={onChange}
              id={attribute.index}
              className={`full-width form-item-daterange-${attribute.index}`}
              // @ts-ignore
              size={attribute.size}
              disabled={attribute.disabled}
              renderExtraFooter={attribute.extraFooter}
              showTime={attribute.showTime}/>
          }}
        </DateRangeWrapper>
      }}
    </EditableWrapper>
  }
}

export default AttributeDateRangePickerComponent;
