import * as React from 'react';
import {Component} from 'react';
import {Attribute, AttributeInputType, DataTableAttribute} from '@dreebit/form-attributes-core';
import {Button, Modal, Table} from "antd";
import _ from 'lodash';
import AddRowModal from "./AddRowModal";
import EditRowModal from "./EditRowModal";
import * as moment_ from "moment";
import DataLoaderValueLabel from "../DataLoaderValueLabel";
import {Icon} from "../Icon";
import {OnFormItemClick} from "../../types";

const moment = moment_;

type Value = { key: string }[];

type Props = {
  value?: Value,
  onChange?: Function,
  attribute: DataTableAttribute<any>,
  t: any,
  className?: any,
  id?: any,
  currentLanguage?: string,
  onFormItemClick?: OnFormItemClick
}

type State = {
  selectedRowKeys: string[],
  loading: boolean,
  showAddModal: boolean,
  showEditModal: boolean,
  editValue: object,
  showColumnTitle: boolean
};

class DataTableComponent extends Component<Props, State> {
  state = {
    selectedRowKeys: [],
    loading: false,
    showAddModal: false,
    showEditModal: false,
    showColumnTitle: false,
    editValue: {}
  };

  getRowKey = () => {
    return this.props.attribute.rowKey || 'key'
  };

  showAddModal = () => {
    this.setState({
      showAddModal: true,
    });
  };

  showEditModal = () => {
    this.setState({
      showEditModal: true,
    });
  };

  onSelectChange = (selectedRowKeys: []) => {
    this.setState({selectedRowKeys});
  };

  handleOnChange = (value: any) => {
    const {onChange, attribute} = this.props;

    const newValue = value.map((item: any) => {
      const newItem = _.get(attribute, 'attributes', [])
        .map((item: Attribute<any>) => item.index)
        .reduce((acc: any, key: string) => {
          acc[key] = item[key];
          return acc;
        }, {});

      return newItem;
    });

    if (onChange) onChange(newValue)
  };

  handleDelete = () => {
    const {selectedRowKeys} = this.state;
    const {value} = this.props;

    if (value) {
      let newValue = value.filter((_el: any, index: any) => {
        // @ts-ignore
        return selectedRowKeys.indexOf(index) === -1
      });

      this.setState(
        {
          selectedRowKeys: [],
        },
        () => {
          this.handleOnChange(newValue);
        },
      );
    }
  };

  handleAdd = (values: object) => {

    const {value} = this.props;
    let newValue = [{
      ...values
    }];

    if (value && value.length > 0) {
      newValue = [
        ...value,
        {
          ...values
        }
      ];
    }

    this.handleOnChange(newValue);

    this.setState({
      showAddModal: false,
    });
  };

  handleEdit = (values: object) => {
    const {value} = this.props;
    const {editValue} = this.state;

    const editRow = {
      ...values,
      [this.getRowKey()]: editValue[this.getRowKey()]
    };

    if (value) {
      const newValue = _.map(value, (item: any, index: any) => {
        if (editRow[this.getRowKey()] === index) {
          return {
            ...item,
            ...editRow
          }
        }
        return item;
      });

      this.handleOnChange(newValue);

      this.setState({
        showEditModal: false,
      });
    }
  };

  renderSelectValue = (tda: Attribute<any>, value: any) => {
    if (value && _.isArray(value)) {
      return value.map((val: any) => this.renderSelectValue(tda, val)).join(', ');
    }

    if (tda["options"] && tda["options"].length) {
      return _.chain(tda).get("options").find({value}).get("title").value()
    }

    if (tda["dataLoaderConfig"]) {
      return <DataLoaderValueLabel dataLoaderConfig={tda["dataLoaderConfig"]} value={value}/>
    }

    return value;
  }


  render() {
    let {value, attribute, t, className, id, currentLanguage, onFormItemClick} = this.props;
    const {selectedRowKeys, loading, showAddModal, showEditModal, editValue} = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    let columns = _.compact(_.get(attribute, 'attributes', []).map((el: any, index: any) => {
      const col = {
        title: el.name,
        dataIndex: el.index,
        [this.getRowKey()]: index,
        render: _.toUpper(el.inputType) === AttributeInputType.SELECT ? (text: string) => this.renderSelectValue(el, text)
          : _.toUpper(el.inputType) === AttributeInputType.MULTILANG ? (text: any, record: any) => {
              if (typeof text === 'string') return text;
              return t(text) || _.chain(record).get('multilang').keys().map((key: any) => _.chain(record).get('multilang.' + key).value()).value().join(",") || text
            }
            : null
      };

      if (attribute.columnWhitelist) {
        if (_.includes(attribute.columnWhitelist, el.index)) {
          return col;
        }
        return null;
      }
      return col;
    }));

    let mobileViewBreakpoint = ['xs'];
    let normalViewBreakpoint = ['sm'];

    columns = _.map(columns, (el: any) => {
      return {
        ...el,
        responsive: normalViewBreakpoint
      }
    });

    columns = _.concat(columns, {
      title: '',
      render: (_text: never, record: any) => {
        return <div className={'flex-col'}>
          {
            Object.keys(record)
              .map((key: string, index: any) => {
                const content = _.get(record, key);
                const column = _.find(columns, {dataIndex: key});
                const title = _.get(column, 'title') || _.get(column, 'name');

                if (content) {
                  return <div className={'flex-col'} key={index}
                              style={{hyphens: 'auto', gap: 4, padding: '0px 0px 15px 0px'}}>
                    <span style={{textDecoration: 'underline'}}>{title}</span>
                    <span>{content}</span>
                  </div>;
                }

                return null;
              })
          }
        </div>
      },
      responsive: mobileViewBreakpoint
    });

    if (!attribute.disabled) {
      columns = _.concat(columns, {
        title: null,
        dataIndex: 'edit',
        width: 60,
        //fixed: 'right',
        [this.getRowKey()]: 'edit',
        render: (_text: never, record: any) => (
          <a onClick={() => this.setState({editValue: record, showEditModal: true})}
             data-testid={"row-edit-button"}>
            <Icon type={"EditFilled"}/>
          </a>
        )
      });
    }

    let data = Array.isArray(value) ? [...value] : [];

    data = data.map((item: any, index: any) => {
      const el = {...item};
      if (typeof el === "object" && Object.keys(el).length > 0) {
        Object.keys(el).map(function (key) {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
          if (moment.isMoment(el[key])) {
            el[key] = moment(el[key]).toString();
          }
          if (typeof el[key] == 'undefined') {
            el[key] = '';
          }
          // if the value is multilang, the value looks like this:
          // {
          //     "de": "asd",
          //     "en": "asdasdad"
          // }
          if (typeof el[key] == "object" && attribute.inputType === "MULTILANG") {
            const firstObjKey = Object.keys(el[key])[0]
            el[key] = el[key][currentLanguage || "en"] || el[key][firstObjKey] || "";
          }
        });
      }
      el[this.getRowKey()] = index;
      return el;
    });

    return <div style={{display: 'flex', flexDirection: 'column'}} className={className} id={id}>
      <div style={{display: 'flex', padding: '0px 0px 5px 0px', justifyContent: 'space-between', width: '100%'}}>
        <div>
          {
            attribute.disabled
              ? null
              : hasSelected
                ? <Button
                  data-testid={"data-table-delete-button"}
                  onClick={this.handleDelete}
                  loading={loading}
                >
                  {t('Delete')}
                </Button>
                : null

          }
        </div>
        {
          attribute.disabled
            ? null
            : <div>
              <Button
                data-testid={"data-table-new-button"}
                onClick={this.showAddModal}
                type="primary"
              >
                {t('New')}
              </Button>
            </div>
        }
      </div>
      <Table
        // @ts-ignore
        rowSelection={attribute.disabled ? null : rowSelection}
        data-testid={"data-table"}
        dataSource={data}
        bordered={attribute.bordered}
        columns={columns}
        loading={loading}
        rowKey={this.getRowKey()}
        // @ts-ignore
        size={attribute.size}
        pagination={attribute.pagination ? attribute.pagination : false}
        scroll={attribute.scroll || {
          x: true
        }}
        onRow={attribute.onRow}/>
      {
        showAddModal
          ? <AddRowModal
            visible={showAddModal}
            columns={_.get(attribute, 'attributes', [])}
            onCreate={({values}: any) => this.handleAdd(values)}
            onCancel={() => {
              this.setState({showAddModal: false});
            }}
            onFormItemClick={onFormItemClick}
            t={t}
          />
          : null
      }
      {
        showEditModal
          ? <EditRowModal
            columns={_.get(attribute, 'attributes', [])}
            editValue={editValue}
            visible={showEditModal}
            onSave={({values}: any) => this.handleEdit(values)}
            onCancel={() => {
              this.setState({showEditModal: false});
            }}
            onFormItemClick={onFormItemClick}
            t={t}
          />
          : null
      }
      {
        this.state.showColumnTitle
          ? <Modal
            title={t('Connected columns')}
            visible={this.state.showColumnTitle}
            footer={null}
            onCancel={() => this.setState({showColumnTitle: false})}
          >
            {
              columns.map((col: any, index: number) => {
                if ((!col.responsive || !_.get(col, 'responsive', []).includes('xs')) && (col.title || col.name)) return <p
                  key={index} style={{hyphens: 'auto'}}>{col.title || col.name}</p>
                return null;
              })
            }
          </Modal>
          : null
      }
    </div>
  }
}

export default DataTableComponent;
