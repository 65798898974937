// @ts-nocheck
import * as React from 'react';
import {Component} from 'react';
import * as _ from "lodash";
import {
  AttributeFormStyles,
  FormAttributeRenderContext,
  OptionAttribute,
  RcForm,
  Translations
} from '@dreebit/form-attributes-core';
import DataLoaderWrapper from "../../Wrapper/DataLoaderWrapper";
import EditableWrapper from "../../Wrapper/EditableWrapper";
import ValueDataLoaderWrapper, {ValueDataLoaderWrapperRenderArguments} from "../../Wrapper/ValueDataLoaderWrapper";
import '../index.css';
import SelectComponent from "./SelectComponent";
import {OnFormItemClick} from "../../../types";


type Props = {
  mode?: string,
  attribute: OptionAttribute<any>,
  form: RcForm,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any
  renderExtra?: any
  open?: boolean,
  onDropdownVisibleChange?: any,
  onMouseEnter?: any,
  onFocus?: any,
  onFormItemClick?: OnFormItemClick
}

class AttributeSelectComponent extends Component<Props> {

  render() {
    const {
      attribute,
      style,
      form,
      allValues,
      translations,
      formProps,
      onDropdownVisibleChange,
      onFocus,
      onMouseEnter,
      open,
      renderExtra,
      onFormItemClick
    } = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      style={style}
      allValues={allValues}
      formProps={formProps}
      form={form}
      translations={translations}
      attribute={attribute}>
      {(editableArgs) => {
        const {value, onClick} = editableArgs;


        return <DataLoaderWrapper
          allValues={allValues}
          form={form}
          value={_.get(value, 'key', value)}
          onChange={editableArgs.onChange}
          options={attribute.options}
          dataLoaderConfig={attribute.dataLoaderConfig}>

          {({options, loading, error, onChange, requestMore, onSearch}) => {

            return <ValueDataLoaderWrapper
              dataLoaderConfig={attribute.dataLoaderConfig}
              options={attribute.options}
              allValues={allValues}
              value={value}>
              {(args: ValueDataLoaderWrapperRenderArguments) => {

                return <>
                  {
                    renderExtra && renderExtra({
                      attribute,
                      options: args.options,
                      loading: args.loading,
                      value,
                      allValues,
                      onChange
                    })
                  }
                  <SelectComponent
                    attribute={attribute}
                    style={style}
                    form={form}
                    allValues={allValues}
                    value={value}
                    translations={translations}
                    formProps={formProps}
                    options={options}
                    loading={loading}
                    error={error}
                    onChange={onChange}
                    requestMore={requestMore}
                    onSearch={onSearch}
                    args={args}
                    context={this.context}
                    open={open}
                    onDropdownVisibleChange={onDropdownVisibleChange}
                    onClick={(e) => onClick && onClick(e, attribute.index)}
                    onMouseEnter={onMouseEnter}
                    onFocus={onFocus}
                  />
                </>
              }}
            </ValueDataLoaderWrapper>
          }}
        </DataLoaderWrapper>
      }}
    </EditableWrapper>
  }
}

AttributeSelectComponent.contextType = FormAttributeRenderContext;

export default AttributeSelectComponent;
