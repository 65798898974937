import * as React from 'react';
import {useContext, useRef} from 'react';
import {
  AttributeFormStyles,
  FormAttributeRenderContext,
  NumberAttribute,
  Translations
} from '@dreebit/form-attributes-core';
import EditableWrapper from "../../Wrapper/EditableWrapper";
import {InputNumber} from "antd";
import createAddon from "../../../utils/AttributeAddonHelper";
import _ from "lodash";
import {DisplayValueRenderArgs} from "../../EditableAttributeContainer";
import {OnFormItemClick} from "../../../types";

type Props = {
  attribute: NumberAttribute<any>,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

export const isFloat = (value?: any) => {
  if (value) {
    if (typeof value === 'string') value = Number(value);

    return value % 1 !== 0;
  }
  return true;
}

const AttributeCurrencyComponent = ({
                                      attribute,
                                      style,
                                      form,
                                      translations,
                                      allValues,
                                      formProps,
                                      onFormItemClick
                                    }: Props) => {

  const context: any = useContext(FormAttributeRenderContext);
  const numberRef: any = useRef();

  return <EditableWrapper
    onFormItemClick={onFormItemClick}
    loading={attribute.loading}
    className={'form-attribute-text-' + attribute.index}
    style={{
      ...style,
      editable: true
    }}
    form={form}
    translations={translations}
    allValues={allValues}
    formProps={formProps}
    displayValueRender={(args: DisplayValueRenderArgs) => {
      let t = _.get(translations, 't');
      if (!t) t = (text: string) => _.get(translations, text, text);

      if (args.value) return Number(args.value).toFixed(2)
        .replace('.', t('decimalSeparator'))
        .replace(/\B(?=(\d{3})+(?!\d))/g, t('thousandSeparator'));

      return null;
    }}
    attribute={attribute}>

    {({value, onChange, onKeyPress, t}: any) => {
      if (!t) t = (text: string) => _.get(translations, text, text);

      let addOnAfter: any = createAddon(attribute, form, _.get(context, 'renderFactory'), style, translations);
      let decimalSeparator = t('decimalSeparator');
      let thousandSeparator = t('thousandSeparator');

      return <InputNumber
        ref={numberRef}
        value={value}
        onKeyPress={onKeyPress}
        // @ts-ignore
        addonAfter={addOnAfter}
        disabled={attribute.disabled}
        placeholder={attribute.placeholder}
        formatter={value => {
          return value
            .replace(".", decimalSeparator) //replace Decimal Separator
            .replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator) //add thousand Separator
        }}
        parser={(value: any) => {
          return value
            .replace(new RegExp(`\\${thousandSeparator}*`, 'g'), '') //replace thousand Seperators
            .replace(decimalSeparator, '.'); //replace Decimal Separator
        }}
        onChange={(num: any) => {
          onChange(num)
        }}
      />
    }}

  </EditableWrapper>
}

export default AttributeCurrencyComponent;
