// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import Toolbar from "../Toolbar";
import MutationToolbarButton from "../MutationToolbarButton";
import * as _ from "lodash";
import {Button} from "antd";

type Props = {
    toolbarConfig?: {
        renderLeft?: {
            content: any,
            useSelection?: boolean,
            access?: boolean,
            onClick?: Function,
            mutation?: {
                mutation: any,
                mutationOptions?: any,
                translateMutationOptions: any,
                successToast?: string
            }
        }[],
        renderRight?: {
            content: any,
            useSelection?: boolean,
            access?: boolean,
            onClick?: Function,
            mutation?: {
                mutation: any,
                mutationOptions?: any,
                translateMutationOptions: any,
                successToast?: string
            }
        }[]
    },
    selectedRowIds?: any,
    setSelectedRowIds?: Function
}

const QueryMasterTableToolbar = (props: Props) => {

    const {
        toolbarConfig,
        selectedRowIds,
        setSelectedRowIds
    } = props;
    const {t} = useTranslation();
    let key = 0;

    const ToolbarButton = (config: any) => {
        key++;
        if(!_.isNil(config.access) && !config.access) return null;

        if(config.mutation){
            return <MutationToolbarButton onClick={config.onClick}
                                          mutation={config.mutation}
                                          selectedRowIds={selectedRowIds}
                                          onSuccess={()=> setSelectedRowIds ? setSelectedRowIds([]) : null}
                                          content={config.content}
                                          key={key}
            />
        }
        return <Button
            onClick={() => {
                if(!_.isNil(config.onClick)){
                    config.onClick(selectedRowIds);
                }
            }}
            key={key}
        >
            {config.content}
        </Button>
    };

    return <Toolbar
        renderLeft={() => {
            // @ts-ignore
            if(toolbarConfig.renderLeft){
                return <div>
                    {
                        // @ts-ignore
                        toolbarConfig.renderLeft.map((config: any) => {
                            if(config.useSelection){
                                if(selectedRowIds && selectedRowIds.length && selectedRowIds.length > 0){
                                    return ToolbarButton(config);
                                }
                                else {
                                    return null;
                                }
                            }
                            else {
                                return ToolbarButton(config);
                            }
                        })
                    }
                </div>;
            }
        }}
        renderRight={() => {
            // @ts-ignore
            if(toolbarConfig.renderRight){
                return <div>
                    {
                        // @ts-ignore
                        toolbarConfig.renderRight.map((config: any) => {
                            if(config.useSelection){
                                if(selectedRowIds && selectedRowIds.length && selectedRowIds.length > 0){
                                    return ToolbarButton(config);
                                }
                                else {
                                    return null;
                                }
                            }
                            else {
                                return ToolbarButton(config);
                            }
                        })
                    }
                </div>;
            }
        }}
    />
};

export default QueryMasterTableToolbar
