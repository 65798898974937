import _ from "lodash";

export default function translate(input: string | object, t: Function, language?: string): string {
  if (!input) return '';

  if (input && typeof input === "string") {
    return t(input);
  }

  if (!language) language = "en";

  return _.get(input, language, _.first(Object.values(input)))
}
