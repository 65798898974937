import _ from "lodash";

export const updateCache = (prev: any, next: any, itemsKeyPath: string, totalKeyPath: string, uniqueBy: string = "id") => {

    const {fetchMoreResult} = next;
    if (!fetchMoreResult) return prev;

    const assign = _.cloneDeep(prev);
    let newList = _.get(fetchMoreResult, itemsKeyPath);
    const prevItems = _.get(prev, itemsKeyPath);
    if (prevItems) {
        newList = [...prevItems, ...newList];
    }

    _.set(assign, itemsKeyPath, _.uniqBy(newList, uniqueBy));
    _.set(assign, totalKeyPath, _.get(fetchMoreResult, totalKeyPath));

    if (_.get(assign, itemsKeyPath, []).length !== (newList.length)) {
        console.warn('Length of merged list not as expected', assign, newList);
    }

    return assign;
};

