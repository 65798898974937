// @ts-nocheck
import React, {useState} from "react";
import {Input, Select, Spin, Tooltip} from "antd";
import _ from "lodash";
import createAddon from "../../../utils/AttributeAddonHelper";
import {ValueDataLoaderWrapperRenderArguments} from "../../Wrapper/ValueDataLoaderWrapper";
import {AttributeInputType, OptionAttribute, OptionValue} from "@dreebit/form-attributes-core";
import {CloseCircleOutlined, DownOutlined, ExclamationOutlined} from "@ant-design/icons";

interface Props {
  attribute: OptionAttribute<any>,
  options: any[],
  form: any,
  mode?: string,
  value?: any,
  style?: any,
  translations?: any,
  allValues?: any,
  formProps?: any
  loading?: boolean,
  error?: any,
  onChange: (changes: any, search?: boolean) => any,
  requestMore: Function,
  onSearch: (text: string) => any,
  args: ValueDataLoaderWrapperRenderArguments,
  context?: any
  open?: boolean,
  onDropdownVisibleChange?: any,
  onMouseEnter?: any,
  onFocus?: any,
  onClick?: (_e: any) => void,
}

const scrollTriggerArea = 50;

const SelectComponent = (props: Props) => {
  let {
    error,
    open,
    onDropdownVisibleChange,
    onMouseEnter,
    onFocus,
    onChange,
    requestMore,
    onSearch,
    args,
    options,
    mode,
    attribute,
    form,
    value,
    translations,
    context,
    style,
    onClick
  } = props;

  const [searchText, setSearchText] = useState(null);
  const [loading, setLoading] = useState(false);

  const showSearch = attribute.searchable || attribute.inputType.toUpperCase() === AttributeInputType.AUTOCOMPLETE;
  const valueLoading = args.loading;

  /**
   *  Helper Functions
   */
  const handleSelectScroll = (e: any, loadMore: Function) => {
    const target = e.currentTarget;

    const ul = target.querySelector('ul');
    if (ul) {
      const scrollPosition = ul.scrollTop / (ul.scrollHeight - ul.clientHeight - scrollTriggerArea);
      if (scrollPosition >= 1) {
        loadMore();
      }
    }
  }

  const createPickerItem = (title: string, itemValue: any, raw: any) => {
    const renderOptions = _.get(attribute, 'inputProps.renderOption', _.get(attribute, 'renderOptions', null));
    return <Select.Option key={itemValue} value={itemValue}
                          title={title}>{!_.isNil(renderOptions) && typeof renderOptions === 'function' ? renderOptions(title, itemValue, raw) : title}</Select.Option>
  }

  /**
   * Item Handling
   */
  let items = Array.isArray(options) ? _.compact(options
    .filter((optionValue: OptionValue<any>) => {
      if (showSearch && !onSearch && searchText) {
        return optionValue.title.toLowerCase().includes(searchText.toLowerCase());
      }

      return true;
    })
    .map((optionValue: OptionValue<any>) => {
      if (attribute.valueBlacklist && _.indexOf(attribute.valueBlacklist, optionValue.value) > -1) return null;

      return createPickerItem(optionValue.title, optionValue.value, optionValue.raw)
    })) : []

  // create new item, if custom Entry is allowed or Autocomplete is on
  if (attribute.allowNoOption || attribute.inputType.toUpperCase() === AttributeInputType.AUTOCOMPLETE) {

    if (!_.get(attribute, 'disableValueOption', false) && searchText && items.indexOf({key: searchText}) < 0) {
      items = [
        createPickerItem(searchText, searchText, searchText),
        ...items
      ]
    }

    if (attribute.value) {

      if (typeof attribute.value === 'string' && items.indexOf({key: attribute.value}) < 0) {
        items = [
          createPickerItem(attribute.value, attribute.value, attribute.value),
          ...items
        ]
      }

      if (_.isArray(attribute.value)) {
        items = [
          ..._.compact(attribute.value.map((el: any) => {
            if (_.findIndex(items, {key: el}) < 0) return createPickerItem(el, el, el);
            return null;
          })),
          ...items
        ]
      }
    }
  }

  const AttributeSelect = <Select
    onClick={onClick}
    autoComplete={'new-password'}
    open={open}
    onDropdownVisibleChange={onDropdownVisibleChange}
    onMouseEnter={onMouseEnter}
    onFocus={onFocus}
    showArrow={_.get(attribute, 'showArrow', true)}
    className={`form-item-select-${attribute.index} flex-grow-1`}
    defaultOpen={_.get(attribute, 'inputTypeOptions.defaultOpen', false)}
    labelInValue
    optionLabelProp="title"
    id={attribute.index}
    dropdownMatchSelectWidth={false}
    allowClear={!_.find(attribute.rules, {required: true})}
    suffixIcon={
      error
        ? <Tooltip title={<span>{error.toString()}</span>}>
          <ExclamationOutlined style={{color: 'red'}}/>
        </Tooltip>
        : value && !_.find(attribute.rules, {required: true})
          ? <CloseCircleOutlined/>
          : _.get(attribute, 'showArrow', true)
            ? <DownOutlined/>
            : null
    }
    value={value}
    notFoundContent={valueLoading || props.loading || loading ? <Spin size="small"/> : null}
    loading={valueLoading || props.loading || loading}
    maxTagCount={attribute.maxTagCount}
    maxTagPlaceholder={attribute.maxTagCount === 0 ? (vals: any) =>
      <span>{attribute.name || attribute.placeholder} ({vals.length}) </span> : undefined}
    // @ts-ignore
    mode={mode ? mode : attribute.multiple ? attribute.tokenSeparators ? 'tags' : 'multiple' : 'default'}
    disabled={attribute.disabled}
    placeholder={attribute.placeholder}
    tokenSeparators={attribute.tokenSeparators}
    onChange={(changes: any) => {
      setSearchText(null);
      if (!onChange) return;

      if (Array.isArray(changes)) {
        onChange(changes.map((v) => v && v.key ? v.key : null).filter(v => !!v), showSearch);
      } else if (changes && changes.key) {
        onChange(changes.key, showSearch);
      } else {
        onChange(null, showSearch)
      }
    }}
    onClear={() => {
      setSearchText(null);
      if (!onChange) return;

      onChange(null, showSearch);
    }}
    showSearch={showSearch}
    onSearch={showSearch && !attribute.localSearch
      ? _.get(attribute, 'searchDebounce')
        ? _.debounce((q: string) => {
          setSearchText(q);
          if (onSearch) onSearch(q);
        }, _.get(attribute, 'searchDebounce'))
        : (q: string) => {
          setSearchText(q);
          if (onSearch) onSearch(q);
        }
      : null
    }
    filterOption={!onSearch || attribute.localSearch ? (input: any, option: any) => {
        try {
          return _.get(option, 'props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0
        } catch {
          return false
        }
      }
      : false
    }
    onPopupScroll={(e: any) => handleSelectScroll(e, () => {
      if (requestMore) {
        const promise = requestMore();
        if (promise) {
          setLoading(true);

          promise.finally(() => {
            setLoading(false);
          })
        }
      }
    })}
  >
    {items}
  </Select>


  /**
   * Render Select with or without Addon
   */

  let addOnAfter: any = createAddon(attribute, form, context.renderFactory, style, translations);

  if (addOnAfter) return <Input.Group compact style={{display: 'flex'}}>
    {AttributeSelect}
    {addOnAfter}
  </Input.Group>

  return AttributeSelect;
}

export default SelectComponent;
