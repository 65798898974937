import React, {Component} from 'react';
import _ from "lodash";
import './index.css';
import {
    mdiAccount,
    mdiAccountBoxMultiple,
    mdiAccountOutline,
    mdiBell,
    mdiBox,
    mdiCalendarToday,
    mdiCashUsdOutline,
    mdiChartBox,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarked,
    mdiCodeTags,
    mdiCog,
    mdiCogOutline,
    mdiCogs,
    mdiCube,
    mdiDelete,
    mdiDomain,
    mdiDownload,
    mdiEarth,
    mdiFaceAgent,
    mdiFile,
    mdiFileExcel,
    mdiFileImage,
    mdiFilePdf,
    mdiFileVideo,
    mdiFileWord,
    mdiFinance,
    mdiFlashAuto,
    mdiFolderAccountOutline,
    mdiForklift,
    mdiHeadQuestionOutline,
    mdiLink,
    mdiLinkOff,
    mdiLock,
    mdiMagnify,
    mdiMenu,
    mdiMenuDown,
    mdiMessageProcessing,
    mdiNewspaper,
    mdiPackageVariantClosed,
    mdiPaperclip,
    mdiPlaylistEdit,
    mdiShareVariant,
    mdiSpeedometer,
    mdiStore,
    mdiTag,
    mdiTruck,
    mdiWrench,
    mdiZipBox,
    mdiOpenInNew,
    mdiViewAgendaOutline,
    mdiTable,
    mdiViewDashboard,
    mdiEqual,
    mdiGreaterThan,
    mdiGreaterThanOrEqual,
    mdiLessThan, mdiLessThanOrEqual, mdiNotEqualVariant, mdiHelp
} from "@mdi/js";
import Icon from '@mdi/react';

export const MDIMap: any = {
    'open-in-new' :mdiOpenInNew,
    "forklift": mdiForklift,
    "speedometer": mdiSpeedometer,
    "magnify": mdiMagnify,
    "checkbox-marked": mdiCheckboxMarked,
    "checkbox-blank-outline": mdiCheckboxBlankOutline,
    "download": mdiDownload,
    "delete": mdiDelete,
    "tag": mdiTag,
    "share-variant": mdiShareVariant,
    "lock": mdiLock,
    "file": mdiFile,
    "file-image": mdiFileImage,
    "file-video": mdiFileVideo,
    "file-pdf": mdiFilePdf,
    "file-excel": mdiFileExcel,
    "file-word": mdiFileWord,
    "code-tags": mdiCodeTags,
    "zip-box": mdiZipBox,
    "bell": mdiBell,
    "paperclip": mdiPaperclip,
    "account": mdiAccount,
    "settings-outline": mdiCogOutline,
    "face-agent": mdiFaceAgent,
    "earth": mdiEarth,
    "domain": mdiDomain,
    "newspaper": mdiNewspaper,
    "truck": mdiTruck,
    "cash-usd-outline": mdiCashUsdOutline,
    "folder-account-outline": mdiFolderAccountOutline,
    "playlist-edit": mdiPlaylistEdit,
    "flash-auto": mdiFlashAuto,
    "cubes": mdiCogs,
    "user": mdiAccount,
    "users": mdiAccountBoxMultiple,
    "box": mdiBox,
    "package": mdiPackageVariantClosed,
    "cube": mdiCube,
    "wrench": mdiWrench,
    "account-outline": mdiAccountOutline,
    "caret-down": mdiMenuDown,
    "link": mdiLink,
    "linkOff": mdiLinkOff,
    "unlink": mdiLinkOff,
    "menu": mdiMenu,
    "store": mdiStore,
    "building": mdiStore,
    "calendarToday": mdiCalendarToday,
    "cog": mdiCog,
    "message-processing": mdiMessageProcessing,
    "finance": mdiFinance,
    "chart-box": mdiChartBox,
    "view-agenda-outline": mdiViewAgendaOutline,
    "table": mdiTable,
    "view-dashboard": mdiViewDashboard,
    "equal": mdiEqual,
    "greater-than": mdiGreaterThan,
    "greater-than-or-equal": mdiGreaterThanOrEqual,
    "less-than": mdiLessThan,
    "less-than-or-equal": mdiLessThanOrEqual,
    "not-equal-variant": mdiNotEqualVariant
}

type Props = {
    name: string,
    circled?: boolean,
    size?: number | string,
    color?: string,
    style?: any,
    className?: string,
    onClick?: any
};

export const MaterialCommunityIcon = ({name, size, color, style, className, circled, onClick}: Props) => {
    const icons = require(`@mdi/js`);

    let path: any = MDIMap[name];
    if (!path) {
        path = icons[name];

        if(!path) {
            console.warn('MDI Icon not fount', name);
            path = mdiHelp;
        }
    }

    let numberSize = size || "1.2em";
    if (!_.isString(numberSize)) {
        numberSize = `${numberSize}px`
    }

    return <i className={`flex mdi-icon ${circled ? 'circled' : ''} ${className ? className : ''}`}
              onClick={onClick}
              style={{
                  ...style,
                  ...circled ? {backgroundColor: color ? color : 'auto'} : {}
              }}
    >
        <Icon path={path} size={numberSize} color={circled ? 'white' : color}/>
    </i>;
}
