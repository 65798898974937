// @flow
import * as React from "react"
import {ReactNode} from "react"
import * as moment_ from "moment";
import _ from 'lodash';

const moment = moment_;

type RenderArguments = {
  value?: any,
  onChange?: any,
}


type Props = {
  value?: any,
  format?: string,
  onChange?: Function,
  children: (args: RenderArguments) => ReactNode
};

type State = {}

class DateRangeWrapper extends React.Component<Props, State> {

  render() {
    const {children, value, onChange} = this.props;

    let dates = value;
    if (typeof value === 'string') {
      const valueSplit = value.split(',');

      if (valueSplit && valueSplit.length === 2) {
        dates = valueSplit;
      }
    }

    let result = new Array(2)
      .fill(undefined)
      .map((_un, index) => {
        let el = _.get(dates, index);
        if (typeof el === 'string') {
          if (el !== '') {
            el = moment(el);
          } else {
            el = null;
          }
        }
        return el;
      });


    return children({
      value: result,
      onChange
    });
  }
}

export default DateRangeWrapper
