// @ts-nocheck
import * as React from "react";
import {Component, ReactNode} from "react";
import {Attribute, DataLoaderConfig, OptionValue} from '@dreebit/form-attributes-core';
import {gql} from "@apollo/client";
import _ from 'lodash';
import {updateCache} from "../../utils/loadMoreApolloCache";
import {Query, QueryResult} from "@apollo/client/react/components";

type RenderArguments = {
  options: OptionValue<any>[],
  loading?: boolean,
  error?: any,
  value?: any,
  onChange?: Function,
  onSearch?: (query: string) => void | Promise<any>,
  requestMore?: () => void | Promise<any>
}

type Props = {
  form?: any,
  attribute?: Attribute<any>,
  options?: OptionValue<any>[],
  children: (renderArguments: RenderArguments) => ReactNode,
  dataLoaderConfig?: DataLoaderConfig,
  value?: any,
  allValues?: any,
  onChange?: Function,
  pagingSize?: number,
  uniqueIdentifier?: string,
  debounceTime?: number,
  searchString?: string
};

type State = {}

class DataLoaderWrapper extends Component<Props, State> {

  static defaultProps = {
    pagingSize: 20,
    debounceTime: 500,
    uniqueIdentifier: 'id'
  }

  state = {loading: false};

  _handleRefetch = (refetch: Function, options: any) => {
    return refetch(options)
  }

  debounceSearch = _.debounce(this._handleRefetch, this.props.debounceTime);

  _handleWaypointEnter = (fetchMore: Function, data: any, exisitingVariables?: any): Promise<any> => {
    const dataLoaderConfig = this.props.dataLoaderConfig;

    if (!dataLoaderConfig || this.state.loading) {
      return Promise.resolve();
    }
    const totalKeyPath = _.get(dataLoaderConfig, "dataQueryConfig.totalKeyPath");
    const itemsKeyPath = _.get(dataLoaderConfig, "dataQueryConfig.dataKeyPath");
    const total = _.get(data, totalKeyPath);
    const length = _.get(data, itemsKeyPath, []).length;

    if (length === total) return Promise.resolve();
    if (length < total && total > 0) {
      const variables = this._getVariablesForPage(length, this.props.pagingSize || DataLoaderWrapper.defaultProps.pagingSize, exisitingVariables);

      this.setState({
        loading: true
      })

      return fetchMore({
        variables,
        // @ts-ignore
        updateQuery: (prev: any, next: any) => {
          const result = updateCache(data, next, itemsKeyPath, totalKeyPath, this.props.uniqueIdentifier);
          return result;
        }
      }).finally(() => {
        this.setState({
          loading: false
        })
      });
    }
    return Promise.resolve();
  };

  _getVariablesForPage = (start: number, limit: number, existingVariables?: any) => {
    const variables = {
      ...existingVariables,
    }
    const dataLoaderConfig = this.props.dataLoaderConfig;
    const limitKeyPath = _.get(dataLoaderConfig, "dataQueryConfig.limitKeyPath", "limit");
    const startKeyPath = _.get(dataLoaderConfig, "dataQueryConfig.startKeyPath", "start");
    _.set(variables, limitKeyPath, limit);
    _.set(variables, startKeyPath, start);
    return variables;
  };

  getVariables(query?: string) {

    const {dataLoaderConfig, value, attribute, form, allValues} = this.props;

    const config: any = _.get(dataLoaderConfig, 'dataQueryConfig');

    // Replace variables from form data
    let variables = {
      ..._.get(config, 'variables')
    };
    if (form && variables) {
      const formValues = form.getFieldsValue();
      const injectableParams = {
        ...variables,
      };
      [Object.keys(injectableParams)].forEach((key) => {
        const val = injectableParams[key];
        if (val && val.match) {
          const regex = /\${(.*)}/;
          let match = null;
          if (val && val.match) match = val.match(regex);
          if (match && match.length > 1) {
            const index = match[1];
            if (index === 'query') {
              variables[key] = query
            } else {
              variables[key] = formValues[index];
            }
          }
        }

      })
    }

    if (config.getQueryOptions) {
      variables = _.get(config.getQueryOptions({
        query,
        value,
        ...value,
        ...attribute,
        ...allValues
      }), 'variables', {
        query,
        value,
        ...value,
        ...attribute,
        ...allValues
      });
    }

    if (config && config.variables) {
      const values = {
        value,
        ...value,
        ...allValues
      }

      Object.keys(config.variables).forEach((variableKey: string) => {
        if (!_.get(variables, variableKey)) _.set(variables, variableKey, _.get(values, _.get(config, `variables.${variableKey}`)))
      })
    }

    return variables;
  }

  render() {
    const {options, children, dataLoaderConfig, value, onChange, searchString} = this.props;

    let tmpOptions = options || [];

    if (dataLoaderConfig) {
      const queryString = _.get(dataLoaderConfig, 'dataQueryConfig.query');
      if (!queryString) return `Error! No query text available!`;

      const query = typeof queryString === 'string' ? gql(queryString) : queryString;

      return <Query
        query={query}
        variables={this.getVariables(searchString)}>
        {(queryResult: QueryResult) => {

          const {loading, error, data, fetchMore, refetch, variables} = queryResult;
          const requestMore = () => this._handleWaypointEnter(fetchMore, data, variables);
          const onSearch = (query: string) => {
            this.debounceSearch(refetch, this.getVariables(query))
          }

          const resetQuery = () => {
            if (refetch) {
              this._handleRefetch(refetch, this.getVariables());
            }
          }

          const onChangeSearch = (changes: any, search?: boolean) => {
            if (search && refetch) {
              this._handleRefetch(refetch, this.getVariables());
            }
            if (onChange) onChange(changes);
          }

          if (error) {
            return children({
              value,
              loading,
              error,
              onChange: onChangeSearch,
              requestMore,
              onSearch,
              resetQuery,
              options: tmpOptions
            })
          }

          const result = _.get(data, dataLoaderConfig.dataQueryConfig.dataKeyPath, []).map((el: any, i: number) => {
            return {
              key: _.get(el, dataLoaderConfig.dataQueryConfig.valueKeyPath) || `${i}`,
              title: _.get(el, dataLoaderConfig.dataQueryConfig.titleKeyPath),
              value: _.get(el, dataLoaderConfig.dataQueryConfig.valueKeyPath),
              raw: el
            }
          })

          let optionValues = []
          if (Array.isArray(tmpOptions) && Array.isArray(result)) {
            optionValues = [
              ...tmpOptions,
              ...result
            ]
          } else {
            optionValues = result || tmpOptions || [];
          }

          return children({
            options: _.uniqBy(optionValues, 'value'),
            onChange: onChangeSearch,
            loading: loading || this.state.loading,
            requestMore,
            onSearch,
            value
          })
        }}
      </Query>
    }

    return children({options: tmpOptions, value, onChange, loading: this.state.loading})
  }
}

export default DataLoaderWrapper;
