import React from "react";
import _ from "lodash";
import {Button, Input, Space} from "antd";
import {SearchOutlined} from '@ant-design/icons';

type Props = {
    columns: any[],
    cacheFilter?: any,
    searchInput?: any,
    t: any,
    handleSearch: Function,
    handleReset?: any,
    filterConfig?: any,
    setCacheFilter?: Function,
    sortTableDirectionMap?: any,
    cacheSorter?: any,
    filterDropdownVisible?: boolean,
    onFilterDropdownVisibleChange?: any
}

export const getColumns = (props: Props) => {
    const {cacheFilter, cacheSorter, columns, filterConfig, handleSearch, handleReset, filterDropdownVisible, onFilterDropdownVisibleChange, searchInput, setCacheFilter, sortTableDirectionMap, t} = props;

    return columns.map((col: any) => {
        if(col.useSearch){
            const dataIndex = col.dataIndex;
            const defaultValue = _.get(cacheFilter, _.get(col, 'dataIndex', 'search'));

            col.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`${t("Search")} ${dataIndex}`}
                    value={selectedKeys[0] || defaultValue}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, dataIndex, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, dataIndex, confirm)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        {t("Reset")}
                    </Button>
                </Space>
            </div>;

            col.filterIcon = (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />;
            col.onFilter = (value: any, record: any) =>
                record[dataIndex]
                    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                    : '';
            if(!_.isNil(filterDropdownVisible)) col.filterDropdownVisible = filterDropdownVisible;
            col.onFilterDropdownVisibleChange = (visible: any) => {
                if(onFilterDropdownVisibleChange) onFilterDropdownVisibleChange(visible);

                if (visible) {
                    // @ts-ignore
                    setTimeout(() => searchInput.current.select(), 100);
                }
            }
        }

        if(col.useFilter){
            col.filteredValue = _.get(cacheFilter, _.get(col, 'filteredValueFilterKey', col.dataIndex));

            if(_.get(col, 'filters.query') && !col.filters){
                col.filters = _.get(_.get(col, 'filters.query'),_.get(col, 'filters.queryPath'),[]).map((item: any) => ({
                    text: t(item.name),
                    value: item.id,
                }));
            }
        }

        if(col.useSort){
            if(_.isNil(col.sorter)) col.sorter = true;
            if(col.sorter && typeof col.sorter === 'boolean'){
                col.sortOrder = _.get(sortTableDirectionMap,
                    _.chain(cacheSorter)
                        .find({key: col.dataIndex})
                        // @ts-ignore
                        .get('direction')
                        .value()
                    , false);
            }
        }

        return col;
    });
};
