import * as React from 'react';
import {Component} from 'react';
import {Input} from "antd";
import {AttributeFormStyles, TextAttribute, Translations} from '@dreebit/form-attributes-core';
import EditableWrapper from "../Wrapper/EditableWrapper";
import {OnFormItemClick} from "../../types";


type Props = {
  attribute: TextAttribute<any>,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

class AttributePasswordComponent extends Component<Props> {


  render() {
    const {attribute, style, form, allValues, translations, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      allValues={allValues}
      translations={translations}
      form={form}
      formProps={formProps}
      attribute={attribute}>

      {({value, onChange, onClick}) => {

        return <Input.Password
          value={value}
          id={attribute.index}
          onChange={onChange}
          className={`form-item-passwort-${attribute.index}`}
          addonAfter={attribute.addOn}
          disabled={attribute.disabled}
          placeholder={attribute.placeholder}
          onClick={(event) => onClick && onClick(event, attribute.index)}/>
      }}

    </EditableWrapper>
  }
}

export default AttributePasswordComponent;
