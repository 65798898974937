import _ from "lodash";

export type NumberParts = {
  sign: boolean,
  mantissa: number,
  exponent: number,
  base: number,
};

export default class NumberExtractor {
  static getNumberParts = (x: number): NumberParts | void => {
    // let exp = 0;
    // let sign = 0;
    // if (x === 0) return { sign: 0, mantissa: 0, exponent: 0, base };
    // if (x < 0) (sign = 1), (x = -x);
    // while (x > base) (x /= base), exp++;
    // while (x < 1) (x *= base), exp--;
    // return { sign, mantissa: x, exponent: exp, base };

    if (!_.isNumber(x)) {
      return undefined;
    }

    const exponential = x.toExponential();
    const expArr = String(exponential).split('e');

    return {
      sign: x >= 0,
      mantissa: Number.parseFloat(expArr[0]),
      exponent: Number.parseInt(expArr[1]),
      base: 10,
    };
  };
}
