// @flow
import React from "react";
import {Alert} from 'antd';
import {useTranslation} from "react-i18next";

type Props = {
    title: string,
    description?: string,
    type: 'success' | 'info' | 'warning' | 'error',
    onRetry?: Function,
    closeText?: string,
    closeable?: boolean,
    onClose?: React.MouseEventHandler<HTMLButtonElement>,
    status?: 'default'|'404'
}

const Error = (props: Props) => {

    const {status, description, type, closeable, closeText, onClose, title} = props;
    const {t} = useTranslation();

    const renderContent = () => {
        switch(status){
            case '404': {
                return <Alert message={t('Content not found')}
                              description={description}
                              type={type}
                              closable={closeable }
                              closeText={closeText }
                              onClose={onClose }
                />
            }
        }

        return <Alert message={title}
                      description={description}
                      type={type}
                      closable={closeable }
                      closeText={closeText }
                      onClose={onClose }
        />
    };

    return <div>
        {
            renderContent()
        }
    </div>;
};

Error.defaultProps = {
    title: "Es ist ein Fehler aufgetreten",
    description: "Leider ist ein Fehler aufgetreten. Versuchen Sie das Fenster neu zu laden. Sollte der Fehler erneut auftreten, wenden Sie sich bitte an den Administrator.",
    type: "error",
    status: 'default'
};

export default Error;
