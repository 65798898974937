// @flow
import React, {ReactNode, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import QueryTable from "../QueryTable";
import QueryMasterTableToolbar from "../QueryMasterTableToolbar";
import ListFilter from "../ListFilter";
import Sort from "../../Types/Sort";
import _ from 'lodash';
import {getColumns} from "../../utils/masterTable";
import {Empty} from "antd";

export interface TableProps {
    useFullHeight?: boolean,
    columns: {
        key: string,
        title?: string,
        dataIndex?: string,
        render?: (text: any, record: any) => ReactNode,
        filterMultiple?: boolean,
        useSearch?: boolean,
        useFilter?: boolean,
        useSort?: boolean,
        filters?: any[] | {
            query: any,
            queryPath: string
        },
        filteredValue?: string[],
        filteredValueFilterKey?: String,
        onFilter?: Function,
        filterDropdown?: any,
        filterIcon?: any,
        onFilterDropdownVisibleChange?: any,
        sorter?: Function | boolean,
        sortOrder?: boolean | 'ascend' | 'descend',
        sortDirections?: ('descend' | 'ascend')[]
        ellipsis?: any,
        width?: any,
        onCell?: (record: any, rowIndex: any) => {
            onClick?: (event: any) => {},
                onDoubleClick?: (event: any) => {},
                onContextMenu?: (event: any) => {},
                onMouseEnter?: (event: any) => {},
                onMouseLeave?: (event: any) => {},
        }
    }[],
    filterConfig?: {
        useInlineFilter?: boolean,
        transformFilter?: (filter: any, changes?: any) => any,
        filter?: object,
        defaultFilter?: object,
        sort?: Sort[],
        onSearchChange?: (filter: any, changes?: any) => any,
        onFilterChange?: (filter: any, changes?: any) => any,
        onSortChange?: (sort: any) => any,
        onChangeAttributes?: any,
        attributes?: any[]
    },
    size?: string,
    rowKey?: string,
    onRow?: (record: any, index: any) => {
        onClick?: (event: any) => {},
            onDoubleClick?: (event: any) => void,
            onContextMenu?: (event: any) => void,
            onMouseEnter?: (event: any) => void,
            onMouseLeave?: (event: any) => void,
    },
    useRowSelection?: boolean,
    rowSelection?: {
        onChange?: Function,
        getCheckboxProps?: Function
    },
    toolbarConfig?: {
        renderLeft?: {
            content: any,
            useSelection?: boolean,
            access?: boolean,
            onClick?: Function,
            mutation?: {
                mutation: any,
                mutationOptions?: any,
                translateMutationOptions: any,
                successToast?: string
            }
        }[],
        renderRight?: {
            content: any,
            useSelection?: boolean,
            access?: boolean,
            onClick?: Function,
            mutation?: {
                mutation: any,
                mutationOptions?: any,
                translateMutationOptions: any,
                successToast?: string
            }
        }[]
    },
    componentStyle?: any,
    tableStyle?: any,
    scroll?: any,
    usePagination?: boolean
}

type Props = TableProps & {
    queryData: {
        query: any,
        pagingSize?: number,
        queryOptions?: any,
        itemsKeyPath: string,
        totalKeyPath: string,
        limitKeyPath?: string,
        startKeyPath?: string,
        queryVariables?: object,
    }
}

const sortTableDirectionMap: any = {
    'DESC': 'descend',
    'ASC': 'ascend'
};

const sortArgDirectionMap = Object.keys(sortTableDirectionMap).reduce((acc: any, key: any) => {
    acc[sortTableDirectionMap[key]] = key;
    return acc;
},{});


const QueryMasterTable = (props: Props) => {

    let {
        queryData,
        rowSelection,
        useRowSelection,
        toolbarConfig,
        size,
        rowKey,
        columns,
        filterConfig,
        componentStyle,
        tableStyle,
        scroll,
        usePagination,
        useFullHeight,
    } = props;
    const {t} = useTranslation();

    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [cacheFilter, setCacheFilter]: any = useState(null);
    const [cacheSorter, setCacheSorter]: any = useState(null);

    useEffect(() => {
        if(filterConfig){
            if(filterConfig.filter){
                setCacheFilter(filterConfig.filter);
            }
            if(filterConfig.defaultFilter){
                setCacheFilter(filterConfig.defaultFilter);
            }
            if(filterConfig.sort){
                setCacheSorter(filterConfig.sort);
            }
        }
    }, [filterConfig]);

    const searchInput = useRef(null);

    if (!queryData) return <Empty description={t('Missing loading information')}/>

    if(useRowSelection && !rowSelection){
        rowSelection = {
            onChange: (selectedRowKeys: any, selectedRows: any) => {
                setSelectedRowIds(selectedRows.map((item: any) => item.id));
            },
            getCheckboxProps: (record: any )=> ({
                disabled: record.current,
                name: record.name,
            }),
        };
    }

    const handleSearch = ( [search]: any, dataIndex: any, confirm?: any) => {
        confirm();

        const changes = {[dataIndex]: search};
        let value = {
            ...cacheFilter,
            ...changes
        };

        if(_.get(props, 'filterConfig.transformFilter')){
            // @ts-ignore
            value = props.filterConfig.transformFilter(value, changes);
        }

        if(_.get(filterConfig, 'onFilterChange')){
            // @ts-ignore
            return filterConfig.onFilterChange(value, changes);
        }

        setCacheFilter(value);
    };

    const handleReset = (clearFilters: any, dataIndex?: any) => {
        clearFilters();

        let value = {
            ...cacheFilter,
            [dataIndex]: null
        };

        if(_.get(props, 'filterConfig.transformFilter')){
            // @ts-ignore
            value = props.filterConfig.transformFilter(value, {[dataIndex]: null});
        }

        if(_.get(filterConfig, 'onFilterChange')){
            // @ts-ignore
            return filterConfig.onFilterChange(value, {[dataIndex]: null});
        }

        setCacheFilter(value);
    };

    columns = getColumns({
        t,sortTableDirectionMap,setCacheFilter,searchInput, handleSearch, handleReset,filterConfig,columns,cacheSorter,cacheFilter
    });



    return <div className={useFullHeight ? "full-height" : ""} style={componentStyle}>
        {
            filterConfig && !filterConfig.useInlineFilter && filterConfig.attributes
                ? <ListFilter
                    onChange={(changes: any) => {
                        let newParams = changes;

                        if(filterConfig && filterConfig.onChangeAttributes){
                            newParams = filterConfig.onChangeAttributes(changes)
                        }

                        setCacheFilter(newParams);
                    }}
                    params={cacheFilter}
                    attributes={filterConfig.attributes}
                />
                : null
        }

        {
            toolbarConfig
                ? <QueryMasterTableToolbar
                    toolbarConfig={toolbarConfig}
                    selectedRowIds={selectedRowIds}
                    setSelectedRowIds={setSelectedRowIds}
                />
                : null
        }

        <QueryTable
            filter={cacheFilter}
            onFilterChange={(filter: any) => {

                if(_.get(props, 'filterConfig.transformFilter')){
                    // @ts-ignore
                    filter = props.filterConfig.transformFilter(filter);
                }

                if(_.get(filterConfig, 'filter') && _.get(filterConfig, 'onFilterChange')){
                    // @ts-ignore
                    return props.filterConfig.onFilterChange(filter);
                }

                if(_.get(filterConfig, 'onFilterChange')){
                    // @ts-ignore
                    const newFilter = props.filterConfig.onFilterChange(filter);
                    if(newFilter) filter = newFilter;
                }

                if(filterConfig && filterConfig.defaultFilter){
                    filter = {
                        ...filter,
                        ...filterConfig.defaultFilter
                    }
                }

                setCacheFilter(filter);
            }}
            sorter={cacheSorter}
            onSorterChange={(sorter: any) => {
                let newSort = [{
                    key: sorter.field,
                    direction: _.get(sortArgDirectionMap,sorter.order)
                }].filter((item) => !!item.direction);

                if(_.get(props, 'filterConfig.onSortChange')){
                    // @ts-ignore
                    newSort = props.filterConfig.onSortChange(sorter);
                }

                setCacheSorter(newSort)
            }}
            rowSelection={rowSelection}
            size={size ? size : 'small'}
            rowKey={rowKey ? rowKey : "id"}
            queryData={queryData}
            columns={columns}
            style={tableStyle}
            scroll={scroll}
            useFullHeight={useFullHeight}
            usePagination={usePagination}
            // @ts-ignore
            locale={{
                filterConfirm: t('OK'),
                filterReset: t('Reset'),
                emptyText: t('No Data'),
            }}
        />
    </div>
};

export default QueryMasterTable
