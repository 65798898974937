// @flow
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import QueryMasterTableToolbar from "../QueryMasterTableToolbar";
import {Button, Input, Space, Table} from "antd";
import _ from "lodash";
import {SearchOutlined} from '@ant-design/icons';
import ListFilter from "../ListFilter";
import {Row} from "../Flex";
import {TableProps} from "../QueryMasterTable";
import {getColumns} from "../../utils/masterTable";

type Props = TableProps & {
    dataSource: any,
    onChange?: any,
};

const sortTableDirectionMap: any = {
    'DESC': 'descend',
    'ASC': 'ascend'
};

const sortArgDirectionMap = Object.keys(sortTableDirectionMap).reduce((acc: any, key: any) => {
    acc[sortTableDirectionMap[key]] = key;
    return acc;
},{});

const MasterTable = (props: Props) => {

    let {
        rowSelection,
        useRowSelection,
        toolbarConfig,
        size,
        rowKey,
        columns,
        dataSource,
        onChange,
        componentStyle,
        tableStyle,
        scroll,
        usePagination,
        filterConfig
    } = props;
    const {t} = useTranslation();

    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [cacheSearch, setCacheSearch]: any = useState(null);
    const [cacheFilter, setCacheFilter] = useState(_.get(filterConfig, 'defaultFilter') || _.get(filterConfig, 'filter'));
    const [cacheSorter, setCacheSorter] = useState(_.get(filterConfig, 'sort'));

    if(useRowSelection && !rowSelection){
        rowSelection = {
            onChange: (selectedRowKeys: any, selectedRows: any) => {
                setSelectedRowIds(selectedRows.map((item: any) => item.id));
            },
            getCheckboxProps: (record: any )=> ({
                disabled: record.current,
                name: record.name,
            }),
        };
    }

    const searchInput = useRef(null);

    const handleSearch = ( [search]: any, dataIndex: any) => {
        setCacheSearch({
            searchString: search,
            index: dataIndex
        });
    };

    columns = getColumns({
        t,sortTableDirectionMap,setCacheFilter,searchInput,handleSearch,filterConfig,columns,cacheSorter,cacheFilter
    });

    let items = [...dataSource];

    if (cacheFilter) {
        items = _.filter(items, cacheFilter);
    }
    if (cacheSorter) {
        items = _.sortBy(items, cacheSorter)
    }
    // @ts-ignore
    if (!_.isNil(cacheSearch) && cacheSearch.searchString && cacheSearch.index) {
        // @ts-ignore
        items = _.filter(items, (obj: any) => obj[cacheSearch.index].match(new RegExp(cacheSearch.searchString, 'gim')));
    }

    return <div style={componentStyle}>
        {
            filterConfig && !filterConfig.useInlineFilter && filterConfig.attributes
                ? <ListFilter
                    onChange={(changes: any) => {
                        let newParams = changes;

                        if(filterConfig && filterConfig.onChangeAttributes){
                            newParams = filterConfig.onChangeAttributes(changes)
                        }

                        setCacheFilter(newParams);
                    }}
                    params={cacheFilter}
                    attributes={filterConfig.attributes}
                />
                : null
        }

        {
            toolbarConfig && selectedRowIds && selectedRowIds.length && selectedRowIds.length > 0
                ? <QueryMasterTableToolbar
                    toolbarConfig={toolbarConfig}
                    selectedRowIds={selectedRowIds}
                    setSelectedRowIds={setSelectedRowIds}
                />
                : null
        }

        {
            // @ts-ignore
            <Table
                // @ts-ignore
                rowSelection={rowSelection}
                rowKey={rowKey ? rowKey : "id"}
                // @ts-ignore
                columns={columns}
                scroll={scroll}
                footer={ () => {
                    const total = items.length;
                    return <Row style={{height: 30, contentAlign: 'middle'}}>
                        <span className={"flex-grow-1"}>
                            {total>-1 ? `${total} ${t("items")}` : t("No entries loaded")}
                        </span>
                    </Row>
                } }
                // @ts-ignore
                pagination={usePagination}
                onChange={(pagination, filters, sorter: any) => {
                    if (filters){
                        let changes: any = {};
                        Object.keys(filters).map((key: string) => {
                            if(!_.isNil(filters[key]) && !_.isEmpty(filters[key])){
                                changes[key] = filters[key];
                            }
                        });

                        if(_.get(props, 'filterConfig.transformFilter')){
                            // @ts-ignore
                            changes = props.filterConfig.transformFilter(changes);
                        }

                        if(_.get(filterConfig, 'filter') && _.get(filterConfig, 'onFilterChange')){
                            // @ts-ignore
                            return props.filterConfig.onFilterChange(changes);
                        }

                        if(_.get(filterConfig, 'onFilterChange')){
                            // @ts-ignore
                            const newFilter = props.filterConfig.onFilterChange(changes);
                            if(newFilter) changes = newFilter;
                        }

                        setCacheFilter(changes);
                    }

                    if (sorter) {
                        let newSort = [{
                            key: sorter.field,
                            direction: _.get(sortArgDirectionMap,sorter.order)
                        }].filter((item) => !!item.direction);

                        if(_.get(props, 'filterConfig.onSortChange')){
                            // @ts-ignore
                            newSort = props.filterConfig.onSortChange(sorter);
                        }

                        setCacheSorter(newSort)
                    }

                }}
                {...props}
                dataSource={items}
            />
        }
    </div>
};

export default MasterTable
