// @ts-nocheck
import * as React from 'react';
import './index.css';
import {CloseOutlined} from "@ant-design/icons";

export interface IProps {
  value?: any,
  labelName: React.ReactNode,
  closeItem: React.ReactNode[],
  onChange?: (val: any) => void
}

const FilterValueLabel = (props: IProps) => {
  const {labelName, closeItem, value} = props;

  const onItemClose = (index: number) => {
    let changes = null;

    if (Array.isArray(value)) {
      changes = value.filter((_item, i) => {
        return i !== index;
      })

      if (!changes.length) {
        changes = null
      }
    }

    if (props.onChange) props.onChange(changes)
  }

  const labelValues = closeItem.map((title, index) => {

    return (
      <span className='content-label' key={`${index}`}>
            <span className='value-content'>
                <span className='filter-value'>{title}</span>
                <span className='close' onClick={() => onItemClose(index)}><CloseOutlined/></span>
            </span>
            </span>
    );
  });

  return (
    <span className='filter-label'>
          {labelName ? <span className='label-name'>
            {labelName}
          </span> : null}
      {labelValues}
        </span>
  );
}

export default FilterValueLabel
