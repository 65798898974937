import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, MarkdownAttribute, Translations} from '@dreebit/form-attributes-core';
import MarkdownEditor from "../MarkdownEditor";
import EditableWrapper from "../Wrapper/EditableWrapper";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: MarkdownAttribute<any>,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

class AttributeMarkdownComponent extends Component<Props> {

  render() {
    const {attribute, style, form, allValues, translations, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      form={form}
      allValues={allValues}
      formProps={formProps}
      translations={translations}
      attribute={attribute}>

      {({value, onChange}) => {

        return <MarkdownEditor value={value}
                               onChange={onChange}
                               placeholder={attribute.placeholder}
                               id={attribute.index}
                               markdownButtons={attribute.markdownButtons}
                               disabled={attribute.disabled}
                               className={`form-item-markdown-${attribute.index}`}
                               {...attribute.inputProps}
        />
      }}

    </EditableWrapper>

  }
}

export default AttributeMarkdownComponent;
