import * as React from 'react';
import {Component} from 'react';
import _ from "lodash";
import {Input} from "antd";
import {
  AttributeFormStyles,
  FormAttributeRenderContext,
  TextAttribute,
  Translations
} from '@dreebit/form-attributes-core';
import EditableWrapper from "../Wrapper/EditableWrapper";
import {DisplayValueRenderArgs} from "../EditableAttributeContainer";
import createAddon from "../../utils/AttributeAddonHelper";
import './index.css';
import {OnFormItemClick} from "../../types";


type Props = {
  attribute: TextAttribute<any>,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

class AttributeTextComponent extends Component<Props> {

  state = {
    value: null
  }

  constructor(props: Props) {
    super(props);

  }

  _handleChanges = (changes: string, onChange: (value: string) => void) => {

    onChange(changes);
    this.setState({
      value: null
    })
  }

  debounceChanges = _.debounce(this._handleChanges, this.props.attribute.debounceTime || 0);

  render() {
    const {attribute, style, form, translations, allValues, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      allValues={allValues}
      formProps={formProps}
      translations={translations}
      displayValueRender={(args: DisplayValueRenderArgs) => {
        return _.get(args, 'value', <span
          className={"placeholder"}>{_.get(attribute, 'placeholder', attribute.name)}</span>)
      }}
      form={form}
      attribute={attribute}>

      {({value, onChange, onKeyPress, inputRef, onClick}) => {

        // @ts-ignore
        let addOnAfter: any = createAddon(attribute, form, this.context.renderFactory, style, translations);
        return <Input
          readOnly={_.get(attribute, 'readonly', false)}
          autoComplete={attribute.autocomplete}
          ref={inputRef}
          value={this.state.value !== null ? this.state.value : value}
          defaultValue={attribute.defaultValue}
          onChange={(event) => {
            const value = event.target.value;
            if (_.get(attribute, 'inputTypeOptions.debounceTime')) {
              this.setState({
                value,
              }, () => {
                this.debounceChanges(value, onChange);
              })

            } else {
              onChange(value)
            }
          }}
          onKeyPress={onKeyPress}
          onClick={(event) => onClick && onClick(event, attribute.index)}
          id={attribute.index}
          className={`form-item-text form-item-text-${attribute.index}`}
          addonAfter={addOnAfter}
          disabled={attribute.disabled}
          placeholder={_.get(attribute, 'inputTypeOptions.placeholder', attribute.placeholder)}/>
      }}

    </EditableWrapper>

  }
}

AttributeTextComponent.contextType = FormAttributeRenderContext;

export default AttributeTextComponent;
