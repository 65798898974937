import * as React from 'react';
import {Component} from 'react';
import {Attribute, AttributeFormStyles, RcForm, Translations} from '@dreebit/form-attributes-core';
import * as _ from "lodash";
import EditableWrapper from "../Wrapper/EditableWrapper";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: Attribute<any>,
  translations: Translations,
  form: RcForm,
  style: AttributeFormStyles,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

type State = {}

class AttributeSectionComponent extends Component<Props, State> {

  render() {
    const {attribute, style, form, allValues, translations, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-radio form-attribute-radio-' + attribute.index}
      style={style}
      form={form}
      formProps={formProps}
      allValues={allValues}
      translations={translations}
      attribute={attribute}
    >
      {() => {

        return <div style={{margin: "0 8px", ..._.get(attribute, 'inputTypeOptions.style', {})}}
                    className={'attribute-section-component'}>
          <span
            style={{fontWeight: 'bold', ..._.get(attribute, 'inputTypeOptions.labelStyle', {})}}
            key={attribute.index}
          >{attribute.name || ''}
          </span>
          {_.get(attribute, 'inputTypeOptions.hint')
            ? <span style={{fontSize: 10}}>
                {_.get(attribute, 'inputTypeOptions.hint', '')}
          </span>
            : null}
        </div>;
      }}
    </EditableWrapper>

  }
}

export default AttributeSectionComponent;
