import * as React from 'react';
import {Component} from 'react';
import {Input, Tooltip} from "antd";
import {AttributeFormStyles, RenderFactory, TextAttribute, Translations} from '@dreebit/form-attributes-core';
import EditableWrapper from "../Wrapper/EditableWrapper";
import {DisplayValueRenderArgs} from "../EditableAttributeContainer";
import createAddon from "../../utils/AttributeAddonHelper";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: TextAttribute<any>,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

class AttributeHoursComponent extends Component<Props> {

  render() {
    const {attribute, style, form, translations, allValues, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      allValues={allValues}
      formProps={formProps}
      translations={translations}
      form={form}
      displayValueRender={(args: DisplayValueRenderArgs) => {
        return args.value
      }}
      attribute={attribute}>

      {({value, onChange, onKeyPress, inputRef}) => {

        let addOnAfter: any = createAddon(attribute, form, this.context.renderFactory, style, translations);
        if (attribute.addOnAttribute) {
          const renderFactory: RenderFactory = this.context.renderFactory;

          addOnAfter = renderFactory.renderAttributes([attribute.addOnAttribute], form, {
            ...style,
            withoutFormItem: true,
          }, translations)
        }

        const num = value ? Number(value) : 0;
        let years = (num / 8760);
        let ryears = Math.floor(years);
        let months = (years - ryears) * 12;
        let rmonths = Math.floor(months);
        let days = (months - rmonths) * 31;
        let rdays = Math.floor(days);
        let hours = (days - rdays) * 24;
        let rhours = Math.floor(hours);

        return <Tooltip
          title={`${ryears} ${translations['year(s)']}, ${rmonths} ${translations['month(s)']}, ${rdays} ${translations['day(s)']}, ${rhours} ${translations['hour(s)']}`}>
          <Input
            ref={inputRef}
            id={attribute.index}
            value={value}
            className={`form-item-input-${attribute.index}`}
            onChange={(event) => {
              const value = event.target.value;
              onChange(value);
            }}
            onKeyPress={onKeyPress}
            addonAfter={addOnAfter}
            disabled={attribute.disabled}
            placeholder={attribute.placeholder}/>
        </Tooltip>
      }}

    </EditableWrapper>
  }
}

export default AttributeHoursComponent;
