import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, CustomAttribute, RcForm, Translations} from '@dreebit/form-attributes-core';
import * as _ from "lodash";
import EditableWrapper from "../Wrapper/EditableWrapper";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: CustomAttribute,
  translations: Translations,
  form: RcForm,
  style: AttributeFormStyles,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

type State = {}

class AttributeCustomComponent extends Component<Props, State> {

  render() {
    const {attribute, style, form, allValues, translations, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-custom-' + attribute.index}
      style={style}
      allValues={allValues}
      formProps={formProps}
      translations={translations}
      form={form}
      attribute={attribute}
    >
      {() => {
        return _.get(attribute, 'component', null)
      }}
    </EditableWrapper>

  }
}

export default AttributeCustomComponent;
