import * as React from "react";
import {Component, ReactNode} from "react";
import {Col, Form, Tooltip} from "antd";
import {
  Attribute,
  AttributeFormStyles,
  AttributeInputType,
  doesRuleFullFillConditions,
  Rule,
  Translations
} from "@dreebit/form-attributes-core";
import ValueWrapper from "./ValueWrapper";
import EditableAttributeContainer from "../EditableAttributeContainer";
import _ from "lodash";
import './index.css';
import {OnFormItemClick} from "../../types";
import {Icon} from "../Icon";

interface RenderArguments {
  value?: any
  editable?: boolean,
  onChange?: any
  onKeyPress?: (event: any) => void,
  inputRef?: (node: any) => void,
  t?: any,
  onClick?: any,
  disabled?: boolean,
}

interface Props {
  className?: string
  enableOutsideClick?: boolean,
  attribute: Attribute<any>
  children: (args: RenderArguments) => ReactNode
  form: any
  style: AttributeFormStyles
  displayValueRender?: any,
  disableEnter?: boolean,
  translations?: Translations,
  allValues?: any,
  formProps?: any,
  enableInAttributeEdit?: boolean,
  shouldBeInsideClick?: (e: any) => boolean,
  editableContainerClassName?: string,
  editableValueWrapperClassName?: string,
  onFormItemClick?: OnFormItemClick
  loading?: boolean
}


class EditableWrapper extends Component<Props> {

  render() {
    let {
      attribute,
      children,
      form,
      style,
      editableValueWrapperClassName,
      editableContainerClassName,
      enableInAttributeEdit,
      shouldBeInsideClick,
      displayValueRender,
      enableOutsideClick,
      disableEnter,
      translations,
      allValues,
      formProps,
      onFormItemClick,
      loading
    } = this.props;

    let t = _.get(translations, 't');

    if (!t) t = (text: string) => _.get(translations, text, text);

    let formItemLayout = {
      ...style.formItemLayout,
    };

    let tailFormItemLayout = {
      ...style.tailFormItemLayout,
    };

    if (_.get(attribute, 'style.formItemLayout')) {
      formItemLayout = {
        ...formItemLayout,
        ..._.get(attribute, 'style.formItemLayout')
      }
    }

    if (formProps && formProps.layout && (attribute.hideLabel || !attribute.name || attribute.name.length < 1)) {
      if (formProps.layout === 'horizontal') {
        formItemLayout = {
          labelCol: {
            xs: {span: 0},
            sm: {span: 0},
          },
          wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
          },
        };

        // Einruecken der Checkboxes ohne Label beim horizontal Layout
        if (attribute.inputType === "CHECKBOX" && attribute.hideLabel) {
          tailFormItemLayout = {
            ...tailFormItemLayout,
            wrapperCol: {
              ...tailFormItemLayout.wrapperCol,
              sm: {
                span: 16,
                offset: 8
              }
            }
          }
        }
      }

      if (formProps.layout === 'vertical') {
        formItemLayout = {
          labelCol: {
            xs: {span: 0},
            sm: {span: 0},
          },
          wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
          }
        };
      }
    }

    const getHint = (attribute: Attribute<any>) => {
      let result: any = null;
      const hint = _.get(attribute, 'hint', _.get(attribute, 'inputTypeOptions.hint'));

      // shared attributes have a custom icon + hint (defined via getLabel function)
      if (!hint || _.get(attribute, 'shared')) {
        return null;
      }

      if (React.isValidElement(hint)) result = hint;
      else result =
        <span style={_.get(attribute, 'hintStyle', _.get(attribute, 'inputTypeOptions.hintStyle'))}>{t(hint)}</span>;

      if (formProps) if (!formProps.hideRequiredStar && _.find(attribute.rules, {required: true}) && attribute.hideLabel) result =
        <div className={'flex-row'}>
          <span className={'ant-required-star'}>*</span>
          {result}
        </div>;

      return result;
    };

    const getRules = (attribute: Attribute<any>) => {
      if (!attribute.rules) return null;

      return attribute.rules
        .map((rule: Rule) => {
          if (attribute.inputType && attribute.inputType.toUpperCase() === AttributeInputType.CHECKBOX && rule.required) return {
            ...rule,
            validator: (_: any, value: any) => value ? Promise.resolve() : Promise.reject(new Error(attribute.index + ' ' + t('is required'))),
            required: true
          };

          if (attribute.inputType && attribute.inputType.toUpperCase() === AttributeInputType.NUMBER) return {
            ...rule,
            type: 'number'
          };

          return rule;
        })
        .filter((rule: Rule): boolean => {
          if (rule.conditions && rule.conditions.length > 0) {
            const conditionsOperator = _.get(rule, 'conditionsOperator');

            const values = {
              ...allValues,
              ...form.getFieldsValue()
            };

            return doesRuleFullFillConditions(rule.conditions, values, conditionsOperator);
          }

          return true;
        })
    };

    const field = <ValueWrapper>
      {
        ({value, onChange}: any) => {

          return style.editable && (displayValueRender || enableInAttributeEdit) ? <EditableAttributeContainer
            form={form}
            loading={loading}
            value={value}
            enableOutsideClick={enableOutsideClick}
            attribute={attribute}
            displayValueRender={displayValueRender}
            enableInAttributeEdit={enableInAttributeEdit}
            onChange={onChange}
            shouldBeInsideClick={shouldBeInsideClick}
            disableEnter={disableEnter}
            editableContainerClassName={editableContainerClassName}
            editableValueWrapperClassName={editableValueWrapperClassName}
            onFormItemClick={onFormItemClick}
          >
            {children}
          </EditableAttributeContainer> : children({value, onChange, editable: true, t, onClick: onFormItemClick})
        }
      }
    </ValueWrapper>;

    if (style.withoutFormItem) return field;

    const hint = getHint(attribute);
    const rules = getRules(attribute);

    const tooltip = _.get(attribute, 'tooltip', _.get(attribute, 'inputTypeOptions.tooltip'));
    const regexAllTags = /<([a-zA-Z1-6]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)/gim;
    let htmlTags: any = null;
    if (attribute.name && typeof attribute.name === "string" && attribute.name.match) htmlTags = attribute.name.match(regexAllTags);

    let formItemClassName = this.props.className;
    if (formProps) if (!formProps.hideRequiredStar && attribute.hideLabel && !hint && _.find(attribute.rules, {required: true})) formItemClassName = formItemClassName + ' required-form-item';

    const getLabel = (attribute: Attribute<any>) => {
      if (attribute.hideLabel) {
        return null;
      }
      let customLabelIcon = null;

      if (_.get(attribute, 'shared') && _.get(attribute, 'hint')) {
        customLabelIcon = <Tooltip title={t(attribute.hint)}>
          <Icon type={"ShareAltOutlined"} style={{marginLeft: 2}}/>
        </Tooltip>
      }

      if (attribute.name && (htmlTags && htmlTags.length > 0)) {
        return <span><span
          dangerouslySetInnerHTML={{__html: typeof attribute.name === "string" ? attribute.name : ""}}/> {customLabelIcon}</span>
      } else if (attribute.name && !(htmlTags && htmlTags.length > 0)) {
        return <span
          style={_.get(attribute, 'inputTypeOptions.labelStyle', {})}>{t(attribute.name)} {customLabelIcon}</span>
      } else {
        return null;
      }
    }

    const FormItem = <Form.Item
      name={attribute.index}
      htmlFor={`form-item-${attribute.index}`}
      className={formItemClassName}
      {...formItemLayout}
      {...tailFormItemLayout}
      hasFeedback={attribute.hasFeedback ? attribute.hasFeedback : undefined}
      help={hint}
      tooltip={tooltip ? tooltip : undefined}
      hidden={attribute.hideAttribute}
      noStyle={_.get(attribute, 'noStyle')}
      rules={rules}
      label={getLabel(attribute)}
    >
      {field}
    </Form.Item>

    if (_.get(formProps, 'layout') === 'inline') {
      // @ts-ignore
      return <div style={hint ? {marginBottom: 20} : null}>
        {FormItem}
      </div>
    }

    let itemSpan = _.get(formItemLayout, 'span', 24);

    return <Col
      className={'editableWrapper'}
      xs={24}
      sm={itemSpan > 12 ? 24 : 12}
      md={itemSpan > 12 ? 24 : itemSpan <= 12 ? 12 : 8}
      lg={itemSpan}
    >
      <div className={`flex-col full-width`}
           style={{
             marginBottom: hint ? 20 : 0
           }}
      >
        {FormItem}
      </div>
    </Col>
  }
}

export default EditableWrapper
