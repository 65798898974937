import * as React from "react";
import {ReactNode} from "react";
import {Attribute} from "@dreebit/form-attributes-core";
import _ from "lodash";
import {createAddonLabel} from "../../utils/AttributeAddonHelper";
import {Icon} from "../Icon";
import {OnFormItemClick} from "../../types";


export interface DisplayValueRenderArgs {
  value?: any
}

interface RenderArguments {
  editable?: boolean,
  value?: any,
  onChange?: any,
  onClick?: any,
  onKeyPress?: (event: any) => void,
  inputRef?: (node: any) => void,
  disabled?: boolean,
}

interface Props {
  enableOutsideClick?: boolean,
  form: any,
  attribute: Attribute<any>,
  value: any,
  onChange: Function
  children: (args: RenderArguments) => ReactNode,
  displayValueRender?: (args: DisplayValueRenderArgs) => ReactNode,
  disableEnter?: boolean,
  enableInAttributeEdit?: boolean,
  shouldBeInsideClick?: (e: any) => boolean,
  editableContainerClassName?: string,
  editableValueWrapperClassName?: string,
  onFormItemClick?: OnFormItemClick
  loading?: boolean
}

interface State {
  loading: boolean,
  editMode: boolean,
  changedValue?: any,
}


class EditableAttributeContainer extends React.Component<Props, State> {

  state = {
    loading: this.props.loading || false,
    editMode: false,
    changedValue: undefined,
  }


  node: any = null;
  input: any = null;

  componentWillMount(): void {
    document.addEventListener('mousedown', this.handleAnyClick, false)
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleAnyClick, false)
  }

  handleAnyClick = (e: any) => {
    if (this.node && this.node.contains && !this.node.contains(e.target)) {
      this.handleOutsideClick();
    }
  }

  handleOutsideClick = () => {
    if (!this.state.editMode || !this.props.enableOutsideClick) {
      return;
    }
    this.handleSubmit();
  }

  setLoading(loading: boolean) {
    return new Promise((resolve) => {
      this.setState({loading: loading}, resolve)
    })
  }

  handleSubmit = async () => {
    if (this.state.changedValue !== undefined && this.state.changedValue !== this.props.value) {

      this.setLoading(true)
        .then(async () => {
          return this.props.onChange(this.state.changedValue);
        })
        .then(() => {
          // wenn props.loading true ist, dann warte so lange, bis die loading props auf false gesetzt wird
          return new Promise((resolve) => {
            const interval = setInterval(() => {
              if (!this.props.loading) {
                clearInterval(interval);
                resolve();
              }
            }, 10);
          })
        })
        .then(() => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {

              this.props.form.validateFields([this.props.attribute.index])
                .then(resolve)
                .catch(reject)
            }, 0)
          })
        })
        .then(() => {
          this.close();
        })
        .finally(() => this.setLoading(false))
    } else {
      this.close();
    }
  }

  open = (e: any) => {
    if (!this.props.shouldBeInsideClick || this.props.shouldBeInsideClick(e)) {

      this.setState({
        editMode: true,
        changedValue: this.props.value,
      }, () => {

        if (this.input && this.input.focus) {
          this.input.focus();
        }
      });
      return true
    }
    return false
  }

  close = () => {
    this.setState({
      editMode: false,
      changedValue: undefined,
    });
  };

  handleKeyPress = (e: any) => {

    if (e.key === 'Enter' && !this.props.disableEnter) {
      e.preventDefault();
      this.handleSubmit();
    }

    if (e.key === 'Escape') {
      e.preventDefault();
      this.close();
    }
  };

  render() {
    const {
      children,
      value,
      displayValueRender,
      enableInAttributeEdit,
      attribute,
      editableContainerClassName,
      editableValueWrapperClassName,
      onFormItemClick
    } = this.props;
    const {editMode, loading} = this.state;

    const onClickHandler = (e: any) => {
      if (onFormItemClick) {
        onFormItemClick(e, attribute.index)
        this.close();
      }
    }

    if (!editMode) {
      if (enableInAttributeEdit) {
        return <div className={"editable-container"} ref={node => this.node = node}>
          <div className={"editable-input"}>
            {children({
              editable: true,
              inputRef: (input) => {
                this.input = input;
              },
              value,
              onClick: (e: any) => {
                if (this.open(e)) {
                  if (this.input) this.input.focus();
                }
                onClickHandler(e);
              },
              onChange: () => {
                return null;
              }
            })}
          </div>
        </div>
      }

      return <div className={`editable-container ${editableContainerClassName}`} ref={node => this.node = node}>
        <div onClick={(e) => {
          if (!_.get(attribute, 'disabled', false)) {
            this.open(e)
            onClickHandler(e);
          }
        }}
             className={_.get(attribute, 'disabled', false) ? "editable-disabled-value-wrap" : "editable-value-wrap"}
        >
          <div className={`editable-value ${editableValueWrapperClassName}`}>
            {
              displayValueRender
                ? displayValueRender({value})
                : value !== null && value !== undefined ? value :
                  <span className={"placeholder"}>{_.get(attribute, 'placeholder', '')}</span>
            }
          </div>
          {
            attribute.addOn
              ? <span className={"editable-addon-wrapper ant-input-group-addon"}>{createAddonLabel(attribute)}</span>
              : null
          }
        </div>
      </div>
    }

    return <div className={"editable-container"} ref={node => this.node = node}>
      <div className={"editable-input-wrap"}>

        <div className={"editable-input"}>
          {children({
            editable: true,
            onKeyPress: (e) => this.handleKeyPress(e),
            inputRef: (input) => {
              this.input = input;
            },
            value: this.state.changedValue,
            onClick: (e: any) => {
              onClickHandler(e);
            },
            disabled: loading,
            onChange: (changedValue: any) => {
              this.setState({
                changedValue
              })
            }
          })}
        </div>

        <div style={{
          display: 'flex',
          paddingLeft: 10
        }}>
          {
            loading
              ? <Icon
                type={"LoadingOutlined"}
                style={{
                  fontSize: 21
                }}
                className="editable-icon-loading"
              />
              : <Icon
                type={"CheckCircleOutlined"}
                style={{fontSize: 21}}
                className="editable-icon-check"
                onClick={() => {
                  this.handleSubmit();
                }}
              />
          }
        </div>
      </div>
    </div>
  }
}

export default EditableAttributeContainer;
