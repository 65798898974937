import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, OptionAttribute, RcForm, Translations} from "@dreebit/form-attributes-core";
import EditableWrapper from "../Wrapper/EditableWrapper";
import DataLoaderWrapper from "../Wrapper/DataLoaderWrapper";
import * as _ from "lodash";
import SelectList from "../SelectList";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: OptionAttribute<any>,
  form: RcForm,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
};

type State = {}

class AttributeSelectListComponent extends Component<Props, State> {

  render() {
    const {attribute, style, form, translations, allValues, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      style={style}
      form={form}
      allValues={allValues}
      formProps={formProps}
      translations={translations}
      attribute={attribute}>

      {({value, onChange}) => {
        return <DataLoaderWrapper
          form={form}
          value={_.get(value, 'key', value)}
          onChange={onChange}
          options={attribute.options}
          dataLoaderConfig={attribute.dataLoaderConfig}>
          {({options, onChange, onSearch}) => {
            return <SelectList
              onSearch={attribute.dataLoaderConfig && _.get(attribute, "dataLoaderConfig.dataQueryConfig.variables") && !attribute.localSearch ? onSearch : undefined}
              translations={translations} value={value} options={options} onChange={onChange}/>
          }
          }

        </DataLoaderWrapper>
      }}

    </EditableWrapper>


  }
}

export default AttributeSelectListComponent;
