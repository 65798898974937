import React, {useState} from "react";
import {Select} from "antd";
import {useTranslation} from "react-i18next";
import {ANTD_ICON_MAP, Icon} from "../Icon";
import {MDIMap} from "../MaterialCommunityIcon/MaterialCommunityIcon";
import _ from "lodash";
const icons = require(`@ant-design/icons`);

type Props = {
    value?: string,
    onChange: (value: any) => any

    allowClear?: boolean,
    disabled?: boolean,

    className?: string,
    bordered?: boolean
}

const IconMap = _.sortBy([
    ...Object.keys(icons),
    ...Object.keys(ANTD_ICON_MAP),
    ...Object.keys(MDIMap)
]);

export const renderIconItem = (icon: string) => {
    return <div className={'flex-row gap-10 flex-align-items-center'}>
        <div
            className={'flex flex-center flex-align-items-center'}
            style={{
                width: 35
            }}
        >
            <Icon
                type={icon}
            />
        </div>

        <div>{icon}</div>
    </div>
}

export const IconPicker = ({
    value, className, onChange, allowClear, bordered, disabled
                    }: Props) => {
    const {t} = useTranslation();

    const [searchValue, setSearchValue]: any = useState();

    let items = IconMap.map((icon: string, index: number) => {
        return <Select.Option
            value={icon}
            key={index}
        >
            {renderIconItem(icon)}
        </Select.Option>
    });

    if(searchValue) {
        items = [
            <Select.Option value={searchValue}>{renderIconItem(searchValue)}</Select.Option>,
            ...items,
        ]
    }

    return <Select
        value={value}
        className={'full-width ' + className}
        onChange={onChange}
        allowClear={allowClear}
        bordered={bordered}
        disabled={disabled}
        placeholder={t('Icon')}
        showSearch
        onSearch={(q: string) => setSearchValue(q)}
    >
        {items}
    </Select>
};
