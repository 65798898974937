// @flow
import React, {useEffect, useState} from 'react';
import {Popconfirm} from "antd";
import {useTranslation} from "react-i18next";
import {Button} from "../Button";
import _ from 'lodash';

type Props = {
    mutation: any,
    buttonProps?: any,
    onDone?: (res: any) => void,
    onError?: (err: any) => void,
    confirm?: boolean,
    onConfirmOK?: any,
    onConfirmCancel?: any,
    confirmationText?: string,
    okText?: string,
    cancelText?: string,
    children?: any,
    onChangeLoading?: any,
    loading?: boolean
};

export const MutationButton = ({
                                   mutation,
                                   onDone,
                                   buttonProps,
                                   cancelText,
                                   confirm,
                                   confirmationText,
                                   okText,
                                   onConfirmCancel,
                                   onConfirmOK,
                                   onError,
                                   children,
                                   loading,
                                   onChangeLoading
}: Props) => {
    const {t} = useTranslation();
    const [loadingState, setLoading] = useState(false);

    useEffect(() => {
        if(!_.isNil(loading) && loading !== loadingState){
            setLoading(loading)
        }
    }, [loading])

    const onClick = () => {
        if(onChangeLoading) onChangeLoading(true)
        else setLoading(true);

        mutation()
            .then((res: any) => {
                if (onDone) {
                    onDone(res);
                }
            })
            .catch((err: any) => {
                if (onError) onError(err)
            })
            .finally(() => {
                if(onChangeLoading) onChangeLoading(false)
                else setLoading(false);
            });
    };

    if(confirm) return <Popconfirm
        title={confirmationText || t("Are you sure?")}
        onConfirm={() => {
            if(onConfirmOK) onConfirmOK();
            return onClick();
        }}
        onCancel={() => {
            if(onConfirmCancel) onConfirmCancel();
            return null;
        }}
        okText={okText || t("Yes")}
        cancelText={cancelText || t("No")}
    >
        <Button {...buttonProps} loading={loadingState}>
            {children}
        </Button>
    </Popconfirm>;

    return <Button {...buttonProps} loading={loadingState} onClick={onClick}>
        {children}
    </Button>;
};

MutationButton.defaultProps = {
    loading: false
}
