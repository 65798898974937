import * as React from 'react';
import {useEffect, useState} from 'react';
import _ from "lodash";
import {Popconfirm, Switch} from "antd";
import {Attribute, AttributeFormStyles, RcForm, Translations} from '@dreebit/form-attributes-core';
import EditableWrapper from "../Wrapper/EditableWrapper";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: Attribute<any>,
  form: RcForm,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

const SwitchComponent = (props: any) => {

  const {value, attribute, editable, onChange, disabled} = props;

  const [changes, setChanges] = useState(value);

  useEffect(() => {
    setChanges(value);
  }, [value])

  if (attribute.confirm && editable && !attribute.disabled) {
    return <Popconfirm
      title={_.get(attribute, 'confirmTitle')}
      onConfirm={() => {
        onChange(changes)
      }}
      onCancel={() => {
        setChanges(undefined)
      }}
    >
      <Switch
        checked={changes !== undefined ? changes : value}
        defaultChecked={value}
        loading={attribute.loading}
        onChange={(v) => {
          setChanges(v)
        }}
        className={`form-item-switch-${attribute.index}`}
        disabled={disabled || attribute.disabled}
        checkedChildren={_.get(attribute, 'checkedText', _.get(attribute, 'inputTypeOptions.checkedText'))}
        unCheckedChildren={_.get(attribute, 'unCheckedText', _.get(attribute, 'inputTypeOptions.unCheckedText'))}
      />
    </Popconfirm>
  }

  return <Switch
    checked={changes}
    onChange={onChange}
    className={`form-item-switch-${attribute.index}`}
    loading={attribute.loading}
    disabled={disabled || attribute.disabled}
    checkedChildren={_.get(attribute, 'checkedText', _.get(attribute, 'inputTypeOptions.checkedText'))}
    unCheckedChildren={_.get(attribute, 'unCheckedText', _.get(attribute, 'inputTypeOptions.unCheckedText'))}
  />
}

const AttributeSwitchComponent = (props: Props) => {
  const {attribute, form, style, translations, allValues, formProps, onFormItemClick} = props;


  return <EditableWrapper
    onFormItemClick={onFormItemClick}
    loading={attribute.loading}
    className={'form-attribute-text-' + attribute.index}
    style={style}
    form={form}
    translations={translations}
    allValues={allValues}
    formProps={formProps}
    attribute={attribute}>

    {({value, onChange, editable, disabled}) => {

      return <SwitchComponent
        value={value}
        attribute={attribute}
        editable={editable}
        disabled={disabled}
        onChange={onChange}/>

    }}

  </EditableWrapper>
}

export default AttributeSwitchComponent;
