import React from "react";
import _ from "lodash";
import {Select} from "antd";

interface Props {
  value: any,
  onChange: any,
  attribute: any
  open?: boolean,
  onDropdownVisibleChange?: any,
  onMouseEnter?: any,
  onFocus?: any
  options?: any[]
}

const NumberSelect = ({
                        value,
                        onChange,
                        attribute,
                        options,
                        onDropdownVisibleChange,
                        onFocus,
                        onMouseEnter,
                        open
                      }: Props) => {
  let {index, rules, disabled, placeholder, style}: any = attribute;

  return <Select value={value}
                 className={`form-item-number-select-${index} flex-grow-1`}
    // @ts-ignore
                 style={style}
                 onChange={(changes) => {
                   if (onChange) onChange(changes);
                 }}
                 allowClear={!_.find(rules, {required: true})}
                 disabled={disabled}
                 placeholder={placeholder}
                 options={options}
                 open={open}
                 onDropdownVisibleChange={onDropdownVisibleChange}
                 onMouseEnter={onMouseEnter}
                 onFocus={onFocus}
  />
}

export default NumberSelect;
