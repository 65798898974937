// @flow
import * as React from 'react';
import {useState} from 'react';
import {Modal} from "antd";
import {AttributesForm} from "../../index";

type Props = {
  title?: any,
  value: any,
  visible: boolean,
  attributes: any,
  onSave: Function,
  onCancel: Function,
  attributesFormOptions?: any
}

const FormModal = ({visible, attributes, onCancel, onSave, value, title, attributesFormOptions}: Props) => {
  const [values, setValues] = useState(value);

  return <Modal
    title={title}
    visible={visible}
    footer={null}
    onCancel={() => onCancel()}
  >
    <AttributesForm
      submit={(values: any) => new Promise((resolve: any) => {
        onSave(values);
        resolve(values);
      })}
      attributes={attributes}
      values={values}
      onChange={(_values: any, allValues: any) => setValues(allValues)}
      {...attributesFormOptions}
    />
  </Modal>
};

export default FormModal
