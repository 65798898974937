import * as React from 'react';
import {Component} from 'react';
import * as _ from "lodash";
import {
  AttributeFormStyles,
  AttributeTargetType,
  OptionValue,
  RadioOptionAttribute,
  RcForm,
  Translations
} from '@dreebit/form-attributes-core';
import {Radio} from "antd";
import EditableWrapper from "../Wrapper/EditableWrapper";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: RadioOptionAttribute<any>,
  form: RcForm,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

type State = {}

class AttributeRadioboxComponent extends Component<Props, State> {

  render() {
    const {form, attribute, style, translations, allValues, formProps, onFormItemClick} = this.props;
    // @ts-ignore
    const type = attribute.radioType || 'default';
    let options = _.get(attribute, 'options', []);
    if (!options.length) options = [];


    const createPickerItem = (title: string, value: any) => {
      switch (type) {
        case "button": {
          return <Radio.Button id={value} key={value} value={value}>{title}</Radio.Button>;
        }
      }
      return <Radio id={value} key={value} value={value}>{title}</Radio>;
    };

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-radio form-attribute-radio-' + attribute.index}
      style={style}
      allValues={allValues}
      translations={translations}
      formProps={formProps}
      form={form}
      attribute={attribute}
    >
      {({value, onChange, t}) => {
        let radioOptions = options
          .map((optionValue: OptionValue<any>) => createPickerItem(optionValue.title, optionValue.value));

        if (_.get(attribute, 'targetType', '').toUpperCase() === AttributeTargetType.BOOLEAN) {
          radioOptions = [
            createPickerItem(t('Yes'), "true"),
            createPickerItem(t('No'), "false")
          ]
        }

        const defaultValue = _.get(attribute, 'defaultValue');
        let radioValue = !_.isNil(value)
          ? value
          : !_.isNil(defaultValue)
            ? defaultValue
            : false;

        if (typeof radioValue === 'boolean') {
          radioValue = _.chain(radioOptions)
            .find((option: any) => {
              let val = _.get(option, 'props.value');

              if (typeof val === "string") {
                val = val.toLowerCase();
              }

              if (radioValue) {
                return val === "true" || val === "1"
              }
              return val === "false" || val === "0"
            })
            .get("props.value")
            .value()
        }

        return <Radio.Group disabled={attribute.disabled}
                            value={radioValue}
                            onChange={onChange}
                            className={`form-item-radio-${attribute.index}`}
                            buttonStyle={type === "button" ? "solid" : "outline"}
        >
          {radioOptions}
        </Radio.Group>
      }}
    </EditableWrapper>

  }
}

export default AttributeRadioboxComponent;
