import _ from 'lodash';
import {
  Attribute,
  AttributeFormStyles,
  InputTypeMap,
  RcForm,
  RenderFactory,
  Translations
} from "@dreebit/form-attributes-core";
import * as React from "react";
import {ReactNode} from "react";
import {Button, Form} from "antd";
import {OnFormItemClick} from "../types";


export class WebRenderFactory implements RenderFactory {

  readonly inputTypeComponentMap: InputTypeMap;

  constructor(inputTypeComponentMap: InputTypeMap) {
    this.inputTypeComponentMap = inputTypeComponentMap;
  }

  public renderAttributes(attributes: Attribute<any>[], form: RcForm, styles?: AttributeFormStyles, translations?: Translations, allValues?: any, formProps?: any, onFormItemClick?: OnFormItemClick): ReactNode[] {

    const combinedStyles = {...styles};
    const components = Array.from(attributes.map((item: Attribute<any>) => this.createComponent(item, form, combinedStyles, translations, allValues, formProps, onFormItemClick))
      .filter(item => item !== null));
    return components
  }

  // @ts-ignore
  public renderFormWrapper(items: ReactNode[], attributes: Array<Attribute<any>>, form: RcForm, disableSubmit: boolean, submit?: Function, styles?: AttributeFormStyles, translations: Translations): ReactNode {

    const combinedStyles = {...styles};
    const formItemLayout = _.get(combinedStyles, 'tailFormItemLayout');

    // @ts-ignore
    return <Form onFinish={(_values) => {

      const validateFields = _.get(form, 'validateFields');
      validateFields((err: any, values: any) => {
        if (!err) {
          submit ? submit(values) : null
        } else {
          console.error(err);
        }
      });

    }}>
      {items}

      {!disableSubmit ? <Form.Item
        {...formItemLayout}
      >

        <Button type="primary" htmlType="submit">{translations["Submit"]}</Button>

      </Form.Item> : null}
    </Form>

  }

  private createComponent(attribute: Attribute<any>, form: RcForm, style?: any, translations?: Translations, allValues?: any, formProps?: any, onFormItemClick?: OnFormItemClick): ReactNode | null {
    if (!attribute.inputType) return null;
    let componentMapElement = this.inputTypeComponentMap[attribute.inputType.toUpperCase()];
    if (componentMapElement) {
      // @ts-ignore
      let element = componentMapElement(attribute, form, style, undefined, translations, allValues, formProps, onFormItemClick);

      return element

    }
    return null;
  }

}
