import * as React from "react";
import {MessageOutlined, QuestionOutlined} from "@ant-design/icons";
import {MaterialCommunityIcon} from "./MaterialCommunityIcon";

export const Icon = ({type, allowNull, ...rest}: any) => {
  const icons = require(`@ant-design/icons`);

  let Component;
  if (typeof type === 'object') {
    Component = type
  } else {
    Component = icons[type]
  }

  if (typeof type === "string" && type === "message") {
    return <MessageOutlined rev/>
  }

  if (!Component) {
    const MaterialComponent = <MaterialCommunityIcon
      type={type}
      {...rest}
    />
    if (MaterialComponent) return MaterialComponent;
  }

  if (!Component || !type) {
    console.warn('Icon could not be found', type);
    return allowNull ? null : <QuestionOutlined {...rest} />
  }

  return <Component {...rest} />;
};
