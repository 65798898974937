// @ts-nocheck

import * as React from 'react';
import {Component} from 'react';
import * as _ from "lodash";
import {QueryConfig, TreeNode} from '@dreebit/form-attributes-core';
import {TreeSelect} from "antd";
import {gql} from "@apollo/client";
import Loader from '../Loader';
import {Query, QueryResult} from "@apollo/client/react/components";

type Props = {
  queryConfig?: QueryConfig,
  searchPlaceholder?: string,
  treeData?: TreeNode[],
  value?: any,
  onChange?: Function,
  dataReducer?: (data: any) => TreeNode[],
  disabled?: any
};

type State = {};

class TreeSelectAsync extends Component<Props, State> {

  render() {
    const {queryConfig, treeData, dataReducer} = this.props;

    if (queryConfig && dataReducer) {
      return <Query
        query={gql(queryConfig.query)}
        variables={queryConfig.getQueryOptions ? _.get(queryConfig.getQueryOptions(this.props), 'variables', {}) : null}

      >
        {({loading, error, data}: QueryResult) => {
          if (loading) {
            return <Loader size={12}/>;
          }
          if (error) return `Error!: ${error}`;

          const requestData = _.get(data, `${queryConfig.dataKeyPath}`);
          return this.renderTree(dataReducer(requestData))
        }}
      </Query>
    }

    return <div>
      {this.renderTree(dataReducer ? dataReducer(treeData) : treeData)}
    </div>;
  }

  renderTree = (treeData: any) => {

    const {value, searchPlaceholder, onChange, disabled} = this.props;

    const tProps = {
      treeData,
      value: value,
      disabled: disabled,
      treeCheckable: true,
      showCheckedStrategy: TreeSelect.SHOW_PARENT,
      searchPlaceholder: searchPlaceholder,
      style: {
        width: "100%",
      },
      maxTagCount: 2,
      onChange: (value: any) => onChange ? onChange(value) : null,
    };
    // @ts-ignore
    return <TreeSelect {...tProps} />;
  }
}


export default TreeSelectAsync;
