import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, FormAttribute, Translations} from '@dreebit/form-attributes-core';
import _ from 'lodash';
import EditableWrapper from "../Wrapper/EditableWrapper";
import {Button} from "antd";
import FormModal from "../FormModal";
import {Icon} from "../Icon";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: FormAttribute,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

type State = {
  visible: boolean
};

class AttributeFormComponent extends Component<Props, State> {
  state = {
    visible: false
  };

  changeVisibility = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  render() {
    const {attribute, style, form, allValues, translations, formProps, onFormItemClick} = this.props;
    const {visible} = this.state;

    const attributes = _.get(attribute, 'attributes', []);

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      formProps={formProps}
      form={form}
      allValues={allValues}
      translations={translations}
      attribute={attribute}>

      {({value, onChange}) => {

        return <div>
          <Button type={'default'}
                  icon={<Icon type={"FormOutlined"}/>}
                  onClick={() => this.changeVisibility()}
                  id={attribute.index}
          >
            {_.get(attribute, 'title')}
          </Button>
          {
            visible
              ? <FormModal
                title={_.get(attribute, 'name')}
                attributesFormOptions={_.get(attribute, 'attributesFormOptions')}
                value={value}
                visible={visible}
                attributes={attributes}
                onSave={({values}: any) => {
                  onChange(values);
                  this.changeVisibility()
                }}
                onCancel={() => this.changeVisibility()}
              />
              : null
          }
        </div>
      }}
    </EditableWrapper>
  }
}

export default AttributeFormComponent;
