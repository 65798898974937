import * as React from 'react';
import {Component} from 'react';
import {
  AttributeFormStyles,
  AttributeTargetType,
  OptionAttribute,
  OptionValue,
  RcForm,
  Translations
} from '@dreebit/form-attributes-core';
import {Checkbox} from "antd";
import EditableWrapper from "../Wrapper/EditableWrapper";
import * as _ from "lodash";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: OptionAttribute<any>,
  form: RcForm,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

type State = {}

class AttributeCheckboxComponent extends Component<Props, State> {


  render() {
    const {attribute, style, form, allValues, formProps, onFormItemClick} = this.props;

    let options = _.get(attribute, 'options', []);
    if (!options.length) options = [];

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-radio form-attribute-radio-' + attribute.index}
      style={style}
      allValues={allValues}
      formProps={formProps}
      form={form}
      attribute={attribute}
    >
      {({value, onChange}) => {
        value = _.get(value, 'target.checked', value);

        let items = options
          .map((optionValue: OptionValue<any>, index: any) => this.createPickerItem(optionValue.title, optionValue.value, index));

        if (_.get(attribute, 'targetType', '').toUpperCase() === AttributeTargetType.BOOLEAN) {
          items = [this.createPickerItem(_.get(attribute, 'title', attribute.name), "true")];
        }

        let checkValue = value;
        if (typeof checkValue === 'boolean' && items.length > 0) {
          checkValue = _.chain(items)
            .find((option: any) => {

              let val = _.get(option, 'props.value');
              if (typeof val === "string") {
                val = val.toLowerCase();
              }
              if (checkValue) {
                return val === "true" || val === "1"
              }
              return val === "false" || val === "0"
            })
            .get("props.value")
            .value();
        }

        if (options.length < 1) {
          if (_.isNil(checkValue)) checkValue = false;
          const regexHTMLTags = /<([a-zA-Z1-6]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)/gim;
          let htmlTags = null;
          const attributeName = _.get(attribute, 'title', attribute.name);
          if (attributeName && attributeName.match) htmlTags = attributeName.match(regexHTMLTags);

          return <Checkbox
            checked={checkValue}
            disabled={attribute.disabled}
            onChange={(e) => onChange ? onChange(_.get(e, 'target.checked', e)) : null}
            className={`form-item-checkbox-${attribute.index}`}
          >
            {htmlTags && htmlTags.length > 0 ?
              <span dangerouslySetInnerHTML={{__html: attributeName}}/> : attributeName}
          </Checkbox>
        }

        if (typeof checkValue === "string") {
          checkValue = checkValue.split(',')
        }

        if (!Array.isArray(checkValue)) {
          checkValue = [checkValue];
        }

        return <Checkbox.Group disabled={attribute.disabled}
                               value={checkValue}
                               onChange={onChange}
                               style={{display: 'flex', flexDirection: 'column', minWidth: '250px'}}
                               className={`form-item-checkbox-${attribute.index}`}
        >
          {items}
        </Checkbox.Group>
      }}
    </EditableWrapper>

  }

  createPickerItem = (title: string, value: any, index?: any) => {
    return <Checkbox id={value} key={title} value={value}
                     style={index === 0 ? {marginLeft: '8px'} : undefined}>{title}</Checkbox>;
  }
}

export default AttributeCheckboxComponent;
