import * as React from 'react';
import {Component} from 'react';
import AttributesForm from '../AttributesForm';
import {Modal} from "antd";
import {OnFormItemClick} from "../../types";

type Props = {
  columns: any,
  onCreate: Function,
  onCancel: Function,
  visible: boolean,
  t: any,
  onFormItemClick?: OnFormItemClick
}

type State = {
  values: any
};

class AddRowModal extends Component<Props, State> {
  state = {
    values: []
  };

  render() {
    const {columns, visible, onCancel, t, onCreate, onFormItemClick} = this.props;
    const {values} = this.state;

    const attributes = columns.map((el: object) => {
      return el
    });

    return <Modal
      title={t("Add Row")}
      data-testid={"add-row-modal"}
      visible={visible}
      footer={null}
      onCancel={() => onCancel()}
    >
      <AttributesForm
        layout={'vertical'}
        clearOnSubmit={true}
        onFormItemClick={onFormItemClick}
        attributes={attributes}
        values={values}
        onChange={(_values: any, allValues: any) => this.setState({values: allValues})}
        submitTitle={t('Add Row')}
        submit={(values: any) => new Promise((resolve: any) => {
          onCreate(values);
          resolve(values);
        })}
      />
    </Modal>
  }
}

export default AddRowModal;
