// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Popconfirm} from "antd";
import {useMutation} from "@apollo/client";
import {toastError, toastSuccess} from "../..";
import '../../flexbox.css';

type Props = {
    mutation: any,
    mutationVariables: any
    mutationOptions?: any,
    onSuccess?: any,
}

const DeleteButton = (props: Props) => {

    const { mutation, mutationVariables, mutationOptions, onSuccess } = props;
    const {t} = useTranslation();

    const [deleteMutation, { data }] = useMutation(mutation, mutationOptions);

    const _handleDelete = () => {
        deleteMutation({
            variables: mutationVariables,
        })
            .then(() => {
                toastSuccess(t('Successfully removed'));
                if(onSuccess){
                    onSuccess();
                }
            })
            .catch(() => {
                toastError(t('An error occurred'));
            });
    };

    return <div className={'flex flex-center'}>
        <Popconfirm
            title={t('Are you sure?')}
            onConfirm={() => _handleDelete()}
        >
            <Button
                type="primary"
                danger
            >
                {t('Delete')}
            </Button>
        </Popconfirm>
    </div>
};

export default DeleteButton
