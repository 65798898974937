import * as React from 'react';
import {Component} from 'react';
import * as _ from "lodash";
import FileUploader from "../FileUploader";

const Sizes = {
  grid: 8,
  borderRadius: 5,
};

type Props = {
  value?: any,
  file?: any,
  onChange?: Function,
  style?: any,
  placeholder?: string,
  previewKey?: string,
  uploadKey?: string,
  filenameKey?: string,
  valueKey?: string,
  imageOnly?: boolean,
  multiple?: boolean,
  headers?: any,
  action?: string,
  onUpload?: Function,
  previews?: {
    id: number,
    link: string
  }[],
  customContent?: any,
  t?: any,
  disabled?: any,
  className?: any,
  id?: any,
  fileList?: any,
  onRemove?: (file: any) => boolean | Promise<any>,
  onPreview?: (file: any) => void,
  onDownload?: (file: any) => void,
  withCredentials?: boolean,
  onStatusChange?: any,
  showLoading?: any
}

class File extends Component<Props> {

  static defaultProps = {
    multiple: true
  };

  onChange = (value: any) => {
    let val = value.map((item: any) => _.get(item, this.props.valueKey));
    if (!this.props.multiple) {
      val = _.first(val);
    }
    this.triggerChange(val);
  };

  triggerChange = (changedValue: any) => {

    const onChange = this.props.onChange;
    if (onChange) {
      onChange(changedValue);
    }
  };

  render() {

    const {
      imageOnly, multiple, file, style, action, onUpload, headers, previewKey, filenameKey,
      uploadKey, customContent, t, disabled, className, id, fileList, onRemove,
      onPreview, onDownload, withCredentials, onStatusChange, showLoading
    } = this.props;

    let preview = null;

    if (file && _.startsWith(_.get(file, 'contentType', ""), "image/")) {
      preview = <img alt={_.get(file, filenameKey)} style={{maxHeight: 100, maxWidth: "100%", marginBottom: Sizes.grid}}
                     src={_.get(file, previewKey)}/>
    }


    return <div style={style} className={className} id={id}>
      {preview}
      <FileUploader
        accept={imageOnly ? 'image/*' : undefined}
        multiple={multiple}
        onChange={this.onChange}
        uploadKey={uploadKey}
        action={action}
        headers={headers}
        onUpload={onUpload}
        customContent={customContent}
        t={t}
        disabled={disabled}
        fileList={fileList}
        onRemove={onRemove}
        onPreview={onPreview}
        onDownload={onDownload}
        withCredentials={withCredentials}
        onStatusChange={onStatusChange}
        showLoading={showLoading}
      />
    </div>
  }

}

export default File;
