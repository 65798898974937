import React, {useEffect, useState} from "react";
import {Table} from "antd";
import _ from "lodash";
import { useApolloClient } from "@apollo/client";
import {useTranslation} from "react-i18next";

interface Props {
    loadData:(expandedRecord?: DataNode, start?: number) => Promise<DataNode[]>
    onRowClick?: (item: any) => void,
    pagingTotal?: number,
    pagingLimit?: number,
    tableProps: any
}

export interface DataNode {
    key: string;
    total?: number;
    children?: DataNode[];
    [key: string]: any
}

function updateTreeData(list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] {
    return list.map(node => {
        if (node.key === key) {
            let newChildren = [..._.get(node,"children",[]),...children].filter((item) => !item.isLoadMoreRecord);
            newChildren = _.uniqBy(newChildren,'key');

            if (node.total && node.total > newChildren.length){
                newChildren.push({
                    isLoadMoreRecord: true,
                    parent: node,
                    start: newChildren.length,
                    key: `${node.key}-loadMore`
                })
            }

            return {
                ...node,
                children: newChildren,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    });
}

const rootNode = {
    key: 'root',
    children: []
}

const AsyncTreeTable = (props: Props) => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [treeData, setTreeData] = useState<DataNode[]>([rootNode])
    const [currentMainPage, setCurrentMainPage] = useState(1);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const appendData = async (expandedRecord?: any, start: number = 0) => {

        setLoading(true);
        const fetchedRows = await props.loadData(expandedRecord, start);
        setTreeData((origin) => {
            const newTree = updateTreeData(origin, expandedRecord.key, fetchedRows);
            return newTree
        });
        setLoading(false);
    }

    useEffect(() => {
        appendData({key: 'root'})
    },[])

    const columns = _.get(props.tableProps,'columns',[]);
    if (columns.length){
        const firstCol = _.cloneDeep(columns[0]);
        columns[0] = {
            ...firstCol,
            render: (text: string, row: any) => {
                if (!row.isLoadMoreRecord) {
                    return firstCol.render ? firstCol.render(text, row) : text;
                }
                return {
                    children: <a onClick={() => {
                        appendData(row.parent, row.start)
                    }}>{t('Load more')}</a>,
                    props: {
                        colSpan: 1,
                    },
                };
            },
        }
    }

    return <Table
        expandable={{
            expandedRowKeys,

            rowExpandable: (record: DataNode) => {
                return record.total
            },
            onExpandedRowsChange: (expandedRows: any[]) => {
                setExpandedRowKeys(expandedRows)
            },
            onExpand: (expanded, record) => {
                /**
                 * Reset other expanded?
                 */
                if (!_.size(record.children)){
                    appendData(record)
                }
            }
        }}
        onRow={(record) => ({
            onClick: () => {
                if (props.onRowClick){
                    props.onRowClick(record)
                }
            }
        })}
        pagination={{
            position: ['bottomCenter'],
            current: currentMainPage,
            pageSize: props.pagingLimit,
            showSizeChanger: false,
            onChange: (page, pageSize = 0) => {
                setCurrentMainPage(page);
                setTreeData([rootNode]);
                setLoading(true);
                appendData({key: 'root'}, page * pageSize)
            },
            total: _.get(props,"pagingTotal",0)
        }}
        loading={loading}
        dataSource={_.chain(treeData).first().get("children",[]).value()}
        {...props.tableProps}
        columns={columns}
    />
}

AsyncTreeTable.defaultProps = {
    pagingLimit: 10
}

export default AsyncTreeTable;
