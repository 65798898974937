import * as React from "react"
import {ReactNode} from "react"

type RenderArguments = {
  value?: any,
  onChange?: any,
}

type Props = {
  value?: any,
  onChange?: Function,
  children: (args: RenderArguments) => ReactNode
};

type State = {}

class ValueWrapper extends React.Component<Props, State> {
  render() {
    const {children, value, onChange} = this.props;

    return children({
      value,
      onChange
    });
  }
}

export default ValueWrapper
