import React from "react";
import {Dropdown, Menu} from "antd";
import {OptionValue} from "@dreebit/form-attributes-core";
import {Icon} from "../Icon";

interface Props {
  selectedLanguage?: string,
  onLanguageChange?: (lang: string) => void,
  languages: OptionValue<string>[]
}

const MultiLangDropdownIcon = ({languages, selectedLanguage, onLanguageChange}: Props) => {

  return <Dropdown overlay={<Menu selectedKeys={selectedLanguage ? [selectedLanguage] : []}
                                  onClick={onLanguageChange ? (item: any) => onLanguageChange(item.key) : undefined}>
    {languages.map((item: OptionValue<string>) => <Menu.Item key={item.value}>{item.title}</Menu.Item>)}
  </Menu>}
  >
    <div>
      <Icon type={"mdiWeb"}/>
    </div>
  </Dropdown>
}

export default MultiLangDropdownIcon;
