// @flow
import React from 'react';
import * as _ from "lodash";
import ErrorComponent from "../Error";
import {Button, Popover} from "antd";
import {useTranslation} from "react-i18next";
import {AlertOutlined} from '@ant-design/icons';

type Props = {
    placement?: string,
    query: any,
    t?: any
};


const QueryErrorIndicator = (props:Props) =>  {

    const {query, placement} = props;
    const {t} = useTranslation();

    if (!query || !query.error) {
        return null;
    }

    const error = _.get(query, `error`);
    let content = null;
    if (_.has(error, 'graphQLErrors')) {
        // @ts-ignore
        content = <ErrorComponent title={'GraphQL API Error'}
                                  description={_.first(error.graphQLErrors.map(({message, debugMessage}: any, i: any) => (
                                      <div><span key={i}>{message}{debugMessage ? `: ${debugMessage}` : ''}</span>
                                      </div>
                                  )))}/>;
    } else if (_.has(error, 'networkError')) {
        // @ts-ignore
        content = <ErrorComponent title={'Network Error'} description={error.networkError}/>;
    }

    return <div style={{position: 'relative'}}>
        <Popover
            arrowPointAtCenter
            content={content}
            // @ts-ignore
            placement={placement}
            overlayStyle={{width: 300}}
            title={t('Error')}>
            <Button shape={"circle"} type="primary"
                    danger
                    icon={<AlertOutlined />}/>
        </Popover>
    </div>;
};

QueryErrorIndicator.defaultProps = {
    placement: "bottom"
};

export default QueryErrorIndicator;
