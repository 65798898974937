import React, {Component} from "react"

import classNames from "classnames";
import {Alert} from "antd";

import "./index.css"

type Props = {
    title?: string,
    topRight?: any,
    children?: any,
    style?: any,
    containerStyle?: any,
    bordered?: boolean,
    removeBackground?: boolean,
    bannerText?: string,
    bannerType?: string,
    className?: string
}

const Panel = (props: Props) => {
    const {children, className, title, topRight, style, containerStyle, bordered, removeBackground, bannerType, bannerText} = props;

    const ClassNames = removeBackground ? "relative" : "bg-white relative";

    return <div className={`panel ${className ? className : ''}`} style={style}>
        <div className={"panel-header"}>
            {title ? <div>{title}</div> : <div></div>}
            {topRight}
        </div>
        <div style={containerStyle} className={classNames(ClassNames, { "panel-container": 1,"border": bordered, "border-warning": bannerType === 'warning' })}>
            { bannerText ? <Alert style={{
                margin: -11,
                marginBottom: 8,
            }} message={bannerText} showIcon /> : null}
            {children}
        </div>
    </div>;
}

export default Panel;
