import * as React from 'react';
import {Component} from 'react';
import {Button, Tooltip} from "antd";
import {AttributeFormStyles, ButtonAttribute, Translations} from '@dreebit/form-attributes-core';
import _ from 'lodash';
import EditableWrapper from "../Wrapper/EditableWrapper";
import {Icon} from "../Icon";
import {AttributeTargetType} from "@dreebit/form-attributes-interfaces";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: ButtonAttribute,
  form: any,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

class AttributeButtonComponent extends Component<Props> {


  render() {
    const {attribute, form, style, allValues, translations, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      allValues={allValues}
      formProps={formProps}
      translations={translations}
      form={form}
      attribute={attribute}>

      {({value, onChange}) => {
        let icon = _.get(attribute, 'icon');
        let tooltip = _.get(attribute, 'tooltip');
        const name = _.get(attribute, 'title', _.get(attribute, 'name'));

        let AttributeButton = <Button type={_.get(attribute, 'type', 'default')}
                                      icon={icon ? <Icon type={icon}/> : null}
                                      onClick={() => _.get(attribute, 'onClick')()}
                                      size={_.get(attribute, 'size', 'default')}
                                      disabled={attribute.disabled}
                                      id={attribute.index}
                                      className={_.get(attribute, 'className')}
        >
          {name}
        </Button>

        if (_.get(attribute, 'targetType', '').toUpperCase() === AttributeTargetType.BOOLEAN) {
          if (value) {
            icon = _.get(attribute, 'activeIcon', icon);
            tooltip = _.get(attribute, 'activeTooltip', tooltip);
          } else {
            icon = _.get(attribute, 'inactiveIcon', icon)
            tooltip = _.get(attribute, 'inactiveTooltip', tooltip);
          }

          AttributeButton = <Button
            type={value ? 'primary' : 'default'}
            onClick={() => onChange ? onChange(!value) : null}
            icon={icon ? <Icon type={icon}/> : null}
            size={_.get(attribute, 'size', 'default')}
            disabled={attribute.disabled}
            id={attribute.index}
            className={_.get(attribute, 'className')}
          >
            {name}
          </Button>
        }

        if (tooltip) return <Tooltip title={`${tooltip}`}>
          {AttributeButton}
        </Tooltip>

        return AttributeButton;
      }}
    </EditableWrapper>
  }
}

export default AttributeButtonComponent;
