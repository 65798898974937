import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, RcForm, TimeAttribute, Translations} from '@dreebit/form-attributes-core';
import {TimePicker} from "antd";

import EditableWrapper from "../Wrapper/EditableWrapper";
import DateWrapper from "../Wrapper/DateWrapper";
import {OnFormItemClick} from "../../types";


type Props = {
  attribute: TimeAttribute<any>,
  form: RcForm,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

type State = {}

class AttributeTimePickerComponent extends Component<Props, State> {

  render() {
    const {attribute, style, form, allValues, translations, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      style={style}
      form={form}
      formProps={formProps}
      allValues={allValues}
      translations={translations}
      enableOutsideClick={false}
      attribute={attribute}>

      {(args) => {
        return <DateWrapper
          value={args.value}
          onChange={args.onChange}
          format={attribute.format}>
          {({value, onChange}) => {

            return <TimePicker
              value={value}
              onChange={onChange}
              className={`form-item-time-${attribute.index}`}
              // @ts-ignore
              size={attribute.size}
              disabled={attribute.disabled}
              placeholder={attribute.placeholder}
              format={attribute.format ? attribute.format : "HH:mm:ss"}
              use12Hours={attribute.use12Hours}
            />

          }}
        </DateWrapper>
      }}
    </EditableWrapper>

  }
}

export default AttributeTimePickerComponent;
