// @flow
import * as React from 'react';
import {Component} from 'react';
import {Icon} from "../Icon";


type Props = {
  size?: number
};


class Loader extends Component<Props> {

  static defaultProps = {
    size: 21
  }

  render() {
    return <Icon type={"LoadingOutlined"} style={{fontSize: this.props.size}}/>;
  }
}

export default Loader;
