import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, OptionAttribute, RcForm, Translations} from '@dreebit/form-attributes-core';
import EditableWrapper from "../../Wrapper/EditableWrapper";
import NumberSelect from "./select";
import {getNumberOptionValueItems} from "./utils";
import {OnFormItemClick} from "../../../types";


type Props = {
  mode?: string,
  attribute: OptionAttribute<any>,
  form: RcForm,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any
  renderExtra?: any
  open?: boolean,
  onDropdownVisibleChange?: any,
  onMouseEnter?: any,
  onFocus?: any,
  onFormItemClick?: OnFormItemClick
}

class AttributeNumberSelectComponent extends Component<Props> {

  render() {
    const {
      attribute,
      style,
      form,
      allValues,
      translations,
      formProps,
      renderExtra,
      onDropdownVisibleChange,
      onFocus,
      onMouseEnter,
      open,
      onFormItemClick
    } = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-number-select-' + attribute.index}
      style={style}
      allValues={allValues}
      formProps={formProps}
      form={form}
      translations={translations}
      attribute={attribute}>
      {({value, onChange}) => {
        let {min, max, disabledNumbers, blackList, allowCustom}: any = attribute;

        const options = getNumberOptionValueItems({
          min,
          max,
          allowCustom,
          blackList
        }).map(({label, value}) => {

          return {
            value,
            label,
            disabled: disabledNumbers && typeof disabledNumbers.indexOf === "function" && disabledNumbers.indexOf(value) > -1
          }
        });

        return <>
          {
            renderExtra && renderExtra({
              attribute,
              options,
              value,
              allValues,
              onChange
            })
          }

          <NumberSelect value={value}
                        onChange={onChange}
                        attribute={attribute}
                        options={options}
                        open={open}
                        onDropdownVisibleChange={onDropdownVisibleChange}
                        onMouseEnter={onMouseEnter}
                        onFocus={onFocus}
          />
        </>
      }}
    </EditableWrapper>
  }
}

export default AttributeNumberSelectComponent;
