import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, DateAttribute, RcForm, Translations} from '@dreebit/form-attributes-core';
import {DatePicker} from "antd";
import DateWrapper from "../Wrapper/DateWrapper";
import * as _ from "lodash";
import TimeIntervalButtons from "../TimeIntervalButtons";
import EditableWrapper from "../Wrapper/EditableWrapper";
import {DisplayValueRenderArgs} from "../EditableAttributeContainer";
import * as moment_ from "moment";
import {OnFormItemClick} from "../../types";

const moment = moment_;

type Props = {
  attribute: DateAttribute<any>,
  form: RcForm,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

type State = {}

class AttributeDatePickerComponent extends Component<Props, State> {

  render() {
    const {attribute, style, form, allValues, translations, formProps, onFormItemClick} = this.props;


    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-radio form-attribute-radio-' + attribute.index}
      style={style}
      allValues={allValues}
      formProps={formProps}
      translations={translations}
      form={form}
      displayValueRender={(args: DisplayValueRenderArgs) => {
        return args.value ? moment(args.value).format(attribute.format || (attribute.showTime ? 'LLL' : 'LL')) : null
      }}
      attribute={attribute}
    >
      {(args) => {
        return <DateWrapper
          value={args.value}
          onChange={args.onChange}
          format={attribute.format}>
          {({value, onChange}) => {

            const intervalButtons = _.get(attribute, 'timeIntervalButtons', _.get(attribute, 'inputTypeOptions.timeIntervalButtons'));

            const picker = <DatePicker
              style={{marginRight: 5}}
              value={value}
              id={attribute.index}
              onChange={onChange}
              format={attribute.showTime ? 'L HH:mm' : 'L'}
              // @ts-ignore
              size={attribute.size}
              disabled={attribute.disabled}
              placeholder={attribute.placeholder as any}
              renderExtraFooter={attribute.extraFooter}
              className={`full-width form-item-date-${attribute.index}`}
              showTime={attribute.showTime}/>;

            if (intervalButtons) {
              return <div>
                {picker}
                <TimeIntervalButtons buttons={intervalButtons}
                                     onClick={onChange}
                                     disabled={attribute.disabled}
                />
              </div>
            }
            return picker;

          }}
        </DateWrapper>
      }}
    </EditableWrapper>


  }
}

export default AttributeDatePickerComponent;
