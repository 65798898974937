import * as React from 'react';
import {Component} from 'react';
import {AttributeFormStyles, DataTableAttribute, RcForm, Translations} from '@dreebit/form-attributes-core';
import DataTableComponent from '../DataTableComponent/index';
import EditableWrapper from "../Wrapper/EditableWrapper";
import _ from "lodash";
import {OnFormItemClick} from "../../types";

type Props = {
  attribute: DataTableAttribute<any>,
  form: RcForm,
  style: AttributeFormStyles,
  translations: Translations,
  allValues?: any,
  formProps?: any,
  onFormItemClick?: OnFormItemClick
}

type State = {};

class AttributeTableComponent extends Component<Props, State> {

  render() {
    const {attribute, style, form, translations, allValues, formProps, onFormItemClick} = this.props;

    return <EditableWrapper
      onFormItemClick={onFormItemClick}
      loading={attribute.loading}
      className={'form-attribute-text-' + attribute.index}
      style={style}
      formProps={formProps}
      allValues={allValues}
      translations={translations}
      form={form}
      attribute={attribute}>

      {({value, onChange, t}) => {

        let arrayValue = value;
        if (arrayValue && !Array.isArray(arrayValue)) {
          arrayValue = [value];
        }

        return <DataTableComponent
          t={t}
          currentLanguage={_.get(translations, 'language', 'en')}
          attribute={attribute}
          value={arrayValue}
          onChange={onChange}
          onFormItemClick={onFormItemClick}
          className={`form-item-table-${attribute.index}`}
          id={attribute.index}
        />

      }
      }
    </EditableWrapper>


  }
}

export default AttributeTableComponent;
