import * as React from 'react';
import {Component} from 'react';

import {Editor, EditorState, Modifier, RichUtils, SelectionState} from 'draft-js';
import 'draft-js/dist/Draft.css';
import _ from 'lodash';
import {stateToMarkdown} from "draft-js-export-markdown";
import {stateFromMarkdown} from 'draft-js-import-markdown';
import {Tooltip} from 'antd';
import {Icon} from "../Icon";

type Props = {
  value?: any,
  onChange?: Function,
  markdownButtons?: any,
  placeholder?: any
  disabled?: any,
  className?: any,
  id?: any,
  view?: boolean
};

type State = {
  editorState: any
}

class MarkdownEditor extends Component<Props, State> {
  state = {
    editorState: this.props.value ? EditorState.createWithContent(stateFromMarkdown(this.props.value)) : EditorState.createEmpty()
  };

  componentDidMount = () => {
    this.setState({
      editorState: EditorState.moveFocusToEnd(this.state.editorState),
    });
  };

  onChange = (editorState: any) => {
    if (this.props.onChange) {
      this.props.onChange(stateToMarkdown(editorState.getCurrentContent()));
    }
    this.setState({editorState})
  };

  handleKeyCommand = (command: any) => {
    const newState = RichUtils.handleKeyCommand(this.state.editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  onBoldClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'))
  };

  onItalicClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC'))
  };

  onUL = () => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, 'unordered-list-item'))
  };

  onOL = () => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, 'ordered-list-item'))
  };

  onCustomButton = (button: any) => {
    const content = _.get(button, 'content');
    const styles = _.get(button, 'styles', null); // ['ITALIC', 'BOLD']
    const insertPosition = _.get(button, 'insertPosition', 'afterContent'); // 'beforeContent' | 'afterContent'
    // @ts-ignore
    const insertBeforeContent = insertPosition === "beforeContent";

    // get current EditorState
    const currentContent = this.state.editorState.getCurrentContent();

    // create new SelectionState with focus depending on the insertPosition
    const blockMap = currentContent.getBlockMap();
    const key = insertBeforeContent ? blockMap.first().getKey() : blockMap.last().getKey();
    const lastBlockLength = blockMap.last().getLength();
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: insertBeforeContent ? 0 : lastBlockLength,
      focusKey: key,
      focusOffset: insertBeforeContent ? 0 : lastBlockLength,
    });

    let insertContent = null;
    let contentWithWhitespace = null;

    if (insertBeforeContent) {
      // insert text with given styles at the SelectionState created above --> returns ContentState
      const contentWithStyles = Modifier.insertText(currentContent, selection, content, styles);
      // insert a whitespace with no styles afterwards (so the user can start typing without styles)
      contentWithWhitespace = Modifier.insertText(contentWithStyles, contentWithStyles.getSelectionAfter(), " ", null);
      // add new lines
      insertContent = Modifier.insertText(contentWithWhitespace, contentWithWhitespace.getSelectionAfter(), "\n\n", null);
    } else {
      // insert new lines after current content
      const contentWithNewLines = Modifier.insertText(currentContent, selection, "\n\n", null);
      const contentWithStyles = Modifier.insertText(contentWithNewLines, contentWithNewLines.getSelectionAfter(), content, styles);
      insertContent = Modifier.insertText(contentWithStyles, contentWithStyles.getSelectionAfter(), " ", null);
    }

    // get correct blockKey for the focus selection
    const afterWhitespaceBlockMap = insertBeforeContent && contentWithWhitespace ? contentWithWhitespace.getBlockMap() : insertContent.getBlockMap();
    const afterWhitespaceBlockKey = insertBeforeContent ? afterWhitespaceBlockMap.first().getKey() : afterWhitespaceBlockMap.last().getKey();

    // create new SelectionState with focus after the new text (+1 for the whitespace)
    const selectionAfterNewContent = new SelectionState({
      anchorKey: afterWhitespaceBlockKey,
      anchorOffset: insertBeforeContent ? content.length + 1 : afterWhitespaceBlockMap.last().getLength(),
      focusKey: afterWhitespaceBlockKey,
      focusOffset: insertBeforeContent ? content.length + 1 : afterWhitespaceBlockMap.last().getLength(),
    });

    const editorStateWithInsert = EditorState.push(this.state.editorState, insertContent, 'insert-characters');
    const editorStateWithFocus = EditorState.forceSelection(editorStateWithInsert, selectionAfterNewContent)

    this.setState({
      editorState: editorStateWithFocus
    });
  }

  render() {
    let {markdownButtons, placeholder, disabled, id, view} = this.props;
    let {editorState} = this.state;

    if (view) disabled = true;

    // @ts-ignore
    return <div
      id={id}
      className={this.props.className}
      style={
        view
          ? {}
          : disabled
            ? {
              border: '1px solid #d9d9d9',
              borderRadius: '4px',
              minHeight: '6em',
              backgroundColor: '#f5f5f5',
              color: 'rgba(0, 0, 0, 0.25)'
            }
            : {border: '1px solid #d9d9d9', borderRadius: '4px', minHeight: '6em'}
      }
    >
      {
        disabled
          ? null
          : <div style={{display: 'flex', margin: 5, flexDirection: 'row', alignItems: 'center'}}>
            <a onClick={this.onBoldClick}
               style={{height: '100%', width: 30, textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)', fontSize: 14}}>
              <Icon type={"BoldOutlined"}/>
            </a>
            <a onClick={this.onItalicClick}
               style={{height: '100%', width: 30, textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)', fontSize: 14}}>
              <Icon type={"ItalicOutlined"}/>
            </a>
            <div style={{height: 30, width: 1, borderLeft: '0.5px solid #d9d9d9', margin: '0px 10px'}}/>
            <a onClick={this.onUL}
               style={{height: '100%', width: 40, textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)', fontSize: 16}}>
              <Icon type={"UnorderedListOutlined"}/>
            </a>
            <a onClick={this.onOL}
               style={{height: '100%', width: 40, textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)', fontSize: 16}}>
              <Icon type={"OrderedListOutlined"}/>
            </a>
            <div style={{height: 30, width: 1, borderLeft: '0.5px solid #d9d9d9', margin: '0px 10px'}}/>
            {
              markdownButtons && Array.isArray(markdownButtons)
                ? markdownButtons.map((button: any, i: number) => {
                  return <Tooltip key={`${i}`} title={_.get(button, 'description')}>
                    <a
                      onClick={() => this.onCustomButton(button)}
                      style={{height: '100%', width: 30, textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)', fontSize: 15}}
                    >
                      {
                        button.icon
                          ? typeof button.icon === 'string'
                            ? <Icon type={button.icon}/>
                            : button.icon
                          : <span>{_.get(button, 'text')}</span>
                      }
                    </a>
                  </Tooltip>
                })
                : null
            }
          </div>
      }
      <div style={disabled ? {} : {borderTop: '0.8px solid #d9d9d9', margin: 5}}>
        <div style={{margin: 10}}>
          <Editor
            editorState={editorState}
            onChange={this.onChange}
            handleKeyCommand={this.handleKeyCommand}
            placeholder={placeholder}
            readOnly={disabled}
          />
        </div>
      </div>
    </div>
  }
}

export default MarkdownEditor;
