import {message} from "antd";
import _ from 'lodash'

import findKey from "./findKey";
import Strings from "../statics/Strings";

export const toastError = (error: Error) => {
    let text: any = _.get(error, 'message', error || Strings.errorOccurred);
    text = text.replace('GraphQL error:','');
    message.error(text);
};

export const toastSuccess = (string: String | any) => {

    let text = _.isString(string) ? string : findKey(string, 'message');
    if (_.isString(text)) {
        message.success(text);
    }
};

export const toastMessage = (string: String | any) => {

    let text = _.isString(string) ? string : findKey(string, 'message');
    if (!_.isString(text)) {
        text = String(text);
    }
    message.success(text);

};
