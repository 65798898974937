import * as React from 'react';
import _ from "lodash";
import showdown from 'showdown';
import './index.css';

type Props = {
  placeholder?: string,
  value?: any,
  addOn?: any
};

class ValueLabel extends React.Component<Props> {

  renderTextValue(value: any) {
    if (typeof value !== 'string') return value;

    const markdownConverter = new showdown.Converter();
    return <span className={'LabelMarkdown'} dangerouslySetInnerHTML={{__html: markdownConverter.makeHtml(value)}}/>
  }

  render() {
    let {placeholder, value, children, addOn} = this.props;
    if (children) value = children;
    if (!value) value = placeholder;

    if (value) {
      return <span>
        {this.renderTextValue(value)}
        {
          addOn
            ? <span>
              {_.get(addOn, 'title')}
            </span>
            : null
        }
      </span>;
    }

    return null;
  }
}

export default ValueLabel;
