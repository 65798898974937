// @flow
import * as React from "react";
import {Icon} from "../Icon";
import {Button as AntdButton} from "antd";

export const Button = ({ icon, ...rest }: any) => {

    if(typeof icon === 'string') icon = <Icon type={icon}/>;

    return <AntdButton
        icon={icon}
        {...rest}
    />
};
