import * as React from 'react';
import {Component} from 'react';
import * as _ from "lodash";
import {OptionValue} from '@dreebit/form-attributes-core';
import {Input} from "antd";
import MultiLangDropdownIcon from "./MultiLangDropdownIcon";

type Props = {
  selectedLanguage?: string,
  onSelectedLanguageChange?: (lang: string) => void
  languages: OptionValue<string>[],
  value?: any,
  onChange?: Function,
  disabled?: any,
  className?: any,
  id?: any,
  onClick?: any,
  inputRef?: any,
  onKeyPress?: any,
  placeholder?: any
};

type State = {
  selectedLanguage: any
};

let lang = navigator.language || _.get(navigator, 'userLanguage', 'en');
lang = lang.replace("-", "_");

class MultilangInput extends Component<Props, State> {

  state = {
    selectedLanguage: this.props.selectedLanguage
  };

  componentDidMount(): void {


    // const userAttribute = this.props.languages.find(item => lang === item.value);
    if (!this.state.selectedLanguage) {
      const userAttribute = _.first(this.props.languages);
      if (userAttribute) {
        this.setState({
          selectedLanguage: userAttribute.value
        });
      } else {
        this.setState({
          selectedLanguage: _.first(this.props.languages).value
        });
      }
    }


  }

  _handleSelectChange = (selectedLanguage: string) => {
    if (this.props.onSelectedLanguageChange) {
      this.props.onSelectedLanguageChange(selectedLanguage)
    }

    this.setState({
      selectedLanguage
    })
  };

  _handleInputChange = (e: any) => {

    const text = _.get(e, 'target.value', null);

    const {value, onChange} = this.props;
    const {selectedLanguage} = this.state;

    const newValue = this.props.languages.reduce((acc, cur) => {
      if (value) {
        acc[cur.value] = value[cur.value];
      }
      return acc;
    }, {});

    _.set(newValue, selectedLanguage, text);

    if (onChange) {
      onChange(newValue);
    }

  };

  render() {
    const {value, disabled, className, id, onClick, inputRef, onKeyPress, placeholder} = this.props;

    const languages: OptionValue<any>[] = _.get(this.props, 'languages', []);
    const selectedSubAttribute = _.find(languages, (item: OptionValue<string>) => this.state.selectedLanguage === item.value);
    const textValue = typeof value === 'string'
      ? value
      : selectedSubAttribute ? _.get(value, _.get(selectedSubAttribute, 'value')) : null;

    let multilangPlaceholder = placeholder;
    if (typeof multilangPlaceholder !== 'string') {
      multilangPlaceholder = _.get(placeholder, this.state.selectedLanguage)
    }

    const dropdown = <MultiLangDropdownIcon languages={languages} selectedLanguage={this.state.selectedLanguage}
                                            onLanguageChange={this._handleSelectChange}/>
    return <Input onChange={this._handleInputChange}
                  ref={inputRef}
                  onKeyPress={onKeyPress}
                  type={"text"}
                  id={id}
                  value={textValue}
                  className={className}
                  onClick={onClick}
                  suffix={dropdown}
                  disabled={disabled}
                  placeholder={multilangPlaceholder}
    />;
  }
}

export default MultilangInput;
